import React from "react";
import "../css/_section-8.scss";
import { ROUTES } from "../../config/Routes";
import { Link } from "react-router-dom";

export const Section8 = () => {
  return (
    <div className="row justify-content-between section section-8">
      <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12">
        <h2 className="text-h4">
          About <span>The DAO</span> - <i>Invest in the Future of Business Intelligence</i>
        </h2>
        <p className="text-md lh-31">
          The INTELL Token enables investors to directly participate in a groundbreaking
          Decentralized Autonomous Organization framework - <span className="bold"><i>The DAO</i></span> - that is transforming
          the way artificial intelligence is created, used and shared across a business ecosystem
        </p>

        <ul>
          <p className="text-md lh-31">Unlocking the Power of Federated Learning</p>
          <li className="text-md lh-31">
            Stake INTELL tokens to validate federated learning transactions and help operate the decentralized network. <br/><i>Receive rewards and access exclusive DAO insights</i>.
          </li>
          <li className="text-md lh-31">
            Leverage tokens for voting rights in key DAO decisions around partnerships, protocols, and growth initiatives. <br/><i>Help shape the future direction</i>.
          </li>
          <li className="text-md lh-31">
            Benefit from INTELL appreciation as utilization and adoption increases among organizations worldwide. <br/><i>The value comes from direct utility and governance rights</i>.
          </li>
        </ul>

        <ul>
          <p className="text-md lh-31">Join the Revolution in Enterprise AI</p>
          <li className="text-md lh-31">
            Decentralized, federated learning is powering the next wave of business intelligence. Early INTELL investors and network participants can shape this revolution while benefitting from tremendous growth potential.
          </li>
          <li className="text-md lh-31">
            Investors can stake their INTELL tokens to secure the networks, participate in governance, and invest directly in the future of AI for business.
          </li>
        </ul>

        <p className="text-lg bold lh-31 text-colored">
          <Link to={ROUTES.BUY}>
            Shape the future by investing in INTELL today!
          </Link>
        </p>

      </div>
    </div>
  );
};
