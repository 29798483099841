import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    authenticated: null,
    updating: false,
    error: false,
    success: false,
    token: null,
    admin: null
};

export const applicationReducerSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setAuthenticated: (state, action) => {
            state.authenticated = action.payload;
        },
        setUpdating: (state, action) => {
            state.updating = action.payload;},
        setError: (state, action) => {
            state.error = action.payload;
        },
        setSuccess: (state, action) => {
            state.success = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setAdmin: (state, action) => {
            state.admin = action.payload;
        },
    },
});

export const { setAuthenticated, setUpdating, setError, setSuccess, setAdmin, setToken } = applicationReducerSlice.actions;

export default applicationReducerSlice.reducer;
