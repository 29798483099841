import { ReactElement } from "react";
import { Navigate } from "react-router";

type ProtectedRouteProps = {
  user: boolean; // TODO: MAYBE IT WILL BE AN OBJECT
  redirectPath?: string;
  children: ReactElement;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  user,
  redirectPath = "/",
  children,
}) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedRoute;
