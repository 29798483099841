import React, { useState } from "react";

import "./css/_tokenomics_roadmap.scss";

import { Toggle } from "../components/Toggle";
import { Tokenomics } from "./Tokenomics";
import { Roadmap } from "./Roadmap";

enum Pages {
  tokenomics = "Tokenomics",
  roadmap = "Roadmap",
}

export const TokenomicsRoadmapPage = ({
  defaultActive,
}: {
  defaultActive: string;
}) => {
  const currentPage =
    defaultActive === "tokenomics" ? Pages.tokenomics : Pages.roadmap;

  const [tab, setTab] = useState<string>(currentPage);

  return (
    <div className="info-container">
      <Toggle
        options={Object.values(Pages)}
        onSelect={(selectedValue) => setTab(selectedValue)}
        defaultActive={currentPage}
      />
      {showTab(tab)}
    </div>
  );
};

const showTab = (tabName: string) => {
  switch (tabName) {
    case Pages.tokenomics:
      return <Tokenomics />;
    case Pages.roadmap:
      return <Roadmap />;
    default:
      return <></>;
  }
};
