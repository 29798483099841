import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   cartOpen: false,
   cartItems: [],
    connectWalletOpen: false
};

export const applicationReducerSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setCartItems: (state, action) => {
            state.cartItems = action.payload;
        },
        setCartOpen: (state, action) => {
            state.cartOpen = action.payload;
        },
        setConnectWalletOpen: (state, action) => {
            state.connectWalletOpen = action.payload;
        }
    },
});

export const { setCartItems, setCartOpen, setConnectWalletOpen } = applicationReducerSlice.actions;

export default applicationReducerSlice.reducer;
