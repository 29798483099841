import React, { ReactComponentElement } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { ReactComponent as ErrorIcon } from "./images/error.svg";
import { ReactComponent as HintIcon } from "./images/hint.svg";
import { TooltipComponent } from "../Tooltip";

const Input: React.FC<{
  onChange: (name: string, value: string) => void;
  value: string | number;
  leftIcon?: ReactComponentElement<any>;
  rightIcon?: ReactComponentElement<any>;
  placeholder: string;
  name: string;
  title?: string;
  hint?: string;
  type: string;
  backgroundColor?: string;
  readonly?: boolean;
  error?: boolean;
  errorText?: string;
  max?: number;
  min?: number;
  step?: number;
}> = ({ onChange, value, leftIcon, rightIcon, placeholder, name, title, hint, type, backgroundColor, readonly, error, errorText, max, min, step }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(name, e.target.value);
  };

  return (
    <div className={styles.root}>
      {
        title &&
        <div className={styles.title}>
          {title}
          {
            hint &&
            <span>
              <TooltipComponent title={hint}>
                <span className={styles.hintIcon}>
                  <HintIcon/>
                </span>
              </TooltipComponent>
            </span>
          }
        </div>
      }
      <div className={classNames(styles.inputContainer, {
        [styles.error]: error
      })} style={{ ...(backgroundColor ? { backgroundColor } : {}) }}>
        {leftIcon && (
          <span className={styles.icon}>
            {leftIcon}
          </span>
        )}
        <input
          className={styles.input}
          value={value || ''}
          onChange={handleChange}
          placeholder={placeholder}
          name={name}
          type={type || 'text'}
          readOnly={readonly}
          max={(type === 'number' && max !== undefined) ? max : undefined}
          min={(type === 'number' && min !== undefined) ? min : undefined}
          step={(type === 'number' && step !== undefined) ? step : undefined}
          onKeyDown={type === 'number' ? (evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault() : undefined}
        />
        {rightIcon && (
          <span className={styles.icon}>
            {rightIcon}
          </span>
        )}
      </div>
      {
        errorText &&
        <div className={styles.errorMessage}>
          <span><ErrorIcon/></span>{errorText}
        </div>
      }
    </div>
  );
};

export default Input;
