import React, {useState, useEffect} from 'react'
import { IconPreviewBars, IconPreviewBox } from '../assets/Icons/Icons';
import styles from "./index.module.scss";

interface Props{
    setTypeSection: React.Dispatch<React.SetStateAction<string>>;
    setTypeViewButton:React.Dispatch<React.SetStateAction<string>>;
    typeViewButton:string;
    typeSection: string;
}

const NavCards = ({setTypeSection, typeSection , typeViewButton , setTypeViewButton}:Props) => {

  return (
    <div className={styles.navCards}>
        <div className={styles.typeSections}>
            <button 
                onClick={() => setTypeSection('single')}
                style={{backgroundColor: typeSection === 'single' ? '#CCE0FF' : 'white'}}
            >
                Single
            </button>
            <button 
                onClick={() => setTypeSection('federated')}
                style={{backgroundColor: typeSection === 'federated' ? '#CCE0FF' : 'white'}}
            >
                Federated
            </button>
        </div>
        <div className={styles.buttonsView}>
            <button 
                className={styles.iconBox} 
                onClick={() => setTypeViewButton('box')}
                style={{backgroundColor: typeViewButton === 'box' ? '#CCE0FF' : 'white'}}
            >
                <IconPreviewBox/>
            </button>
            <button 
                className={styles.iconBars}  
                onClick={() => setTypeViewButton('list')}
                style={{backgroundColor: typeViewButton === 'list' ? '#CCE0FF' : 'white'}}
            >
                <IconPreviewBars/>
            </button>
        </div>
    </div>
  )
}

export default NavCards