import React, { useEffect, useState } from 'react'
import styles from "./index.module.scss";
import { Link , useParams} from 'react-router-dom';
import { IconConfig, IconDeploy, IconLearn, IconOperationalize, IconTest }  from '../../assets/Icons/Icons';
import { Agents, Ai, Analytics, ArrowRight, Asterisk, BaseModel, Chart, Checkmark, External, LineColumns, Money, Question, Tasks, Tuning, UserSpeak } from '../../../../icons';
import { Breadcrumb , Button, Tooltip, Select, Tabs ,  TabsProps , Collapse ,CollapseProps  }  from 'antd';
import { HowToReg } from '@mui/icons-material';
import Loading from '../../../../components/Loading/Loading';


const DetailSingle = () => {
   const { id } = useParams();
   const [dataSingle, setDataSingle] = useState<any>([]);
   const [loading , setLoading] = useState(false)


   useEffect(() => {
    fetch('https://run.mocky.io/v3/ace5eeb7-f294-4d4b-8c10-2bd195f3798c')
        .then(response =>  response.json()) 
        .then(data => {
            setLoading(true)
            setDataSingle(data) 
        })
        .catch(error => {
            console.error('error', error);
        });
    },[])


    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
    };


    function getIconByDataType(dataType:any) {
        switch (dataType) {
          case 'base_models':
            return <BaseModel />;
          case 'intelligent_agents':
            return <Agents />;
          case 'tasks_capabilities':
            return <Tasks />;
          case 'language_models':
            return <UserSpeak />;
          case 'fine_tuning':
            return <Tuning />;
          case 'predictive_models':
            return <Ai />;
          case 'analytic_types':
            return <Analytics />;
          case 'data_sources':
            return <Chart />;
          case 'other_data':
            return <LineColumns />;
          default:
            return null;
        }
      }
      

    const toltipData = [
        {
            icon: <IconConfig/>,
            title: 'Configuration'
        },
        {
            icon: <IconTest/>,
            title: 'Test'
        },
        {
            icon: <IconDeploy/>,
            title: 'Deploy'
        },
        {
            icon: <IconOperationalize/>,
            title: 'Operationalize'
        },
        {
            icon: <IconLearn/>,
            title: 'Learn'
        },
    ]


    const [itemTab , setItemTab] = useState('1')
    const onChangeItem = (key: string) => {
        setItemTab(key)
    };

    const items: TabsProps['items'] = [
        {
          key: '1',
          label: <button className={`border-2 p-2 text-[1rem] ${itemTab === '1' && 'bg-[#0166FF] text-white'} text-black rounded-xl w-[11rem]  font-[600]`}>Activation Outcomes</button> ,
          children: <div className={styles.tab}>
                        {
                            dataSingle?.activationOutcomes?.map((data:any , key:any) => (
                                <div key={key} className="flex min-h-[10rem] gap-2 flex-col w-[20rem] border-l-2 border-r-2 border-t-none border-b-none p-2 px-4 border-dashed">
                                    <strong className='text-[1.2rem]'>{data.title}</strong>
                                    <strong className='text-[1.05rem]'>{data.subtitle}</strong>
                                    <span className='font-[500]'>{data.description}</span>
                                </div>
                            ))
                        }
                    </div>,
        },
        {
          key: '2',
          label: <button className={`border-2 p-2 text-[1rem] ${itemTab === '2' && 'bg-[#0166FF] text-white'} text-black rounded-xl w-[10rem] font-[600] !m-0`}>Technical Aspects</button> ,
          children: <div className={styles.tab}>
                        {
                            dataSingle?.technicalAspects?.map((data:any , key:any) => (
                                <div key={key} className="flex min-h-[10rem] gap-2 flex-col w-[20rem] border-l-2 border-r-2 border-t-none border-b-none p-2 px-4 border-dashed">
                                    <span>{getIconByDataType(data.identifier)}</span>
                                    <strong className='text-[1.2rem]'>{data.title}</strong>
                                    {/* <strong className='text-[1.05rem]'>{data.subtitle}</strong> */}
                                    <span className='font-[500]'>{data.description}</span>
                                </div>
                            ))
                        }
                    </div>,
        },
        
    ];




    function getIconCollapse(dataType:any) {
        switch (dataType) {
          case 'business_model':
            return <HowToReg />;
          case 'business_benefit':
            return <Checkmark />;
          case 'investment_risks':
            return <Asterisk />;
          case 'external':
            return <External />;
          case 'cost_structure':
            return <Money />;
          case 'ipr':
            return <Question />;
          default:
            return null;
        }
      }

    const itemsCollapse: CollapseProps['items'] =
     dataSingle?.moreDetails?.map((data: any, index: any) => ({
        key: index, 
        label: <div className="flex gap-2 w-full">
                <span> {getIconCollapse(data.identifier)}</span> 
                <span> {data.title}</span>
               </div> , 
        children: <p>{data.description}</p> ,
        style:{
            marginBottom: 24,
            background: 'white',
            borderRadius: '10px',
            border: 'none',
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1.8px'
        }
      })) || [];


      

  return (
    <div className={styles.rootDetail}> 
    
        <Breadcrumb
            className={styles.breadcrumb}
            separator=">"
            items={[
            {
                title: 'Home',
            },
            {
                title: 'Marketplace',
                href: '/marketplace',
            },
            {
                title: dataSingle.title || '', 
            },
            ]}
        />
        {
         !loading ? 
             <Loading/>
            :
            <div className={styles.container_header}>
                <div className='flex justify-between items-center gap-4 flex-wrap w-full'>
                    <h1>{dataSingle?.title}</h1>
                    <div className='flex gap-6 justify-center items-center'>
                        <strong className='p-2 px-4 rounded-2xl text-[1.2rem] bg-[#FFD2E7]'>{dataSingle.type}</strong>
                        <strong className='p-2 px-4 rounded-2xl text-[1.2rem] bg-[#CCE0FF]'>{dataSingle.stage}</strong>
                    </div>
                </div>
                <div className="flex gap-2 items-center mb-4 flex-wrap w-full ">
                    {dataSingle?.categories?.map((categorie:any, index:any) => (
                        <span 
                            key={index} 
                            className='p-2 px-3 flex justify-center items-center flex-nowrap rounded-2xl text-[1rem] bg-[#DDDDDD75] font-[500]'
                        >
                            {categorie}
                        </span> 
                    ))}
                </div>

                <div className="flex gap-2 flex-col ">
                    <strong className='text-[1.2rem]'>
                        Description
                    </strong>
                    <span className='text-[1.05rem] font-[500]'>
                        {dataSingle?.description}
                    </span>
                </div>

                <div className="flex gap-2 flex-col">
                    <strong className='text-[1.2rem]'>Deployments of this Activation</strong>
                    <div className=" ">
                        <span className='text-[1.05rem] font-[500]'>
                            The icons inside each deployment represent the specific stage within that deployment, so you are able to make informed selections and choices.  
                        </span>
                        <span className='text-[1.05rem] font-[500]'>
                            To learn more about the icons, 
                            <Tooltip 
                                placement="bottom" 
                                color={'#FFDDED'}
                                title={
                                    toltipData.map((data:any, index:any) => (
                                        <div className='flex justify-start items-center gap-2 py-1 px-2' key={index}>
                                            <div className='w-[1rem]'> {data.icon} </div>
                                            <div className='text-[1rem] text-black font-[600]'> {data.title} </div>
                                        </div>
                                    ))
                                } 
                                trigger="click"
                                className='cursor-pointer '
                            >
                                <strong className='!select-none'> click here.</strong>
                            </Tooltip>
                        </span>
                    </div>
                </div>

                <Select
                    defaultValue=  "Select deployment"
                    size="large"
                    className='w-full !h-[3.9rem] !font-[600] ' 
                    onChange={handleChange}
                    options={
                        dataSingle?.deployments?.map((data: any, index: any) => ({
                        value: data.id,
                        label: <div key={index} className='flex flex-nowrap justify-between '>
                                    <div className='flex gap-2 items-center  p-2' >
                                        <span className='w-[1rem]'><IconDeploy color='#0166FF'/></span>
                                        <strong className='font-[500] text-[1rem]'> {data.company} </strong> 
                                    </div> 
                                    
                                        {
                                            data.reservedShares === data.availableShares ? 
                                                <div className="flex justify-center items-center gap-1 w-[7rem] px-4 rounded-2xl font-[500] bg-[#CCE0FF86]">
                                                    <strong>Sold Out</strong>
                                                </div>
                                            :
                                                <div className="flex justify-center items-center gap-1 w-[7rem] px-4 rounded-2xl font-[500] bg-[#CCE0FF86]">
                                                    <span>{data.reservedShares} </span> / <strong> {data.availableShares}</strong> 
                                                </div>
                                        }
                                    
                                </div> 
                        })) || []
                    }
                />

                <div className="h-[30rem] w-full border-dotted border-2 border-black p-4">
                    <strong>Price Per Share</strong>
                </div>

                <div className="flex justify-center items-center w-full w-full flex-col relative">
                    <Tabs 
                        defaultActiveKey="1" 
                        items={items} 
                        onChange={onChangeItem} 
                        className='w-full flex justify-center items-center min-h-[40vh] gap-[2rem] !m-0 ' 
                        />
                    {
                        itemTab === '2' && <Link to=''> 
                                                <strong className='absolute right-[5rem] flex items-center gap-4  hover:text-[#0166FF] mt-[2rem]'> 
                                                    Smart contract verification 
                                                    <span><ArrowRight/></span>
                                                </strong>
                                            </Link>
                    }
                </div>

                <div className="mt-[4rem] flex flex-col gap-8">
                    <h2 className='font-bold text-[1.3rem]'>More details about this Activation</h2>
                    <Collapse 
                        items={itemsCollapse} 
                        /* onChange={onChange} */ 
                        size='large'
                        expandIconPosition='end'
                        className='w-full bg-white font-[500] !border-none'
                    />
                </div>
        </div>
        }

    </div>
  )
}

export default DetailSingle