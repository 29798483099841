import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./index.module.scss";
import Popup from "../Popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import { setCartItems, setCartOpen } from "../../store/reducers/application";
import { CrossSVG, VerifiedSVG, DeleteSVG } from "../../icons";
import classNames from "classnames";
import { DEFAULT_LOCALE } from "../../adminApp/constants";
import { clearBodyLocks } from "tua-body-scroll-lock";

type CART_ITEM = {
  product: {
    _id: string,
    data: {
      en: {
        image: [{ path: string, pathWebp: string }],
        name: string
      }
    }
  },
  amount: number,
  price: number
};

const Cart = () => {
  const dispatch = useDispatch();
  const cartOpen = useSelector(({ application }) => application.cartOpen);
  const cartItems = useSelector(({ application }) => application.cartItems);
  const refInner = useRef<any>();
  const ref = useRef<any>();

  const [items, setItems] = useState(cartItems?.length ? [...cartItems].reverse() : []);
  const totalPrice = useMemo(() => items ? items.reduce((acc: number, item: any) => acc + (+item.price * +item.amount), 0) : 0, [items]);

  const handleClosePopup = () => {
    dispatch(setCartOpen(false));
    clearBodyLocks();
  };

  const handleDelete = (_id: string) => () => {
    if (_id) {
      const newProducts = [...cartItems.filter((item: { product: { _id: string } }) => item.product._id !== _id)]
      dispatch(setCartItems([...newProducts]));
      setItems([...newProducts]);
    }
  }

  const handleClear = () => {
    dispatch(setCartItems([]));
    setItems([]);
  }

  useEffect(() => {
    setItems(cartItems?.length ? [...cartItems].reverse() : []);
  }, [cartItems]);

  return (cartOpen ?
    <div className={styles.root} ref={ref}>
      <Popup onClose={handleClosePopup} theme={"cartPopup"} open={cartOpen} refInner={refInner} refOuter={ref}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>Cart</div>
            {
              !!items.length &&
              <div className={styles.clear} onClick={handleClear}>Clear all</div>
            }
            <button onClick={handleClosePopup} className={styles.close}>
              <CrossSVG />
            </button>
          </div>
          <div className={styles.body}>
            <div className={styles.items} ref={refInner}>
              {
                items.map((item: CART_ITEM) => {
                  const product = item.product.data[DEFAULT_LOCALE];
                  const amount = item.amount;
                  const price = item.price;

                  return <div key={item.product._id} className={styles.item}>
                    <div className={styles.itemImage}>
                      {!!product.image.length &&
                        <picture>
                          <source srcSet={product.image[0].pathWebp} type='image/webp' />
                          <img src={product.image[0].path} className={styles.image} alt={product.name}/>
                        </picture>
                      }
                    </div>
                    <div className={styles.itemInfo}>
                      <div className={styles.itemName}>{product.name}</div>
                      <div className={styles.itemOwner}>Owner ID/Name <VerifiedSVG/></div>
                    </div>
                    <div className={styles.itemDetails}>
                      <span>
                         <div className={styles.itemPrice}>{`${+price} INT`}</div>
                          <div className={styles.itemAmount}>{`Num of shares: ${+amount}`}</div>
                      </span>
                      <div className={styles.deleteIcon} onClick={handleDelete(item.product._id)}><DeleteSVG/></div>
                    </div>
                  </div>
                })
              }
              {
                !items.length && <div className={styles.noItems}>
                  <span className={styles.noItemsText}>Add items to get started</span>
                </div>
              }
            </div>
            {
              !!items.length &&
              <div className={styles.total}>
                <div className={styles.totalTitle}>Total price</div>
                <div className={styles.totalValue}>{`${totalPrice} INT`}</div>
              </div>
            }
            {
              !!items.length &&
              <div className={styles.buttons}>
                <button className={classNames(styles.button, styles.filled)}>Complete purchase</button>
                <button className={classNames(styles.button, styles.outlined)} onClick={handleClosePopup}>Buy more NFT’s</button>
              </div>
            }
            {
              !items.length &&
              <div className={styles.buttons}>
                <button className={classNames(styles.button, styles.outlined)} onClick={handleClosePopup}>Explore more NFT’s</button>
              </div>
            }
          </div>
        </div>
      </Popup>
    </div> : null
  );
};

export default Cart;
