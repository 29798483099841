import React from "react";
import { Link } from "react-router-dom";

import "./_social-block.scss";
import { ReactComponent as InstagramIco } from "./assets/Instagram-ico.svg";
import { ReactComponent as LinkedInIco } from "./assets/Linkedin-ico.svg";
import { ReactComponent as TwitterIco } from "./assets/Twitter-ico.svg";
import { ReactComponent as InstagramIcoBlack } from "./assets/Instagram-ico-black.svg";
import { ReactComponent as LinkedInIcoBlack } from "./assets/Linkedin-ico-black.svg";
import { ReactComponent as TwitterIcoBlack } from "./assets/Twitter-ico-black.svg";

interface ISocialBlock {
  color?: string;
}

export const SocialBlock = ({ color }: ISocialBlock) => {
  return (
    <div className={`social ${color}-schema`}>
      <Link to={""}>
        {color === "white" ? <LinkedInIco /> : <LinkedInIcoBlack />}
      </Link>
      <Link to={""}>
        {color === "white" ? <InstagramIco /> : <InstagramIcoBlack />}
      </Link>
      <Link to={""}>
        {color === "white" ? <TwitterIco /> : <TwitterIcoBlack />}
      </Link>
    </div>
  );
};
