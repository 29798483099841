import { ADMIN_ROUTES } from "../config/AdminRoutes";

import BuyIntellPage from "../pages/admin/ButIntellPage";
import JoinIntellPage from "../pages/admin/JoinIntellPage";
import CredentialsPage from "../pages/admin/Credentials";
import ProductsPage from "../pages/admin/ProductsPage";

export type routerType = {
  title: string;
  path: string;
  element: React.ElementType;
  isPrivate: boolean;
};

const routes: routerType[] = [
  {
    path: ADMIN_ROUTES.CREDENTIALS,
    element: CredentialsPage,
    title: "credentials",
    isPrivate: false,
  },
  {
    path: ADMIN_ROUTES.BUYINTELL,
    element: BuyIntellPage,
    title: "buyIntell",
    isPrivate: false,
  },
  {
    path: ADMIN_ROUTES.JOININTELL,
    element: JoinIntellPage,
    title: "joinIntell",
    isPrivate: false,
  },
  {
    path: ADMIN_ROUTES.PRODUCTS,
    element: ProductsPage,
    title: "products",
    isPrivate: false,
  },
];

export default routes;
