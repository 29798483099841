import React from "react";
import "../css/_section-6.scss";

export const Section6 = () => {
  return (
    <div className="section section-6" id="about">
      <div className="row">
        <div className="col-lg-5 col-sm-12 col-xs-12 sm-show">
          <div className="section-6-img"></div>
        </div>
      </div>

      <h4 className="text-h4">About TIEx</h4>

      <div className="row">
        <div className="col-lg-7 col-sm-12 col-xs-12">
          <div className="text-container">
            <p className="text-md lh-31">
              The Intelligence Exchange is decentralizing the utilization of
              Artificial Intelligence. Greatly reducing development costs and
              mitigating risk while expanding access to Data Science
              collaboration. Democratizing Artificial Intelligence will enable
              access by Any company, Any organization, or Any entity.
            </p>
            <p className="text-md lh-31">
              Decentralization also facilitates collaboration and connectivity
              between creators of AI tools and models – typically data
              scientists – and the companies or organizations that want access
              to greater intelligence about the business environments in which
              they operate.
            </p>
            <p className="text-md lh-31">
              For the past 5 years The Intelligence Exchange has worked with
              some of the biggest organization – the Food Service and Automotive
              Repair industries – to deliver intelligence, analytics and
              strategic data management expertise.
            </p>
          </div>
          <div className="buttons-container">
            <a href="" className="button-primary">
              Visit our website
            </a>
          </div>
        </div>
        <div className="col-lg-5 col-sm-12 col-xs-12 sm-hidden">
          <div className="section-6-img"></div>
        </div>
      </div>
    </div>
  );
};
