import React from "react";
import "../css/_section-2.scss";
import Section2Brain from "../assets/images/section2-brain.svg";
import { ReactComponent as BrainIcon } from "../assets/images/brain-icon.svg";
import { Button } from "../../components/Button";
import { Link } from "react-router-dom";
import { ROUTES } from "../../config/Routes";

export const Section2 = () => {
  return (
    <div className="row section section-2">
      <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 text-content">
        <BrainIcon className="brain-icon xs-hidden" />
        <div className="text-container">
          <h4 className="text-h4">
            <span className="text-h4-colored">Invest</span> in a
            Business-Oriented
            <br />
            Artificial Intelligence Ecosystem
          </h4>

          <p className="text-md">
            Investors in the INTELL Token ($INTL) help to mitigate the most
            common burdens that prevent businesses from utilizing AI to drive
            better business outcomes:{" "}
            <span>
              Lack of Budget, Lack of Expertise and Limited Resources.
            </span>
          </p>

          <p className="text-md">
            INTELL ($INTL) token holders help fuel the AI ecosystem including
            the development and training of business-ready AI models, LLMs, and
            the operations and governance of our DAO (Decentralized Autonomous Organization)
          </p>

          <p className="text-md">
            INTELL Token holders will participate in the democratization and
            disruption of the way businesses can access and implement AI into
            their existing business systems and processes.
          </p>
        </div>
        <div className="buttons-container">
          <Link to={ROUTES.BUY}>
            <Button type="primary" text="Buy $INTL" />
          </Link>
        </div>
      </div>
      <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
        <div className="big-svg-wrapper">
          <img src={Section2Brain} className="big-svg brain-big" />
          <div className="big-svg-shadow"></div>
        </div>
      </div>
    </div>
  );
};
