import React from 'react';

import RecoveryRequestForm from '../RecoveryRequestForm/index.js';
import RecoveryForm from '../RecoveryForm/index.js';

import { makeStyles } from '@mui/styles';

import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

const Recovery = () => {
    const [searchParams] = useSearchParams();

    searchParams.get('page');
    const recoveryToken = searchParams.get('recovery-token');
    const email = searchParams.get('email');
    const classes = useStyles();

    return <div className={classes.root}>
        {!recoveryToken ? <RecoveryRequestForm /> : <RecoveryForm token={recoveryToken} email={email} />}
    </div>;
};

export default Recovery;
