import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { makeStyles } from '@mui/styles';

import Form from '../Form/Form.js';
import getFields from './getFields.js';
import saveCollection from '../../services/nft/saveCollection.ts';
import editCollection from '../../services/nft/editCollection.ts';
import { Alert } from '@mui/lab';

import { SnackbarContent, Snackbar } from '@mui/material';

const useStyles = makeStyles(() => ({
    error: {},
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        padding: '0'
    },
    margin: {
        padding: '0',
        minWidth: 'unset',
        background: 'transparent'
    },
    margin2: {
        transform: 'translateY(calc(-100% - 10px)) !important'
    }
}));

const CollectionForm = ({ item, onDone, setFieldsChanged }) => {
    const [errorText, setErrorText] = useState();
    const classes = useStyles();
    const isExistItem = !!item._id;
    const [disabled, setDisabled] = useState(true);

    const handleSubmit = values => {
        (isExistItem ? editCollection(item._id, values) : saveCollection(values))
            .then(() => {
                onDone();
            })
            .catch(error => {
                if (error.code === 'duplication') {
                    setErrorText('Enter unique alias');
                }
            });
    };

    const handleHideFailMessage = () => {
        setErrorText('');
    };

    const handleChange = (values, changes) => {
        if (disabled && !changes.lang) {
            setFieldsChanged(true);
            setDisabled(false);
        }
    };

    return <div>
        <Form
            data={item.data}
            fields={getFields({
                formTitle: isExistItem ? 'Edit collection' : 'Add collection',
                disabled
            })}
            onSubmit={handleSubmit}
            onChange={handleChange}
        />
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            onClose={handleHideFailMessage}
            open={!!errorText}
            autoHideDuration={null}
        >
            <SnackbarContent
                className={classNames(classes.error, classes.margin, classes.margin2)}
                classes={{ message: classes.message }}
                message={
                    <span className={classes.message}>
                        <Alert onClose={handleHideFailMessage} severity="error">
                            {errorText}
                        </Alert>
                    </span>
                }
            />
        </Snackbar>
    </div>;
};

CollectionForm.propTypes = {
    item: PropTypes.object,
    onDone: PropTypes.func.isRequired,
    setFieldsChanged: PropTypes.func.isRequired
};

CollectionForm.defaultProps = {
    item: {}
};

export default CollectionForm;
