import React, { useState, useEffect } from 'react';

import omit from 'ramda/src/omit';

import { makeStyles } from '@mui/styles';

import AdminTable from '../../../adminApp/components/AdminTable/index.js';
// import Form from '.../../../adminApp/components/Form/index.js';

import { DEFAULT_LOCALE, LOCALES } from "../../constants/index.js";
import { Close } from '@mui/icons-material';
import { IconButton, DialogTitle, DialogActions, Button, Dialog, CircularProgress, Modal, Paper } from '@mui/material';
import { getJoinRequests } from "../../services/getJoinRequests.ts";
import { deleteJoinRequests } from "../../services/deleteJoinRequests.ts";
import { ExportToCsv } from 'export-to-csv';


const headerRows = [
    { id: 'name', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'isBasedInUS', label: 'Is Based in US' },
    { id: 'haveCryptoWallet', label: 'Has Crypto Wallet' },
    { id: 'applied', label: 'Applied' },
];

const useStyles = makeStyles(() => ({
    loader: {
        height: 'calc(100vh - 64px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    closeButton: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        zIndex: '10'
    },
    modalContent: {
        padding: '30px',
        position: 'absolute',
        width: '1200px',
        outline: 'none',
        overflowY: 'auto',
        maxHeight: '100vh',
        '@media (max-width:1300px)': {
            width: '90%'
        }
    },
    warningContent: {
        paddingBottom: '0'
    },
    columnName: {
        maxWidth: '400px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '@media (max-width:1020px)': {
            maxWidth: '200px'
        },
        '@media (max-width:750px)': {
            maxWidth: '100px'
        },
        '@media (max-width:340px)': {
            maxWidth: '80px'
        }
    },
    convert: {
        margin: '30px 30px 0'
    }
}));

const JoinIntellUsersPage = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [warning, setWarning] = useState(false);
    const [data, setData] = useState([]);
    const [formOpened, setFormOpened] = useState(false);
    const [editableItem, setEditableItem] = useState(null);

    useEffect(() => {
        getJoinRequests()
            .then(({ data }) => {
                setData(data);
                setLoading(false);
            });
    }, []);

    const handleFormDone = () => {
        getJoinRequests()
          .then(({ data }) => {
                setData(data);
            })
            .then(handleCloseForm);
    };

    const handleItemsDelete = ids => {
        deleteJoinRequests(ids)
            .then(() => {
                getJoinRequests()
                  .then(({ data }) => {
                        setData(data);
                    });
            });
    };

    const handleFormOpen = item => () => {
        setFormOpened(true);
        setEditableItem(item);
    };

    const handleItemClone = item => () => {
        setFormOpened(true);

        const data = {};
        LOCALES.forEach((locale) => {
            data[locale] = {
                ...item.data[locale],
                name: `${item.data[locale].name} (copy)`
            };
        });

        setEditableItem(omit(['_id'], {
            ...item,
            data: data
        }));
    };

    const handleCloseForm = () => {
        setFormOpened(false);
        setEditableItem(null);
    };

    const handleWarningAgree = () => {
        setWarning(false);
        handleCloseForm();
    };

    const handleWarningDisagree = () => {
        setWarning(false);
    };

    const handleOpenWarning = () => {
        setWarning(true);
    };

    const handleCsvExport = () => {
        const values = data.map((item) => ({
            ['First Name']: item.firstName,
            ['Last Name']: item.lastName,
            ['Email']: item.notNormalizedEmail,
            ['Is Based In US']: item.isBasedInUS,
            ['Has Crypto Wallet']: item.haveCryptoWallet,
            ['Applied']: item.createdAt,
        }));

        const options = {
            useKeysAsHeaders: true,
            filename: 'joinIntell'
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(values);
    };

    const tableCells = [
        { prop: item => <div className={classes.columnName}>{`${item.firstName} ${item.lastName}`}</div> },
        { prop: item => <div className={classes.columnName}>{item.notNormalizedEmail}</div> },
        { prop: item => <div className={classes.columnName}>{item.isBasedInUS}</div> },
        { prop: item => <div className={classes.columnName}>{item.haveCryptoWallet}</div> },
        { prop: item => <div className={classes.columnName}>{new Date(item.createdAt).toLocaleDateString()}</div> },
    ];

    if (loading) {
        return <div className={classes.loader}>
            <CircularProgress/>
        </div>;
    }

    return <div>
        <Button color='primary' variant='contained' className={classes.convert} onClick={handleCsvExport}>Export CSV</Button>
        <AdminTable
            headerRows={headerRows}
            tableCells={tableCells}
            values={data}
            headerText='Join INTELL applicants'
            onDelete={handleItemsDelete}
            onClone={handleItemClone}
            onFormOpen={handleFormOpen}
            noActions={true}
            // onSort={() => {}} // if you need a sortable table
        />
        <Modal open={formOpened} onClose={handleOpenWarning} className={classes.modal} disableEnforceFocus>
            <Paper className={classes.modalContent}>
                {/* <Form item={editableItem} onDone={handleFormDone}/>*/}
                <IconButton onClick={handleOpenWarning} className={classes.closeButton}>
                    <Close />
                </IconButton>
            </Paper>
        </Modal>
        <Dialog
            open={warning}
            onClose={handleWarningDisagree}
        >
            <DialogTitle>Are you sure you want to close the form?</DialogTitle>
            <DialogActions>
                <Button onClick={handleWarningDisagree} color='primary'>
                    No
                </Button>
                <Button onClick={handleWarningAgree} color='primary' autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    </div>;
};

export default JoinIntellUsersPage;
