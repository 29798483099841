import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import always from 'ramda/src/always';

import { BottomNavigationAction, BottomNavigation } from '@mui/material';
import { makeStyles } from '@mui/styles';

import checkSettings from '../../utils/checkSettings.js';

const SETTINGS_MODEL = {}; // no available settings

const useStyles = makeStyles(theme => ({
    root: {
        justifyContent: 'flex-start',
        marginTop: '15px'
    },
    langNav: {
        maxWidth: '50px',
        textTransform: 'uppercase'
    },
    langNavError: {
        color: 'red !important'
    },
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        whiteSpace: 'nowrap',
        height: 'fit-content'
    }
}));

const FormFieldLangs = ({ value, validationMessage, onChange, langs, settings }) => {
    useEffect(() => {
        checkSettings('Langs', settings, SETTINGS_MODEL);
    }, []);

    const classes = useStyles();

    const handleChange = (event, newValue) => {
        onChange(langs[newValue]);
    };

    const valueIndex = useMemo(() => {
        const valueIndex = langs.findIndex(lang => value === lang);

        return valueIndex < 0 ? 0 : valueIndex;
    }, [value, langs]);

    return <BottomNavigation
        value={valueIndex}
        onChange={handleChange}
        showLabels
        className={classes.root}
    >
        {langs.map((lang, i) => {
            return <BottomNavigationAction className={classNames(classes.langNav, {
                [classes.langNavError]: !!validationMessage
            })} key={i} label={lang} />;
        })}
    </BottomNavigation>;
};

FormFieldLangs.propTypes = {
    onChange: PropTypes.func,
    triggerTranslate: PropTypes.func,
    value: PropTypes.string,
    langs: PropTypes.array,
    settings: PropTypes.object,
    validationMessage: PropTypes.string
};

FormFieldLangs.defaultProps = {
    onChange: always,
    langs: [],
    value: '',
    settings: {},
    validationMessage: ''
};

export const fieldName = 'langs';
export const getInitialValues = () => {};
export const Component = FormFieldLangs;

export default {
    Component,
    fieldName,
    getInitialValues
};
