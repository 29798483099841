import React from 'react'
import styles from "./index.module.scss";
import { Link , useParams} from 'react-router-dom';

const DetailFederated = () => {
   const { id } = useParams();

  return (
    <div>DetailFederated {id}</div>
  )
}

export default DetailFederated