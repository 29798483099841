import React from 'react'
import styles from "./index.module.scss";
import { Link } from 'react-router-dom';

interface Props{
    typeViewButton: string;
    dataFederated:any;
}

const CardFederated = ({typeViewButton, dataFederated}:Props) => {
  return (
    <div 
      className={styles.boxCardSingle}
    >
    {
      dataFederated?.map((data:any,index:any) => (
       <Link  to={`/detail/federated/:${data.id}`} key={index}>
        <div 
          className={styles.cardFederated}
          style={{
            flexDirection: typeViewButton === 'list' ?  'row' : 'column',
            justifyContent: typeViewButton === 'list' ?  'space-between' : 'space-between',
            width: typeViewButton === 'list' ?  '100%' : '300px',
            height: typeViewButton === 'list' ?  '143px' : '325px',
          }}
          >
            <div 
               style={{
                display:'flex',
                justifyContent:'space-between',
                padding: typeViewButton === 'list' ? '.5rem' : 0,
                flexDirection: typeViewButton === 'list' ?  'column-reverse' : 'column',
                width: typeViewButton === 'list' ?  '25rem' : " 100%",
                gap: '.5rem'
              }}
              >
                <h2 className={styles.titleRunning}
                  style={{
                    WebkitLineClamp : typeViewButton === 'list' ?  '2' : '1',
                  }}
                >
                  Prioritizing Customers for Optimal Return from my Sales Team  {data.title}
                </h2>
                <div className={styles.boxStage}>
                  <span className={styles.type}>{data.type}</span>
                  <p>{data.description}</p>
                </div>
              </div>
              {
                typeViewButton === 'list' && <div className={styles.line}></div>
              }
              <div
                style={{
                  display:'flex',
                  alignItems: typeViewButton === 'list' ?  'center' : '',
                  gap: typeViewButton === 'list' ?  '1rem' : '',
                  justifyContent: typeViewButton === 'list' ? 'center' : '',
                  flexWrap:  typeViewButton === 'list' ? 'wrap' : 'nowrap',
                  width: typeViewButton === 'list' ?  '14rem' : " 100%",
                }}
                className={typeViewButton === 'list' ? styles.boxCategories_list :  styles.boxCategories }>
                {
                  data.categories.slice(0, 3).map((category: any, index: any) => (
                    <span 
                      key={index} 
                      style={{
                        height: '2rem',
                      }}
                      className={styles.categorie}>
                      {category}
                    </span>
                  ))
                }
          
                {data.categories.length > 3 && (
                  <span 
                    className={styles.categorie}
                    style={{
                      height: '2rem',
                      width: '2.2rem'
                    }}
                  >
                    +{data.categories.length - 3} 
                  </span>
                )}
              </div>
              {
                typeViewButton === 'list' && <div className={styles.line}></div>
              }
              <div 
                className={styles.contain_developments}
                style={{
                  display:'flex',
                  alignItems: typeViewButton === 'list' ?  'flex-start' : '',
                  gap: typeViewButton === 'list' ?  '3vw' : '',
                  padding: typeViewButton === 'list' ?  '1.5rem 1rem' : '',
                  justifyContent: typeViewButton === 'list' ? 'center' : '',
                  flexWrap:  typeViewButton === 'list' ? 'nowrap' : 'nowrap',
                  width: typeViewButton === 'list' ?  '19vw' : " 100%",
                }}
                >
                  <div className={styles.box_data_developments}>
                    <strong>{data.companiesInvolved} </strong>
                    <span
                     style={{
                      width: typeViewButton === 'list' ?  '6rem' : " ",
                      fontSize: typeViewButton === 'list' ?  '.8rem' : " ",

                      }}
                    >
                      Companies Involved
                    </span> 
                  </div>

                  <div className={styles.box_data_developments}> 
                    <strong>+${data.expectedOutcomes}</strong>
                    <span
                     style={{
                      width: typeViewButton === 'list' ?  '6rem' : " ",
                      fontSize: typeViewButton === 'list' ?  '.8rem' : " ",
                      }}
                    >
                      Expected Outcomes
                    </span>
                  </div>

                  <div className={styles.box_data_developments}>
                    <strong>{data.availableShares} USDC </strong>
                    <span
                     style={{
                      width: typeViewButton === 'list' ?  '6rem' : " ",
                      fontSize: typeViewButton === 'list' ?  '.8rem' : " ",

                      }}
                    >
                     Available Shares
                    </span>
                  </div>
              </div>

        

              

              <div className={styles.perShare}>
              {
                typeViewButton === 'box' ?
                 <div className={styles.lineSeparator}></div>
                :
                  <div className={styles.line_share}></div>
              }
                  <strong>{data.pricePerShare} USDC</strong>
                  <span>Per share</span>
              </div>
          </div>
      </Link>
      ))
    }
  </div>
)
}

export default CardFederated