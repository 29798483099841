import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./scss/main.scss";

import { GlobalNavbar } from "./components/GlobalNavbar";
import { ROUTES } from "./config/Routes";
import { LandingPage } from "./landing/LandingPage";
import { TokenomicsRoadmapPage } from "./landing/TokenomicsRoadmapPage";
import { DocsPage } from "./landing/DocsPage";
import MarketplacePage from "./pages/MarketPlacePage/MarketplacePage";
import JoinCommunityForm from "./pages/JoinCommunityForm/JoinCommunityForm";
import BuyIntlForm from "./pages/BuyIntlForm/BuyIntlForm";
import ProductPage from "./pages/ProductPage/ProductPage";
import { AdminApp } from "./adminApp";
import { Provider, useSelector } from "react-redux";
import { store as storeAdmin } from "./adminApp/store/store";
import { store as storeClient } from "./store/store";
import { ADMIN_PANEL_URL } from "./adminApp/constants";
import Cart from "./components/Cart/Cart";
import ConnectWallet from "./components/ConnectWallet/ConnectWallet";
import { NotFound } from "./components/NotFound";
import {
  WagmiConfig,
  configureChains,
  createConfig,
  mainnet,
  sepolia,
} from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { infuraProvider } from "wagmi/providers/infura";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { LedgerConnector } from "wagmi/connectors/ledger";
import DetailFederated from "./pages/MarketPlacePage/Details/DetailFederated/DetailFederated";
import DetailSingle from "./pages/MarketPlacePage/Details/DetailSingle/DetailSingle";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, sepolia],
  [
    infuraProvider({ apiKey: `${process.env.REACT_APP_INFURA_API_KEY}` }),
    publicProvider(),
  ],
);

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "IntellToken",
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: `${process.env.REACT_APP_WALLETCONNECT_PROJECT_ID}`,
      },
    }),
    new LedgerConnector({
      chains,
      options: {
        projectId: `${process.env.REACT_APP_WALLETCONNECT_PROJECT_ID}`,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

function App() {
  const isAdmin = location.pathname.indexOf(ADMIN_PANEL_URL) === 0;
  const store = isAdmin ? storeAdmin : storeClient;
  const clientAppWrapperClasses = "container main_container";
  const clientAppHeaderWrapperClasses =
    "container main_container header-container";

  return (
    // eslint-disable-next-line
    // @ts-ignore

    <Provider store={store}>
      <WagmiConfig config={config}>
        <div>
          <BrowserRouter>
            {!isAdmin && (
              <div className={clientAppHeaderWrapperClasses}>
                <GlobalNavbar />
              </div>
            )}
            <Routes>
              <Route
                path={ROUTES.HOME}
                element={
                  <div className={clientAppWrapperClasses}>
                    <LandingPage />
                  </div>
                }
              />
              <Route
                path={ROUTES.ROADMAP}
                element={
                  <div className={clientAppWrapperClasses}>
                    <TokenomicsRoadmapPage key={1} defaultActive="roadmap" />
                  </div>
                }
              />
              <Route
                path={ROUTES.TOKENOMICS}
                element={
                  <div className={clientAppWrapperClasses}>
                    <TokenomicsRoadmapPage key={2} defaultActive="tokenomics" />
                  </div>
                }
              />

              <Route
                path={ROUTES.DOCS}
                element={
                  <div className={clientAppWrapperClasses}>
                    <DocsPage />
                  </div>
                }
              />
              <Route
                path={ROUTES.MARKETPLACE}
                element={
                  <div className={clientAppWrapperClasses}>
                    <MarketplacePage />
                  </div>
                }
              />

              <Route path={ROUTES.DETAIL_FEDERATED} element={<DetailFederated/>} />
              <Route path={ROUTES.DETAIL_SINGLE} element={<DetailSingle/>} /> 
 
              <Route
                path={ROUTES.PRODUCT}
                element={
                  <div className={clientAppWrapperClasses}>
                    <ProductPage />
                  </div>
                }
              />
              <Route
                path={ROUTES.JOIN}
                element={
                  <div className={clientAppWrapperClasses}>
                    <JoinCommunityForm />
                  </div>
                }
              />
              <Route
                path={ROUTES.BUY}
                element={
                  <div className={clientAppWrapperClasses}>
                    <BuyIntlForm />
                  </div>
                }
              />
              <Route path={ROUTES.ADMIN} element={<AdminApp />} />
              <Route
                path={"*"}
                element={
                  <div className={clientAppWrapperClasses}>
                    <NotFound />
                  </div>
                }
              />
            </Routes>
          </BrowserRouter>
          <Cart />
          <ConnectWallet />
        </div>
      </WagmiConfig>
    </Provider>
  );
}

export default App;
