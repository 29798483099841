import React from "react";
import styles from "./index.module.scss";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { ReactComponent as ShareIcon } from "./images/share.svg";
import { ReactComponent as CopyIcon } from "./images/copy.svg";
import { ReactComponent as FacebookIcon } from "./images/facebook.svg";
import { ReactComponent as TwitterIcon } from "./images/twitter.svg";
import {
  FacebookShareButton,
  TwitterShareButton
} from 'react-share';

export const Share = () => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleCopyHyperlink = async () => {
    await handleCopy(window.location.href);
    setOpen(false);
  };

  const handleCopy = (value:string) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(value);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={<div>
            <span className={styles.shareIconWrapper}>
              <span onClick={handleCopyHyperlink} className={styles.copyLinkContainer}>
                <span><CopyIcon/></span>Copy link
              </span>
            </span>
             <span className={styles.shareIconWrapper}>
               <FacebookShareButton url={window?.location?.href} beforeOnClick={handleTooltipClose}>
                 <span><FacebookIcon/></span>Share on Facebook
               </FacebookShareButton>
             </span>
             <span className={styles.shareIconWrapper}>
               <TwitterShareButton url={window?.location?.href} beforeOnClick={handleTooltipClose}>
                 <span><TwitterIcon/></span>Share on Twitter
               </TwitterShareButton>
             </span>
          </div>}
          classes={{ tooltip: styles.tooltip, popper: styles.popper }}
          placement={"bottom-end"}
        >
          <div className={styles.modelShare} onClick={handleTooltipOpen}>
            <span><ShareIcon/></span>
            Share
          </div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};
