import { fieldName as button } from '../Form/fields/FormFieldButton/index.js';
import { fieldName as input } from '../Form/fields/FormFieldInput/index.js';
import { fieldName as langs } from '../Form/fields/FormFieldLangs/index.js';
import { fieldName as title } from '../Form/fields/FormFieldTitle/index.js';
import { fieldName as select } from '../Form/fields/FormFieldSelect/index.js';
import { fieldName as divider } from '../Form/fields/FormFieldDivider/index.js';
import { fieldName as files } from '../Form/fields/FormFieldFiles/index.js';
import { fieldName as checkbox } from '../Form/fields/FormFieldCheckbox/index.js';

export default function ({
    formTitle,
    categoriesOptions,
    subcategoriesOptions,
    disabled,
    onClose,
    handlePickCategory,
  hiddenCategory,
  hiddenCollection,
  isComingSoon
}) {
    return [
        {
            field: title,
            name: 'titleField',
            settings: {
                label: formTitle
            }
        },
        {
            field: langs,
            name: 'lang'
        },
        {
            field: divider,
            name: 'divider'
        },
        {
            field: select,
            name: 'collection',
            settings: {
                label: 'Collection',
                options: categoriesOptions,
                onChangeCallback: handlePickCategory
            },
            validators: [
                {
                    name: 'required',
                    options: { text: 'Choose collection' }
                }
            ],
            langInsensitive: true
        },
        {
            field: select,
            name: 'category',
            settings: {
                label: subcategoriesOptions === undefined
                  ? 'Category (choose collection first)'
                  : subcategoriesOptions === null
                    ? 'Category (no options available, please, add categories to current collection)'
                    : 'Category',
                options: subcategoriesOptions || [],
                disabled: !subcategoriesOptions
            },
            validators: [
                {
                    name: 'required',
                    options: { text: 'Choose category' }
                }
            ],
            langInsensitive: true
        },
        {
            field: input,
            name: 'name',
            settings: {
                label: 'Model NFT Name',
                maxLength: 50
            },
            validators: [
                {
                    name: 'required',
                    options: { text: 'Model NFT Name is required' }
                }
            ]
        },
        {
            field: input,
            name: 'alias',
            settings: {
                label: 'Model NFT Alias',
                forbiddenSymbols: [':', '/', '.', '?', '&', '=', '#', '+', '%', '@', '_']
            },
            langInsensitive: true,
            validators: [
                {
                    name: 'required',
                    options: { text: 'Model NFT Alias is required' }
                }
            ]
        },
        {
            field: input,
            name: 'description',
            settings: {
                label: 'Model NFT Description',
                multiline: true,
                maxLength: 250
            },
            validators: [
                ...(!isComingSoon ? [{
                    name: 'required',
                    options: { text: 'Model NFT Description is required' }
                }] : [])
            ]
        },
        {
            field: input,
            name: 'businessNeed',
            settings: {
                label: 'Model NFT Business Need Description',
                multiline: true,
                maxLength: 250
            },
            validators: [
                ...(!isComingSoon ? [{
                    name: 'required',
                    options: { text: 'Model NFT Business Need Description is required' }
                }] : [])
            ]
        },
        {
            field: input,
            name: 'companyTargets',
            settings: {
                label: 'Model NFT Company Targets',
                multiline: true,
                maxLength: 250
            },
            validators: [
                ...(!isComingSoon ? [{
                    name: 'required',
                    options: { text: 'Model NFT Company Targets is required' }
                }] : [])
            ]
        },
        {
            field: input,
            name: 'availableShare',
            settings: {
                label: '# of Available Share',
                type: 'number',
                min: 0
            },
            langInsensitive: true,
            validators: [
                ...(!isComingSoon ? [{
                    name: 'required',
                    options: { text: 'Available Share is required' }
                }] : [])
            ]
        },
        {
            field: input,
            name: 'pricePerShare',
            settings: {
                label: 'Price per Share',
                type: 'number',
                min: 0
            },
            langInsensitive: true,
            validators: [
                ...(!isComingSoon ? [{
                    name: 'required',
                    options: { text: 'Price per Share is required' }
                }] : [])
            ]
        },
        {
            field: checkbox,
            name: 'comingSoon',
            settings: {
                label: 'Coming Soon Indicator'
            },
            langInsensitive: true
        },
        {
            field: checkbox,
            name: 'verified',
            settings: {
                label: 'Model NFT Verified'
            },
            langInsensitive: true
        },
        {
            field: divider,
            name: 'divider'
        },
        {
            field: title,
            name: 'titleField',
            settings: {
                label: 'Model NFT Image (max file size is 1MB)',
                variant: 'h6'
            }
        },
        {
            field: files,
            name: 'image',
            settings: {
                webp: true,
                max: 1,
                maxSize: 1048576 // 1MB in bytes
            },
            langInsensitive: true,
            validators: [
                ...(!isComingSoon ? [{
                    name: 'required',
                    options: { text: 'NFT Image is required' }
                }] : [])
            ]
        },
        {
            field: divider,
            name: 'divider'
        },
        {
            field: title,
            name: 'titleField',
            settings: {
                label: 'NFT settings',
                variant: 'h6'
            }
        },
        ...(hiddenCollection ? [{
            field: title,
            name: 'titleField',
            settings: {
                label: 'Visibility of selected collection  is set to hidden',
                variant: 'body1'
            }
        },] : []),
        ...(hiddenCategory ? [{
            field: title,
            name: 'titleField',
            settings: {
                label: 'Visibility of selected category is set to hidden',
                variant: 'body1'
            }
        },] : []),
        {
            field: checkbox,
            name: 'hidden',
            settings: {
                label: 'Hidden',
                disabled: hiddenCollection || hiddenCategory
            },
            langInsensitive: true
        },
        {
            field: divider,
            name: 'divider'
        },
        {
            field: button,
            name: 'button',
            settings: {
                label: 'Save',
                disabled
            }
        }
    ];
}
