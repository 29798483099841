import React from "react";
import "./css/_tokenomics_roadmap.scss";

import { DonutChart } from "../components/DonutChart";

export const Tokenomics = () => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-8 container">
        <div className="info-alocation">
          <h3 className="text-h3">Token Allocation</h3>
          <p className="text-sm">
            We believe in transparency and want to share this information with our community, so that everyone can have a clear understanding of where our resources are going.
            Below is the breakdown of how our tokens will be allocated for the INTELL Token ($INTL) issuance.
          </p>
        </div>

        <div className="info-alocation">
          <h3 className="text-h6">INTELL Token ($INTL) Distribution</h3>
          <p className="text-sm">
            Total Issuance: 1,000,000,000 $INTL to be distributed across the TIEX Ecosystem, including Investors, Team, Advisors and Community
          </p>
          <DonutChart />
          <p className="text-sm">
            To further illustrate how we will support our Community and their utilization of the INTELL Token, below is the breakdown of the 25.5% of Tokens allocated to ‘Community’ in the above chart:
          </p>
          <table className="plain-table">
            <thead>
            <tr>
              <th className="rounded-top-left">COMMUNITY</th>
              <th className="rounded-top-right">
                25.5%
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Community Programs</td>
              <td>7.0%</td>
            </tr>
            <tr>
              <td>Creator Emissions</td>
              <td>5.0%</td>
            </tr>
            <tr>
              <td>Liquidity</td>
              <td>8.5%</td>
            </tr>
            <tr>
              <td className="rounded-bottom-left">Node Emissions</td>
              <td className="rounded-bottom-right">5.0%</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="divider"></div>
        <div className="info-alocation">
          <p className="text-sm">
            Although we will launch with 1,000,000,000 INTELL Tokens at our TGE (Token Generation Event),  please note that many tokens are subject to vesting and lockup periods. See below for details
          </p>
        </div>
        <table className="plain-table">
          <thead>
            <tr>
              <th className="rounded-top-left">ROLE</th>
              <th>% ALLOCATION</th>
              <th className="rounded-top-right">VESTING DETAILS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Team</td>
              <td>17%</td>
              <td>12-month cliff; 36-month vesting</td>
            </tr>
            <tr>
              <td>Advisors</td>
              <td>3%</td>
              <td>12-month cliff; 24-month vesting</td>
            </tr>
            <tr>
              <td>Investors</td>
              <td>10%</td>
              <td>12-month cliff; 18-month vesting
              </td>
            </tr>
            <tr>
              <td>Treasury</td>
              <td>37.5%</td>
              <td>24-month vesting</td>
            </tr>
            <tr>
              <td>Community Programs</td>
              <td>7%</td>
              <td>48-month vesting</td>
            </tr>
            <tr>
              <td>Emissions</td>
              <td>10%</td>
              <td>3-month cliff; 48-month vesting</td>
            </tr>
            <tr>
              <td>Liquidity</td>
              <td>8.5%</td>
              <td>100% unlocked at TGE</td>
            </tr>
            <tr>
              <td className="rounded-bottom-left">Public Launch</td>
              <td>7%</td>
              <td className="rounded-bottom-right">100% unlocked at TGE</td>
            </tr>
          </tbody>
        </table>
        <div className="divider"></div>
        <div className="info-alocation">
          <h6 className="text-h6 text-primary-blue">
            UTILIZATION and ALLOCATION of AI-MODEL SUBSCRIPTION FEES
          </h6>
          <p className="text-sm">
            The chart below provides the entities that may be eligible to receive a portion of the subscription Fees paid by companies and businesses (Buyers). This Fee provides Buyers access and utilization rights to the output of the AI Models and LLMs. The revenue from Fees is distributed to the entities that have contributed to the AI-Model development and utilization processes. For investors and creators, allocation percentages are determined for each Model NFT and are integrated into the Model smart contract within the NFT purchase process.
            <br />
            Some model types (like LLMs) are more complex and therefore Operational costs – to manage, execute and integrate models and their output - will be higher.  Also, as models are used and ‘learn’ more about the Buyer’s business and the environment, the contribution of each component will change. Also keep in mind that the original Creator will determine the percentage of revenue allocated to NFT owners. If you are an investor, please review and understand the percentage of NET Revenue that may be allocated to each NFT before you invest. You can find this information on the model NFT Product Page in the Model NFT Marketplace.
          </p>
        </div>
        <table className="plain-table">
          <thead>
          <tr>
            <th className="rounded-top-left">ENTITY </th>
            <th className="rounded-top-right">DESCRIPTION of REVENUE SHARE OPPORTUNITY and/or ALLOCATION</th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td>Investors (<span className="text-underlined text-italic">e.g., INTELL Token Holders and NFT Owners</span>)</td>
              <td>
                The portion of NET Revenue – allocated to NFT Owners – may be available to shareowners in the amount  proportionate to the # of shares owned (% of NFT purchased) by an individual investor
              </td>
            </tr>
            <tr>
              <td>Model Developers/Creators (<span className="text-italic">e.g., Data Scientists</span>)</td>
              <td>
                The portion of NET revenue allocated to developers of the AI and LLM models to incentivize the creation of high-quality models; If more than one contributing developer, allocation is proportionate to the amount of copyright ownership
              </td>
            </tr>
            <tr>
              <td>Operational Costs</td>
              <td>
                The portion of TOTAL Revenue required to cover the platform{"'"}s operational expenses, such as infrastructure, processing efforts & fees, and all resources – including human –necessary for ongoing model management & maintenance
              </td>
            </tr>
            <tr>
              <td>Asset Providers (<span className="text-italic">Unique Data of Intelligence Providers</span>)</td>
              <td>
                Some Models require additional assets – most notably specific data sets – as unique inputs to generate the outputs. In those instances, the owner of that asset may be allocated a portion of the revenue.
              </td>
            </tr>
            <tr>
              <td className="rounded-bottom-left">Contribution Assessment</td>
              <td className="rounded-bottom-right">
                Each component of the intelligence creation workflow – including the Model – contributes to the value delivered to the Buyer. Which changes over time. We will perform a periodic assessment of that contribution and adjust the revenue allocation accordingly.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
