import React, { useState, useCallback } from 'react';

import classNames from 'classnames';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SnackbarContent from '@mui/material/SnackbarContent';
import Snackbar from '@mui/material/Snackbar';

import NewCredentialsForm from '../../../adminApp/components/NewCredentialsForm';

import { useDispatch } from 'react-redux';
import { setAuthenticated } from '../../../adminApp/store/reducers/application.js';

import { makeStyles } from '@mui/styles';

import { signIn } from '../../../adminApp/services/signIn';
import { ADMIN_PANEL_URL, TOKEN_LOCAL_STORAGE_NAME } from "../../../adminApp/constants";
import { Alert } from '@mui/lab';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 64px)'
  },
  container: {
    width: '500px',
    marginTop: '-40px'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    width: '400px'
  },
  error: {
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    padding: '0'
  },
  margin: {
    padding: '0',
    minWidth: 'unset',
    background: 'transparent'
  }
}));

const steps = [
  'Authentication',
  'Changing Credentials'
];

const Credentials = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [authentication, setAuthentication] = useState({ login: '', password: '' });
  const [user, setUser] = useState({ email: '', login: '' });
  const [activeStep, setActiveStep] = useState(0);
  const [authFailed, setAuthFailed] = useState(false);
  const handleHideFailMessage = useCallback(() => {
    setAuthFailed(false);
  }, []);
  const handleAuthenticationSubmit = useCallback((event: { preventDefault: () => void; }) => {
    event.preventDefault();

    const { login, password } = authentication;

    const credentials = {
      login: login.trim(),
      password: password.trim()
    };

    signIn(credentials)
      .then(({ data }) => {
        setUser({
          login: data.admin.login,
          email: data.admin.email
        });
        setActiveStep(1);
      })
      .catch(() => {
        setAuthentication({
          login,
          password: ''
        });
        setAuthFailed(true);
      });
  }, [authentication]);

  const handleNewCredentialsDone = useCallback(() => {
    localStorage.setItem(TOKEN_LOCAL_STORAGE_NAME, '');
    dispatch(setAuthenticated(false));

    navigate(ADMIN_PANEL_URL);
  }, []);

  const handleAuthenticationChange = useCallback((credential: string) => (event: { target: { value: string; }; }) => {
    setAuthentication(authentication => ({ ...authentication, [credential]: event.target.value }));
  }, []);

  const getContentByStep = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return renderAuthenticationForm();
      case 1:
        return <NewCredentialsForm
          type='authentication'
          initial={{
            email: user.email,
            login: user.login
          }}
          authentication={authentication}
          onDone={handleNewCredentialsDone}
        />;
    }
  };

  const renderAuthenticationForm = () => {
    return <div>
      <form className={classes.form} onSubmit={handleAuthenticationSubmit}>
        <TextField
          label='Login'
          value={authentication.login}
          onChange={handleAuthenticationChange('login')}
          margin='normal'
          variant='outlined'
          fullWidth
          InputLabelProps={{
            shrink: !!authentication.login
          }}
          required
        />
        <TextField
          label='Password'
          value={authentication.password}
          onChange={handleAuthenticationChange('password')}
          margin='normal'
          variant='outlined'
          fullWidth
          required
          InputLabelProps={{
            shrink: !!authentication.password
          }}
          type='password'
        />
        <Button variant='contained' color='primary' type='submit' fullWidth>
          Submit
        </Button>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        onClose={handleHideFailMessage}
        open={authFailed}
        autoHideDuration={null}
      >
        <SnackbarContent
          className={classNames(classes.error, classes.margin)}
          classes={{ message: classes.message }}
          message={
            <span id='client-snackbar' className={classes.message}>
                            <Alert onClose={handleHideFailMessage} severity="error">
                                Invalid username or password
                            </Alert>
                        </span>
          }
        />
      </Snackbar>
    </div>;
  };

  return <div className={classes.root}>
    <div className={classes.container}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.content}>
        {getContentByStep(activeStep)}
      </div>
    </div>
  </div>;
};

export default Credentials;
