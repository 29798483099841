import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import NewCredentialsForm from '../NewCredentialsForm/index.js';

import { Typography, SnackbarContent, Button, CircularProgress } from '@mui/material';
import { amber } from '@mui/material/colors/index.js';
import { makeStyles } from '@mui/styles';

import { checkRecoveryToken } from "../../services/checkRecoveryToken.ts";

import { ADMIN_PANEL_URL } from "../../constants/index.js";
import { Alert } from '@mui/lab';

const useStyles = makeStyles(() => ({
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    warningBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '110px'
    },
    warning: {
        backgroundColor: amber[700]
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        padding: '0'
    },
    margin: {
        padding: '0',
        minWidth: 'unset',
        background: 'transparent'
    }
}));

const RecoveryForm = ({ token, email }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [tokenIsValid, setTokenIsValid] = useState(false);

    useEffect(() => {
        checkRecoveryToken({
            token,
            email
        })
            .then(() => {
                setTokenIsValid(true);
                setLoading(false);
            })
            .catch(() => {
                setTokenIsValid(false);
                setLoading(false);
            });
    }, []);

    const redirectToMain = () => {
        window.location.href = ADMIN_PANEL_URL;
    };

    if (loading) {
        return <div className={classes.loader}>
            <CircularProgress />
        </div>;
    }

    if (tokenIsValid) {
        return <div>
            <Typography variant='h5'>Account Recovery</Typography>
            <NewCredentialsForm
                type='recovery'
                initial={{
                    email
                }}
                recovery={{
                    token,
                    email
                }}
                onDone={redirectToMain}
            />
        </div>;
    }

    return <div className={classes.warningBlock}>
        <SnackbarContent
            className={classNames(classes.warning, classes.margin)}
            classes={{ message: classes.message }}
            message={
                <span id='client-snackbar' className={classes.message}>
                    <Alert severity="warning">
                        This link is outdated
                    </Alert>
                </span>
            }
        />
        <Button variant='contained' color='primary' href={ADMIN_PANEL_URL}>
            Go to Main Page
        </Button>
    </div>;
};

RecoveryForm.propTypes = {
    token: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
};

export default RecoveryForm;
