import React from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

export interface IBreadcrumbs {
  routes: { title: string, url: string }[]
}

export const Breadcrumbs = ({ routes }: IBreadcrumbs) => {
  return (
    <div className={styles.root}>
      {routes.map((route, i) => (<div key={i} className={styles.link}>
        <Link to={route.url}><span>{route.title}</span></Link>
      </div>))}
    </div>
  );
};
