import React, {useEffect, useState} from 'react'
import styles from "./index.module.scss";
import { IconArrowB, IconArrowT, IconSearch, IconTick } from '../assets/Icons/Icons';
import { Button} from 'antd';
 
type SelectedStages = string[];

interface Props{
    typeSection : string;
}

const Filters = ({typeSection}:Props) => {

    const [filterPersonalize, setFilterPersonalize] = useState(false)
    const [filterSort, setFilterSort] = useState(false)
    const [selectCategories, setSelectCategories] = useState(false)
    const [selectedStages, setSelectedStages] = useState<SelectedStages>([]);
    const [minValue, setMinValue] = useState<string>("0.01");  
    const [maxValue, setMaxValue] = useState<string>("0.50");
    const [selectedOptionSort, setSelectedOptionSort] = useState('Sort by');
    const [selectedSort, setSelectedSort] = useState('LH');
    const [inputValue, setInputValue] = useState<string>('');


    const stageMapping: { [key: string]: string } = {
        'Proposal': 'P',
        'Running': 'R',
        'Closed': 'C',
        'Marketing': 'M'
    };
      

    const sortMapping = {
        'Low to high': 'LH',
        'High to low': 'HL',
        'Most relevant': 'MR',
        'Alphabetical': 'A'
      };
      
    const simplifiedSelectedStages = selectedStages.map(stage => stageMapping[stage]);

       /*  sort */
    const handleOptionClick = (option: keyof typeof sortMapping) => {
        setSelectedSort(sortMapping[option])
        setSelectedOptionSort(option);
        setFilterSort(false);
    };

    const postFilter = {
        filterOptions: {
          filterBy: {
            type: typeSection,
            categories: ["C1", "C2", "C3"],
            stages: simplifiedSelectedStages,
            priceRange: {
              minPrice: minValue,
              maxPrice: maxValue
            }
          },
          sortBy: selectedSort
        },
      };


    const sendDataFilters = () => {
        fetch('https://run.mocky.io/v3/3ce378ac-7961-4805-a778-8052d4604e73', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postFilter)
        })
          .then(response => response.json())
          .then(data => {
            console.log(data)
          })
          .catch(error => {
            console.error('error', error);
          });
     }

    const handleMinInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMinValue(event.target.value);  
    };
    
    const handleMaxInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMaxValue(event.target.value);  
    };
    

    const handleFilterPersonalizate = () => {
        setFilterPersonalize(!filterPersonalize)
    }

    const handleFilterSort = () => {
        setFilterSort(!filterSort)
    }

    const handleCategories = () => {
        setSelectCategories(!selectCategories)
    }

    const handleReset = () => {
        setMinValue('0.01')
        setMaxValue('0.50')
        setSelectedStages([])
    }


    
    const handleStageClick = (stage: string) => {
        if (selectedStages.includes(stage)) {
          setSelectedStages(selectedStages.filter((selected) => selected !== stage));
        } else {
          setSelectedStages([...selectedStages, stage]);
        }
    };
    
    /* input search */
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };


    const dataFilterStagesSingle = [
        {stage:'Proposal'},
        {stage:'Marketing'},
        {stage:'Running'},
        {stage:'Closed'}
    ]


  return (
    <div className={styles.filters_market}>
        
        <div className={styles.filter_personalizate}>
            <strong>Personalize your search</strong>
            <div className={styles.filter_personalizate_select} onClick={handleFilterPersonalizate}>
                <span>Filter by</span>
                {
                    filterPersonalize ?  <IconArrowT/> :  <IconArrowB/>
                }
            </div>
            { filterPersonalize && 
                <div className={styles.box_personalizate}>
                    <div className={styles.contain_data_filter_p}>
                        <strong>Filter by</strong>
                        <div className={styles.box_filter_categories}>
                            <span>Categories</span>
                            <div className={styles.filterCategories} onClick={handleCategories}>
                                <span> All categories </span>
                                {
                                    selectCategories ?  <IconArrowT/> :  <IconArrowB/>
                                }
                            </div>
                        </div>

                        <div className={styles.box_filter_stages}>
                            <span >Stages</span>
                            <div className={styles.stages}>
                                {
                                    dataFilterStagesSingle.map((data , index)=> (
                                        <span
                                        key={index}
                                        style={{
                                            border: selectedStages.includes(data.stage) ? '2px solid #0166FFcc' : '2px solid #0e0f193b'
                                          }}
                                        onClick={() => handleStageClick(data.stage)}
                                      >
                                        {data.stage}
                                        {
                                            selectedStages.includes(data.stage) && <div className={styles.iconTick}><IconTick/> </div>
                                        }
                                       
                                      </span>
                                    ))
                                }
                            </div>
                        </div>

                        <div className={styles.box_filter_price}>
                            <span >Price per share</span>

                            <div className={styles.contain_input_price}>
                                <div className={styles.box_input_price}>
                                    <span>Min</span>
                                    <div className={styles.input_price}>
                                        <input 
                                            type="number" 
                                            inputMode="numeric"
                                            defaultValue={minValue}
                                            onChange={handleMinInputChange}
                                        />
                                        <span>USDC</span>
                                    </div>
                                </div>

                                <div className={styles.box_input_price}>
                                    <span>Max</span>
                                    <div className={styles.input_price}>
                                        <input 
                                            type="number" 
                                            inputMode="numeric"
                                            defaultValue={maxValue}
                                            onChange={handleMaxInputChange}
                                        />
                                        <span>USDC</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-center items-center gap-[1rem] flex-col mt-2">
                            <Button 
                                type="primary"
                                style={{textTransform:'none', fontFamily:'inter'}}
                                onClick={() => {
                                    sendDataFilters()
                                    handleFilterPersonalizate()
                                }}
                                className='w-full !bg-[#3485FFf5] !rounded-[12px] h-[3rem] font-[600] !text-[1rem] !tracking-wide text-white '
                            > 
                                Apply now 
                            </Button>
                            
                            <strong className='!text-[1.05rem] text-[#5B5B5B] underline cursor-pointer' onClick={handleReset}>Reset all filters</strong>
                        </div>
                    </div>
                </div>
            }
        </div>



        <div className={styles.filter_Sort}>
            <strong>Sort by</strong>
            <div className={styles.filter_Sort_select} onClick={handleFilterSort}>
                <span>{selectedOptionSort}</span>
                {
                    filterSort ?  <IconArrowT/> :  <IconArrowB/>
                }
            </div>
            { filterSort && 
                <div className={styles.box_personalizate_sort}>
                    <div className={styles.contain_data_filter_sort}>
                        <ul>
                            <li onClick={() => handleOptionClick("Low to high")}>Price: Low to high</li>
                            <li onClick={() => handleOptionClick("High to low")}>Price: High to low</li>
                            <li onClick={() => handleOptionClick("Most relevant")}>Most relevant</li>
                            <li onClick={() => handleOptionClick("Alphabetical")}>Alphabetical</li>
                        </ul>
                    </div>
                </div>
            }
        </div>



        <div className={styles.filter_search}>
            <input 
                type='text'  
                placeholder={ window.innerWidth > 430 ? 'Are you looking something specific? Search here' : 'Search here'}
                value={inputValue}
                onChange={handleInputChange}
            />
            <div className={styles.box_iconSearch}>
                <IconSearch/>
            </div>
        </div>
    </div>
  )
}

export default Filters