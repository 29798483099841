import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import {
    Typography,
    IconButton,
    ListItemSecondaryAction,
    ListItemText,
    ListItemIcon,
    ListItem,
    List,
    Button,
    Drawer,
    Divider,
    Dialog,
    DialogActions,
    Tooltip,
    DialogContentText,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { Delete, Edit, Folder, Reorder, ArrowBack, Add } from '@mui/icons-material';

import { DEFAULT_LOCALE } from '../../constants/index.js';

import getItemName from '../../utils/getItemName.js';

import { makeStyles } from '@mui/styles';

import arrayMove from '../../utils/arrayMove.js';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        '@media (max-width:1200px)': {
            flexDirection: 'column-reverse'
        }
    },
    drawer: {
        maxWidth: '400px',
        minWidth: '350px',
        flexShrink: 0,
        '@media (max-width:1200px)': {
            width: 'calc(100% - 60px)',
            maxWidth: 'unset',
            margin: '30px 30px 0 30px',
            boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
        },
        '@media (max-width:600px)': {
            width: 'calc(100% - 30px)',
            margin: '15px 15px 0 15px'
        },
        '@media (max-width:400px)': {
            width: '100%',
            margin: '15px 0 0 0'
        }
    },
    drawerPaper: {
        top: '0px',
        maxWidth: '400px',
        position: 'relative',
        minHeight: '93vh',
        '@media (max-width:1200px)': {
            zIndex: '0',
            minHeight: 'unset',
            width: '100%',
            maxWidth: 'unset'
        }
    },
    content: {
        flexGrow: 1,
        padding: '30px',
        '@media (max-width:600px)': {
            padding: '15px'
        },
        '@media (max-width:400px)': {
            padding: '15px 0'
        }
    },
    hiddenMark: {
        width: '64px',
        borderRadius: '52px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '14px',
        textAlign: 'center',
        padding: '3px 6px',
        background: '#3f51b5',
        color: 'white',
        marginRight: '75px',
        marginLeft: '10px'
    },
    toolbar: {
        height: '0px'
    },
    toolbarNav: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 30px 5px 30px',
        '@media (max-width:460px)': {
            padding: '5px 16px 5px 16px'
        }
    },
    categoryTitle: {
        height: '30px'
    },
    buttonBack: {
        marginLeft: 'auto'
    },
    buttonSortable: {
        position: 'relative',
        marginRight: '12px',
        cursor: 'pointer'
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    row: {
        backgroundColor: 'white',
        zIndex: 1201,
        '&:hover $valueActions': {
            visibility: 'visible'
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.07)'
        }
    },
    rowActive: {
        backgroundColor: 'rgba(0, 0, 0, 0.07)'
    },
    valueActions: {
        visibility: 'hidden',
        '@media (max-width:780px)': {
            visibility: 'visible'
        }
    },
    categoryOptions: {
        height: '100%',
        padding: '4px 0',
    },
    listItemText: {
        maxWidth: '150px',
        '& span': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '100%'
        },
        '@media (max-width:600px)': {
            maxWidth: '120px'
        },
        '@media (max-width:400px)': {
            padding: '0'
        }
    },
    modalContent: {
        padding: '30px',
        position: 'absolute',
        width: '1200px',
        outline: 'none',
        overflowY: 'auto',
        maxHeight: '100vh',
        '@media (max-width:1300px)': {
            width: '90%'
        }
    },
    loader: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const ButtonSortable = SortableHandle(({ classes }) => (
    <Reorder className={classes.buttonSortable}> Reorder </Reorder>
));

const ItemSortable = SortableElement(({ onFormOpen, onCategoryDelete, name, onCategoryClick, value, classes, activeCategory, readonly }) => (
    <ListItem onClick={onCategoryClick(value)} button className={classNames(classes.row, { [classes.rowActive]: activeCategory?._id === value._id })}>
        {
            !readonly &&
            <ButtonSortable classes={classes}/>
        }
        <ListItemIcon>
            <Folder/>
        </ListItemIcon>
        <ListItemText
            className={classes.listItemText}
            primary={name}
        />
        {
            (value.data && (value.data[DEFAULT_LOCALE]?.hidden || value.collectionHidden)) && <div className={classes.hiddenMark}>
               Hidden
            </div>
        }
        {
            !readonly &&
            <div className={classes.valueActions}>
                <ListItemSecondaryAction className={classes.categoryOptions}>
                    <IconButton onClick={onFormOpen(value)}>
                        <Edit/>
                    </IconButton>
                    <IconButton onClick={onCategoryDelete(value)} edge="end" aria-label="delete">
                        <Delete/>
                    </IconButton>
                </ListItemSecondaryAction>
            </div>
        }
    </ListItem>
));

const SortableWrapper = SortableContainer((
    {
        categories,
        ...rest
    }) =>
    <List>
        {
            categories.map((value, i) => {
                const name = getItemName(value);
                return <ItemSortable key={i} name={name} value={value} index={i} {...rest}/>;
            })
        }
    </List>
);

const CategoriesNavbar = props => {
    const {
        title,
        onBack,
        activeCategory,
        categories,
        handleCategoryClick,
        onCategoryFormOpen,
        onCategoriesDelete,
        onCategoriesSort,
        readonly
    } = props;
    const [categoryForDelete, setCategoryForDelete] = useState(null);
    const classes = useStyles();

    const handleCategoryDelete = category => (event) => {
        event.stopPropagation();
        setCategoryForDelete(category);
    };

    const handleWarningDisagree = () => {
        setCategoryForDelete(null);
    };

    const handleWarningAgree = () => {
        onCategoriesDelete([categoryForDelete._id]);
        setCategoryForDelete(null);
    };

    const handleDragEnd = ({ oldIndex, newIndex }) => {
        const newCategories = arrayMove(categories, oldIndex, newIndex);

        onCategoriesSort(newCategories.map(({ _id }) => _id));
    };

    return <>
        <Drawer
            className={classes.drawer}
            variant="permanent"
            anchor="right"
            classes={{
                paper: classes.drawerPaper
            }}
        >
            <div className={classes.toolbarNav}>
                <Typography variant='h6' className={classes.categoryTitle}>
                    {title}
                </Typography>
                {onBack && (
                    <Tooltip title='Back'>
                        <IconButton aria-label='Back' className={classes.buttonBack} onClick={onBack}>
                            <ArrowBack/>
                        </IconButton>
                    </Tooltip>
                )}
                {
                    !readonly &&
                    <Tooltip title='Add'>
                        <IconButton aria-label='Add' onClick={onCategoryFormOpen()}>
                            <Add/>
                        </IconButton>
                    </Tooltip>
                }
            </div>
            <Divider/>
            <div className={classes.toolbar}/>
            <SortableWrapper
                axis='xy'
                onFormOpen={onCategoryFormOpen}
                onCategoryDelete={handleCategoryDelete}
                onCategoryClick={handleCategoryClick}
                onSortEnd={handleDragEnd}
                categories={categories}
                useDragHandle
                classes={classes}
                activeCategory={activeCategory}
                readonly={readonly}
            />
        </Drawer>
        <Dialog
            open={!!categoryForDelete}
            onClose={handleWarningDisagree}
        >
            <DialogTitle>Are you sure you want to delete?</DialogTitle>
            <DialogContent className={classes.warningContent}>
                <DialogContentText>{getItemName(categoryForDelete)}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleWarningDisagree} color='primary'>
                    No
                </Button>
                <Button onClick={handleWarningAgree} color='primary' autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    </>;
};

CategoriesNavbar.propTypes = {
    title: PropTypes.string,
    onBack: PropTypes.func,
    activeCategory: PropTypes.object,
    categories: PropTypes.array,
    handleCategoryClick: PropTypes.func,
    onCategoryFormOpen: PropTypes.func,
    onCategoriesDelete: PropTypes.func,
    onCategoriesSort: PropTypes.func,
    readonly: PropTypes.bool
};

CategoriesNavbar.defaultProps = {
    title: '',
    activeCategory: {},
    categories: []
};

export default CategoriesNavbar;
