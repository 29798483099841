import { post } from "../../config/axios";

type UserData = {
  firstName: string;
  lastName: string;
  email: string;
  isBasedInUS: string;
  haveCryptoWallet: string;
};

export const sendBuyRequest = (data: UserData) =>
  post("/api/client/buyRequest", data);
