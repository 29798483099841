import React from "react";
import "./css/_docs.scss";
import { DocTile } from "../components/DocsTile";

export const DocsPage = () => {
  const Doc1 = {
    date: "9/7/2023",
    title:
      "The Intelligence Exchange Whitepaper",
    subtitle: "intelligenceexchange.ai",
    source:
      process.env.PUBLIC_URL + "/media/docs/AI creativity White paper.pdf",
  };

  const Doc2 = {
    date: "9/5/2023",
    title: "A Marketplace and DAO for AI and LLM Models",
    subtitle: "intelligenceexchange.ai",
    source: process.env.PUBLIC_URL + "/media/docs/A Marketplace and DAO for AI and LLM Models.pdf",
  };

  const Doc3 = {
    date: "9/5/2023",
    title: "Deploying a “Purpose-driven Intelligence Collective",
    subtitle: "intelligenceexchange.ai",
    source: process.env.PUBLIC_URL + "/media/docs/Purpose-Driven Intelligence Collective.pdf",
  };

  const Doc4 = {
    date: "9/5/2023",
    title: "The best B2B Blockchain Use case",
    subtitle: "intelligenceexchange.ai",
    source: process.env.PUBLIC_URL + "/media/docs/The best B2B Blockchain.pdf",
  };

  const Doc5 = {
    date: "9/5/2023",
    title: "The Limits of Transactional Thinking in an AI world",
    subtitle: "intelligenceexchange.ai",
    source: process.env.PUBLIC_URL + "/media/docs/The Limits of Transactional Thinking in AI world.pdf",
  };

  return (
    <div className="row docs">
      <div className="col-sm-12 col-lg-9">
        <div className="head-section">
          <div className="head-tile">Docs</div>
        </div>
        <h4 className="text-h4 title">
          DOCUMENTATION, MATERIALS & INFORMATION
        </h4>
        <p className="subtitle">
          Below are various materials - whitepapers, articles, videos and
          thought leadership articles, blog posts etc. - to provide you with
          information about our innovation, platform and the company overall.
        </p>
        <div className="docs-container">
          <DocTile type="whitepaper" key={1} {...Doc1} />
          <DocTile type="whitepaper" key={2} {...Doc2} />
          <DocTile type="whitepaper" key={3} {...Doc3} />
          <DocTile type="whitepaper" key={4} {...Doc4} />
          <DocTile type="whitepaper" key={5} {...Doc5} />
        </div>
      </div>
    </div>
  );
};
