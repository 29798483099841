import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import {
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    List,
    ListItem,
    ListItemText,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import { Delete, Close, Add, FilterList } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import getRemovedItemName from '../AdminTable/utils/getRemovedItemName.js';


const useStyles = makeStyles(theme => ({
    highlight:
        theme.palette?.type === 'light'
            ? {
                color: theme.palette?.secondary.main
            }
            : {
                color: theme.palette?.text.primary
            },
    spacer: {
        flex: '1 1 100%'
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette?.text.secondary
    },
    title: {
        flex: '0 0 auto',
        '@media (max-width:780px)': {
        }
    },
    itemsNumber: {
        display: 'flex',
        alignItems: 'center',
        width: '130px',
        justifyContent: 'space-between'
    },
    valuesActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    closeIcon: {
        cursor: 'pointer'
    },
    toolbar: {
        width: '100%',
    },
    warningContent: {
        paddingBottom: '0'
    },
    button: {
        color: theme.palette?.secondary.main,
        whiteSpace: 'nowrap',
        '@media (max-width:1500px)': {
            '& span': {
                fontSize: '10px'
            }
        },
        '@media (max-width:1360px)': {
            whiteSpace: 'unset'
        }
    },
    search: {
        width: '250px',
        flexShrink: '0',
        '@media (max-width:1500px)': {
            width: '155px'
        }
    },
    searchRoot: {
        margin: '0 20px 0 0'
    },
    dateRoot: {
        margin: '0 20px 0 0',
    }
}));

const AdminTableHeader = props => {
    const classes = useStyles();
    const {
        selected, headerText, noActions, search, handleSearchChange,
        dateStart, handleDateStartChange,
        dateEnd, handleDateEndChange
    } = props;
    const [valueForDelete, setValueForDelete] = useState(false);

    const handleSelectedCloseClick = () => {
        props.onSelectedCloseClick();
    };

    const handleDelete = () => {
        setValueForDelete(selected);
    };

    const handleWarningDisagree = () => {
        setValueForDelete(null);
    };

    const handleWarningAgree = () => {
        if (valueForDelete) {
            setValueForDelete(null);
            const ids = selected.map(item => item._id);
            props.onDelete(ids);
        }
    };

    return <div>
        <Toolbar
            className={classNames(classes.toolbar, {
                [classes.highlight]: selected.length > 0
            })}
        >
            <div className={classes.title}>
                {selected.length > 0
                    ? (
                        <div className={classes.itemsNumber}>
                            <Close className={classes.closeIcon} onClick={handleSelectedCloseClick}/>
                            <Typography color='inherit' variant='subtitle1'>
                                {selected.length} selected
                            </Typography>
                        </div>
                    )
                    : (
                        <Typography variant='h6' id='tableTitle'>
                            {headerText}
                        </Typography>
                    )}
            </div>
            <div className={classes.spacer} />
            {
                (!noActions && !selected.length) &&
                <div className={classes.date}>
                    <TextField
                        label={'Pick start date to filter'}
                        value={dateStart}
                        onChange={handleDateStartChange}
                        margin='normal'
                        variant='outlined'
                        type='date'
                        InputLabelProps={{
                            shrink: true
                        }}
                        classes={{
                            root: classes.dateRoot
                        }}
                    />
                </div>
            }
            {
                (!noActions && !selected.length) &&
                <div className={classes.date}>
                    <TextField
                        label={'Pick end date to filter'}
                        value={dateEnd}
                        onChange={handleDateEndChange}
                        margin='normal'
                        variant='outlined'
                        type='date'
                        InputLabelProps={{
                            shrink: true
                        }}
                        classes={{
                            root: classes.dateRoot
                        }}
                    />
                </div>
            }
            {
                (!noActions && !selected.length) &&
                <div className={classes.search}>
                    <TextField
                        label={'Search by title'}
                        value={search}
                        onChange={handleSearchChange}
                        margin='normal'
                        variant='outlined'
                        multiline={false}
                        type={'text'}
                        classes={{
                            root: classes.searchRoot
                        }}
                    />
                </div>
            }
            <div className={classes.actions}>
                {selected.length > 0
                    ? (
                        <React.Fragment>
                            <Tooltip title='Delete'>
                                <IconButton aria-label='Delete' onClick={handleDelete}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    )
                    : (
                        <div className={classes.valuesActions}>
                            { props.onFiltersOpen && <Tooltip title={'Filter'}>
                                <IconButton aria-label='Filters' onClick={props.onFiltersOpen}>
                                    <FilterList />
                                </IconButton>
                            </Tooltip> }
                            {!noActions &&
                            <div className={classes.valuesActions}>
                                <Tooltip title={'Add'}>
                                    <IconButton aria-label='Add' onClick={props.onFormOpen()}>
                                        <Add />
                                    </IconButton>
                                </Tooltip>
                                   </div>
                            }
                        </div>
                    )}
            </div>
        </Toolbar>
        <Dialog
            open={!!valueForDelete}
            onClose={handleWarningDisagree}
        >
            <DialogTitle>
                Are you sure you want to delete users? Deleted users will be removed and cannot be restored
            </DialogTitle>
            <DialogContent className={classes.warningContent}>
                <List>
                    {
                        selected.map((item, i) => <ListItem key={i}>
                            <ListItemText
                              primary={getRemovedItemName(item)}
                            />
                        </ListItem>)
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleWarningDisagree} color='primary'>
                    No
                </Button>
                <Button onClick={handleWarningAgree} color='primary' autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    </div>;
};

AdminTableHeader.propTypes = {
    headerText: PropTypes.string,
    selected: PropTypes.array,
    onDelete: PropTypes.func.isRequired,
    onApprove: PropTypes.func,
    onFormOpen: PropTypes.func.isRequired,
    onSelectedCloseClick: PropTypes.func.isRequired,
    onFiltersOpen: PropTypes.func,
    handleAddNewsClick: PropTypes.func,
    isMultimedia: PropTypes.bool,
    isComment: PropTypes.bool,
    noActions: PropTypes.bool,
    search: PropTypes.string,
    handleSearchChange: PropTypes.func,
    handleStatusChange: PropTypes.func,
    status: PropTypes.string,
    dateStart: PropTypes.string,
    handleDateStartChange: PropTypes.func,
    dateEnd: PropTypes.string,
    handleDateEndChange: PropTypes.func,
    onCategorySelectorOpen: PropTypes.func,
    voteCompleted: PropTypes.bool
};

AdminTableHeader.defaultProps = {
    headerText: '',
    selected: []
};

export default AdminTableHeader;
