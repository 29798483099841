import { get } from "../../config/axios";

type UserData = {
    token: string;
    email: string;
};

export const checkRecoveryToken = ({ token, email }: UserData ) =>
  get("/api/admin/admin/check-recovery-token", { token, email });


