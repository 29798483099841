import React from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

const SuccessForm: React.FC<{
  title: string;
  onButtonClick: () => void;
}> = ({ title, onButtonClick }) => {
  return (
    <div className={styles.root}>
      <p className={styles.title}>{title}</p>
      <Link onClick={onButtonClick} className={styles.backLink} to="/">
        Back to Home page
      </Link>
    </div>
  );
};

export default SuccessForm;
