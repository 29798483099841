import formFieldLangsInfo from './FormFieldLangs/index.js';
import formFieldInputInfo from './FormFieldInput/index.js';
import formFieldDateInfo from './FormFieldDate/index.js';
import formFieldDividerInfo from './FormFieldDivider/index.js';
import formFieldFilesInfo from './FormFieldFiles/index.js';
import formFieldCheckboxInfo from './FormFieldCheckbox/index.js';
import formFieldCheckboxesInfo from './FormFieldCheckboxes/index.js';
import formFieldRadiosInfo from './FormFieldRadios/index.js';
import formFieldSelectInfo from './FormFieldSelect/index.js';
import formFieldTitleInfo from './FormFieldTitle/index.js';
import formFieldButtonInfo from './FormFieldButton/index.js';
import formFieldButtonsInfo from './FormFieldButtons/index.js';

export default {
    [formFieldInputInfo.fieldName]: formFieldInputInfo,
    [formFieldLangsInfo.fieldName]: formFieldLangsInfo,
    [formFieldDateInfo.fieldName]: formFieldDateInfo,
    [formFieldDividerInfo.fieldName]: formFieldDividerInfo,
    [formFieldFilesInfo.fieldName]: formFieldFilesInfo,
    [formFieldCheckboxInfo.fieldName]: formFieldCheckboxInfo,
    [formFieldCheckboxesInfo.fieldName]: formFieldCheckboxesInfo,
    [formFieldRadiosInfo.fieldName]: formFieldRadiosInfo,
    [formFieldSelectInfo.fieldName]: formFieldSelectInfo,
    [formFieldTitleInfo.fieldName]: formFieldTitleInfo,
    [formFieldButtonInfo.fieldName]: formFieldButtonInfo,
    [formFieldButtonsInfo.fieldName]: formFieldButtonsInfo,
};
