import "./css/_footer.scss";
import { EmailIco, LogoBig } from "../icons";
import { SocialBlock } from "../components/SocialBlock";
import { Link } from "react-router-dom";
import { ROUTES } from "../config/Routes";

export const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="container footer">
      <div className="row">
        <div className="col-lg-4 col-sm-12 col-xs-12 row ">
          <div className="col align-self-center">
            <Link to="/">
              <LogoBig className="logo" />
            </Link>
          </div>
        </div>
        <div className="col-lg-5 col-sm-12 col-xs-12 text-center text-md-start">
          <p className="text-md lh-28 link-header">Quick Links</p>
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12 link-container">
              <a href="#" className="text-sm link-footer">
                Home
              </a>
              <a href="https://www.intelligenceexchange.ai/" target='_blank' rel="noreferrer" className="text-sm link-footer">
                Company website
              </a>
              <Link to={ROUTES.JOIN} className="text-sm link-footer">
                Join our community
              </Link>
              <Link to={ROUTES.DOCS} className="text-sm link-footer">
                Read our Whitepaper
              </Link>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12 link-container">
              <Link to={ROUTES.MARKETPLACE} className="text-sm link-footer">
                AI-Model NFT Marketplace
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12 col-xs-12 text-center text-md-start">
          <p className="text-md lh-28 link-header">Contact</p>
          <div className="row">
            <div className="link-container">
              <a
                className="email text-sm"
                href="mailto:info@intelligenceexchange.ai"
              >
                <EmailIco />
                <span>info@intelligenceexchange.ai</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row social-rights">
        <div className="col-md-6 col-4" style={{ width: "fit-content" }}>
          <SocialBlock color="white" />
        </div>
        <div className="col-md-6 col-4 owner">
          <span className="text-sm">A product of </span>
          <LogoBig className="logo-small" />
        </div>
        <div className="col-md-12 col-4 rights">
          <span className="text-sm">
            © {year} The Intelligence Exchange, Inc.
          </span>
        </div>
      </div>
    </div>
  );
};
