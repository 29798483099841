import { put } from "../../../config/axios";


type Data = {
    item: any;
    id: string;
    collectionId: string;
};

export default ({ id, collectionId, item }: Data) =>
  put(`/api/admin/nft/category/${collectionId}/${id}`, item);


