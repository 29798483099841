import required from './required.js';
import email from './email.js';
import phone from './phone.js';
import max from './max.js';
import min from './min.js';

export default {
    required,
    email,
    phone,
    max,
    min
};
