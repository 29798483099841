import React, {useEffect, useState} from 'react'
import styles from "./index.module.scss";
import { Link} from 'react-router-dom';

interface Props{
    typeViewButton: string;
    dataSingle:any;
}

const CardSingle = ({typeViewButton, dataSingle}:Props) => {

  function getInfoByStage(data:any) {
    switch (data.stage) {
      case 'Proposal':
      case 'Marketing':
        return {
          price: data.reservePrice,
          text: 'Reserve Price',
        };
      case 'Closed':
        return {
          price: data.historicalPrice,
          text: 'Historical Price',
        };
      default:
        return {
          price: null,
          text: '',
        };
    }
  }


  return (
      <div 
        className={styles.boxCardSingle}
        >
        {
          dataSingle?.map((data:any,index:any) => (
            <Link  to={`/detail/single/:${data.id}`} key={index}>
              <div 
                className={styles.cardSingle}
                style={{
                  flexDirection: typeViewButton === 'list' ?  'row' : 'column',
                  justifyContent: typeViewButton === 'list' ?  'space-between' : 'space-between',
                  width: typeViewButton === 'list' ?  '100%' : '300px',
                  height: typeViewButton === 'list' ?  '143px' : '221px',

                }}
                >
                {
                  data.stage === 'Running' ?
                  < >
                    <div 
                      style={{
                        display:'flex',
                        justifyContent:'space-between',
                        padding: typeViewButton === 'list' ? '.5rem' : 0,
                        flexDirection: typeViewButton === 'list' ?  'column-reverse' : 'column',
                        width: typeViewButton === 'list' ?  '25rem' : " 100%",

                      }}
                    >
                      <h2 className={styles.titleRunning}
                        style={{
                          WebkitLineClamp : typeViewButton === 'list' ?  '2' : '1',
                        }}
                      >
                        Prioritizing Customers for Optimal Return from my Sales Team  {data.title}
                      </h2>
                      <div className={styles.boxStage}>
                        <span className={styles.type}>{data.type}</span>
                        <span className={styles.stage}>{data.stage}</span>
                      </div>
                    </div>
                    {
                      typeViewButton === 'list' && <div className={styles.line}></div>
                    }
                    <div
                      style={{
                        display:'flex',
                        alignItems: typeViewButton === 'list' ?  'center' : '',
                        gap: typeViewButton === 'list' ?  '1rem' : '',
                        justifyContent: typeViewButton === 'list' ? 'center' : '',
                        flexWrap:  typeViewButton === 'list' ? 'wrap' : 'nowrap',
                        width: typeViewButton === 'list' ?  '14rem' : " 100%",
                      }}
                      className={styles.boxCategories}>
                      {
                        data.categories.slice(0, 3).map((category: any, index: any) => (
                          <span 
                            key={index} 
                            style={{
                            height: '2rem'
                            }}
                            className={styles.categorie}>
                            {category}
                          </span>
                        ))
                      }
                
                      {data.categories.length > 3 && (
                        <span 
                          className={styles.categorie}
                          style={{
                            height: '2rem',
                            width: '2.2rem'
                          }}
                        >
                          +{data.categories.length - 3} 
                        </span>
                      )}
                    </div>
                    {
                      typeViewButton === 'list' && <div className={styles.line}></div>
                    }
                    <div 
                      className={styles.contain_developments}
                      style={{
                        display:'flex',
                        alignItems: typeViewButton === 'list' ?  'flex-start' : '',
                        gap: typeViewButton === 'list' ?  '4vw' : '',
                        padding: typeViewButton === 'list' ?  '1.5rem 1rem' : '',
                        justifyContent: typeViewButton === 'list' ? 'center' : '',
                        flexWrap:  typeViewButton === 'list' ? 'nowrap' : 'nowrap',
                        width: typeViewButton === 'list' ?  '27vw' : " 100%",
                      }}
                      >
                        <div className={styles.box_data_developments}>
                          <strong>{data.developments.availableShares} </strong>
                          <span
                          style={{
                            width: typeViewButton === 'list' ?  '6rem' : " ",
                            fontSize: typeViewButton === 'list' ?  '1rem' : " ",

                            }}
                          >
                            Available Shares
                          </span> 
                        </div>

                        <div className={styles.box_data_developments}> 
                          <strong>{data.developments.developmentsAmount}</strong>
                          <span
                          style={{
                            width: typeViewButton === 'list' ?  '6rem' : " ",
                            fontSize: typeViewButton === 'list' ?  '1rem' : " ",
                            }}
                          >
                            Developments
                          </span>
                        </div>

                        <div className={styles.box_data_developments}>
                          <strong>{data.developments.startingPrice} USDC </strong>
                          <span
                          style={{
                            width: typeViewButton === 'list' ?  '6rem' : " ",
                            fontSize: typeViewButton === 'list' ?  '1rem' : " ",

                            }}
                          >
                            Starting Price
                          </span>
                        </div>
                    </div>

                  </ >
                  :
                    <>
                      <div className="flex flex-col gap-2">
                        <div 
                            style={{
                              display:'flex',
                              justifyContent:'space-between',
                              padding: typeViewButton === 'list' ? '.5rem' : 0,
                              flexDirection: typeViewButton === 'list' ?  'column-reverse' : 'column',
                              width: typeViewButton === 'list' ?  '25rem' : " 100%",
                              gap: typeViewButton === 'list' ?  '1rem': " ", 
                            }}
                          >

                            <h2> Prioritizing Customers for Optimal Return from my Sales Team {data.title}</h2>
                            {
                              typeViewButton === 'list' ?
                                <div className={styles.boxStage_core}>
                                  <span className={styles.type_core}>{data.type}</span>
                                  <span className={styles.stage_core}>{data.stage}</span>
                                </div>
                              :

                              <div className={styles.boxCategories}>
                                {
                                  data.categories.slice(0, 3).map((category: any, index: any) => (
                                    <span 
                                      key={index} 
                                      className={styles.categorie}
                                    >
                                      {category}
                                    </span>
                                  ))
                                }
                                {data.categories.length > 3 && (
                                  <span 
                                    className={styles.categorie}
                                    style={{
                                      height: '2rem',
                                      width: '2.2rem'
                                    }}
                                  >
                                    +{data.categories.length - 3} 
                                  </span> 
                                )}
                              </div>

                            }
                      
                        </div>
                      </div>
                      {
                          typeViewButton === 'list' ?
                          <>
                            {
                                typeViewButton === 'list' && <div className={styles.line}></div>
                              }
                                <div 
                                  style={{
                                    display:'flex',
                                    alignItems: 'center',
                                    gap:  '1rem' ,
                                    justifyContent: 'center' ,
                                    flexWrap:  'wrap' ,
                                    width:  '14rem' ,
                                  }}
                                className={styles.boxCategories}> 
                                  {
                                    data.categories.slice(0, 3).map((category: any, index: any) => (
                                      <span 
                                        key={index} 
                                        className={styles.categorie}
                                        style={{
                                          height: '2rem',
                                        }}
                                      >
                                        {category}
                                      </span>
                                    ))
                                  }
                                  {data.categories.length > 3 && (
                                    <span 
                                      className={styles.categorie}
                                      style={{
                                        height: '2rem',
                                        width: '2.2rem'
                                      }}
                                      >
                                      +{data.categories.length - 3} 
                                    </span> 
                                  )}
                                </div>
                            {
                                typeViewButton === 'list' && <div className={styles.line}></div>
                            }

                            <div
                              style={{
                                display:'flex',
                                alignItems:  'center'  ,
                                flexDirection:'column',
                                gap: '0rem',
                                justifyContent:  'center' ,
                                flexWrap:  'wrap' ,
                                width:   '27vw'  ,
                              }} 
                              className={styles.box_data_developments_core}
                              >
                                <strong>{getInfoByStage(data).price } USDC </strong>
                                <span>{getInfoByStage(data).text}</span>
                            </div>
                          </>

                          :

                          <div className={styles.contain_box_stages_core}>
                            <div className={styles.boxStage_core}>
                              <span className={styles.type_core}>{data.type}</span>
                              <span className={styles.stage_core}>{data.stage}</span>
                            </div>
                            
                            <div className={styles.box_data_developments_core}>
                                <strong>{getInfoByStage(data).price } USDC </strong>
                                <span>{getInfoByStage(data).text}</span>
                            </div>
                          </div>
                      }
                    </>
                  }
                </div>
              </Link>
            ))
          }
      </div>
  )
}

export default CardSingle