import React from "react";
import "../css/_section-3.scss";
import { Link } from "react-router-dom";
import { ROUTES } from "../../config/Routes";

export const Section7 = () => {
  return (
    <div className="row section section-3">
      <div className="d-flex flex-nowrap sub-section">
        {/*
        <div className="order-xl-1 order-md-1 order-sm-1 media">
          <div className="media-container">
            <div className="section-3-img-2"></div>
          </div>
        </div>
         <div className="order-xl-2 order-md-2 order-sm-2 spacer"></div>
          <div className="order-xl-3 order-md-3 order-sm-3 content">
        */}
        <div className="order-xl-12 order-md-12 order-sm-12 content-full">
          <div className="text-container text-container-full">
            <h4 className="text-h4">
              The INTELL Token <span className="text-orange">($INTL)</span> is
              the fuel for a thriving{" "}
              <span className="text-orange">AI Ecosystem</span>
            </h4>
            <p className="text-md">
              <span>
                The INTELL Token ($INTL) fuels a revolutionary Artificial
                Intelligence ecosystem designed to facilitate the direct
                connection between creators, businesses, and investors in the
                buying, selling, accessing and utilization of AI Models and
                Large Language Models (LLMs); as well as businesses that want
                to collaborate in the creation of Federated Intelligence – within
                the parameters they establish.
              </span>
            </p>
            <p className="text-md">
              The INTELL Token ($INTL) and the AI powers the AI Ecosystem by:
              <ul className="list">
                <li>
                  Contributing to development of new AI Models and LLMs – that
                  support real work business challenges and needs
                </li>
                <li>
                  Supporting the ability of any data scientist - and third-party
                  model creators – from around the globe, to contribute to the Ecosystem
                </li>
                <li>
                  Helping to reduce barriers to a business’s ability to access the value
                  of the improved Intelligence
                </li>
                <li>
                  Improving the efficiency, time to market and time to value of business
                  utilization of AI
                </li>
                <li>
                  Contributing to the Governance of the DAO; which enables the proliferation
                  of decentralized federating intelligence; facilitating a smarter
                  ecosystem overall
                </li>
              </ul>
            </p>
            <p className="text-md">
              Investments in the INTELL Token will also be used to fund the promotion, selling
              and use of the models to the companies and businesses that can benefit.  As well
              as support the operational and governance Sales efforts pay off by generating
              revenue that is shared with the entire ecosystem, including the INTELL Token
              holders and NFT shareholders.
            </p>
          </div>
          <div className="buttons-container">
            <Link to={ROUTES.BUY} className="button-primary">
              Buy $INTL
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
