import { post } from "../../config/axios";

type UserData = {
    oldCredentials: {[key: string]: string };
    newCredentials: {[key: string]: string };
};

export const changeCredentials = (data: UserData) =>
  post("/api/admin/admin/change", data);

