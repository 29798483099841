import React, {useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAdmin, setToken, setAuthenticated } from "./store/reducers/application";

import { getProfile } from "./services/getProfile";

import Header from '.././adminApp/components/Header';
import SignIn from '.././adminApp/components/SignIn';
import Recovery from '.././adminApp/components/Recovery/index.js';
import CircularProgress from '@mui/material/CircularProgress';

import { RECOVERY_URL, TOKEN_LOCAL_STORAGE_NAME } from "./constants";
import Notifications from '.././adminApp/components/Notifications';

import Router from "./Router";
import { useLocation } from "react-router-dom";

export const AdminApp: React.FC = () => {
  const authenticated = useSelector(({ application }) => application.authenticated);

  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_NAME);

    getProfile(token)
      .then(({ data }) => {
        dispatch(setToken(token));
        dispatch(setAdmin(data.admin));
        dispatch(setAuthenticated(true));
      })
      .catch(() => {
        dispatch(setAuthenticated(false));
      });
  }, []);

  const getCurrentComponent = () => {
    if (location.pathname.indexOf(RECOVERY_URL) === 0) {
      return <Recovery />;
    }

    if (authenticated === null || authenticated === undefined) {
      return <div style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <CircularProgress />
      </div>;
    }

    if (!authenticated) {
      return <SignIn />;
    }

    return <main>
      <Header />
      <Notifications />
      <Router />
    </main>;
  };

  return getCurrentComponent();
};
