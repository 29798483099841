import React from "react";
import styles from "./index.module.scss";

export const NotFound = () => {
  return (
   <div className={styles.root}>
     <div className={styles.title}>404 - Page not found</div>
     <div className={styles.text}>The page you are looking for might have been removed or temporarily unavailable</div>
   </div>
  );
};
