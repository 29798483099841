import Metamask from "./images/logos/Metamask.svg";
import Coinbase from "./images/logos/Coinbase.svg";
import Ledger from "./images/logos/Ledger.svg";
import Wallet from "./images/logos/Wallet.svg";
import BitKeep from "./images/logos/BitKeep.svg";
import Torus from "./images/logos/Torus.svg";

export const POPULAR_WALLETS = [
  {
    id: "metaMask",
    name: "Metamask",
    image: Metamask,
  },
  {
    id: "coinbaseWallet",
    name: "Coinbase wallet",
    image: Coinbase,
  },
];

export const ADDITIONAL_WALLETS = [
  {
    id: "ledger",
    name: "Ledger",
    image: Ledger,
  },
  {
    id: "walletConnect",
    name: "WalletConnect",
    image: Wallet,
  },
  {
    id: "bitKeep",
    name: "BitKeep",
    image: BitKeep,
  },
  {
    id: "torus",
    name: "Torus",
    image: Torus,
  },
];
