import React from "react";
import { Link } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import ModalVideo from "react-modal-video";

import "../css/_section-1.scss";
import { ROUTES } from "../../config/Routes";

/* eslint-disable-next-line */
// @ts-ignore
// import Video from "../../../public/media/video/Video1044.mp4";

import { ReactComponent as LogoSVG } from "../assets/images/Intell-token-one-line.svg";

export const Section1 = () => {
  return (
    <div className="row section section-1 col-space-between">
      <div className="col-md-12 col-lg-6 col-space-between pl-30">
        <div className="title">
          Introducing <br />
          the <span className="colored">
          <LogoSVG/>
        </span>
        </div>
        <div className="subtitle">
          The first cryptocurrency that fuels investor-supported proliferation of Artificial Intelligence enabling businesses to tackle their biggest
          challenges. Today.
        </div>
        <div className="buttons-container">
          <Link to={ROUTES.JOIN} className="button-primary">
            Join the community
          </Link>
          <Link
            to={ROUTES.BUY}
            className=" button-text alter-arrow text-color-4"
          >
            Buy INTELL ($INTL)
          </Link>
        </div>
        <div
          className="video-wrapper md-show"
          dangerouslySetInnerHTML={{
            __html: `<video className="app__backgroundVideo" autoplay loop muted playsinline>
            <source src=${
              process.env.PUBLIC_URL + "/media/video/Video1044.mp4"
            } type="video/mp4" /> Your browser does not support the video tag.
                    </video>`,
          }}
        />

        <div className="interactive-buttons">
          <Link to="/marketplace">
            <div className="interactive-button shop-nft">
              <div className="icon"></div>
              <div className="text">Shop NFT’s</div>
            </div>
          </Link>
          <Link to={ROUTES.TOKENOMICS}>
            <div className="interactive-button tokens">
              <div className="icon"></div>
              <div className="text">Token Allocation</div>
            </div>
          </Link>
        </div>
      </div>

      <div className="col-md-12 col-lg-6">
        <div
          className="video-wrapper md-hidden"
          dangerouslySetInnerHTML={{
            __html: `<video className="app__backgroundVideo" autoplay loop muted playsinline>
            <source src=${
              process.env.PUBLIC_URL + "/media/video/Video1044.mp4"
            } type="video/mp4" /> Your browser does not support the video tag.
                    </video>`,
          }}
        ></div>
      </div>
    </div>
  );
};
