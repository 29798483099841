import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { SnackbarContent, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Form from '../Form/Form.js';
import getFields from './getFields.js';
import saveItem from '../../services/nft/saveCollectionItem.ts';
import editItem from '../../services/nft/editCollectionItem.ts';

import getItemName from '../../utils/getItemName.js';
import { DEFAULT_LOCALE, LOCALES } from '../../constants/index.js';
import { Alert } from '@mui/lab';

const useStyles = makeStyles(() => ({
    error: {},
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        padding: '0'
    },
    margin: {
        padding: '0',
        minWidth: 'unset',
        background: 'transparent'
    },
    margin2: {
        transform: 'translateY(calc(-100% - 10px)) !important'
    }
}));

const SubcategoryItemForm = ({ item, onDone, categories, activeSubcategory, activeCategory, setFieldsChanged }) => {
    const formRef = useRef();
    const [errorText, setErrorText] = useState();
    const classes = useStyles();
    const isNewItem = !!item._id;
    const [category, setCategory] = useState(activeCategory
        ? (categories.find((category) => category._id === activeCategory._id))
        : (!!item.data && categories.find((category) => category._id === item.data[DEFAULT_LOCALE]?.collection)));
    const isExistItem = !!item._id;

    const categoriesOptions = categories.map((category, i) => ({ value: category._id, label: getItemName(category, `Category ${i}`) }));
    const subcategoriesOptions = category
        ? category?.categories?.length
            ? category?.categories?.map((category, i) => ({ value: category._id, label: getItemName(category, `Category ${i}`) }))
            : null
        : undefined;
    const [disabled, setDisabled] = useState(true);
    const newItemData = {};
    activeCategory && LOCALES.forEach(locale => {
        newItemData[locale] = {
            category: activeCategory._id,
            ...activeSubcategory ? { subcategory: activeSubcategory._id } : { subcategory: null }
        };
    });
    const collection = isExistItem && categories.find(collection => collection._id.toString() === item.data[DEFAULT_LOCALE]?.collection);
    const [hiddenCollection, setHiddenCollection] = useState(isExistItem && collection?.data[DEFAULT_LOCALE]?.hidden);
    const [hiddenCategory, setHiddenCategory] = useState(isExistItem && collection?.categories.find(category => category._id.toString() === item.data[DEFAULT_LOCALE]?.category)?.data[DEFAULT_LOCALE]?.hidden);
    const [isComingSoon, setIsComingSoon] = useState(isExistItem && item.data[DEFAULT_LOCALE]?.comingSoon);

    const handleSubmit = values => {
        (isExistItem ? editItem(item._id, values) : saveItem(values))
          .then(() => {
              onDone();
          })
          .catch(error => {
              if (error?.response?.data?.code === 'duplication') {
                  setErrorText('Enter unique alias');
              }
          });
    };

    const handlePickCategory = (id) => {
        setCategory(categories.find(category => category._id === id) || null);
    };

    const handleHideFailMessage = () => {
        setErrorText('');
    };

    const handleChange = (values, changes) => {
        if (disabled && !changes.lang) {
            setFieldsChanged(true);
            setDisabled(false);
        }

        if (changes.category){
            const collection = categories.find(collection => collection._id.toString() === values.collection);
            setHiddenCategory(collection.categories.find(category => category._id.toString() === values.category)?.data[DEFAULT_LOCALE]?.hidden);
        }

        if (changes.collection){
            const collection = categories.find(collection => collection._id.toString() === values.collection);
            setHiddenCollection(collection?.data[DEFAULT_LOCALE]?.hidden);
        }

        if ('comingSoon' in changes){
            setIsComingSoon(values.comingSoon);
        }
    };

    return <div>
        <Form
            ref={formRef}
            data={item?.data || newItemData}
            fields={getFields({
                formTitle: isNewItem ? 'Edit NFT' : 'Add NFT',
                categoriesOptions,
                subcategoriesOptions,
                handlePickCategory,
                disabled,
                categories,
                hiddenCollection,
                hiddenCategory,
                isComingSoon
            })}
            onSubmit={handleSubmit}
            onChange={handleChange}
            dynamicFieldsChanged={isComingSoon}
        />
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            onClose={handleHideFailMessage}
            open={!!errorText}
            autoHideDuration={null}
        >
            <SnackbarContent
                className={classNames(classes.error, classes.margin, classes.margin2)}
                classes={{ message: classes.message }}
                message={
                    <span className={classes.message}>
                        <Alert onClose={handleHideFailMessage} severity="error">
                            {errorText}
                        </Alert>
                    </span>
                }
            />
        </Snackbar>
    </div>;
};

SubcategoryItemForm.propTypes = {
    item: PropTypes.object,
    categories: PropTypes.array,
    onDone: PropTypes.func.isRequired,
    activeSubcategory: PropTypes.object,
    activeCategory: PropTypes.object,
    setFieldsChanged: PropTypes.func
};

SubcategoryItemForm.defaultProps = {
    categories: {},
    item: {},
    activeSubcategory: {},
    activeCategory: {}
};

export default SubcategoryItemForm;
