import React, { useState, useRef } from 'react';

import routes from '../../constants/routes.js';

import { AppBar, Typography, Toolbar, IconButton, MenuItem, MenuList, Popper, ClickAwayListener, Grow, Paper, Button } from "@mui/material";
import { Menu } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { useDispatch } from 'react-redux';
import { setAuthenticated } from '../../store/reducers/application.js';

import propOr from 'ramda/src/propOr';

import { ADMIN_PANEL_URL, TOKEN_LOCAL_STORAGE_NAME } from "../../constants/index.js";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles({
    title: {
        flexGrow: 1
    },
    popper: {
        zIndex: 100
    },
    button: {
        textAlign: 'center',
        color: 'white'
    }
});

const Header = () => {
    const anchorEl = useRef();
    const dispatch = useDispatch();
    const [menuShowed, setMenuShowed] = useState(false);
    const classes = useStyles();
    const location = useLocation();

    const getHeaderTitle = () => {
        const match = routes.find(route => location.pathname === route.path);

        return propOr('This page does not exist', 'title', match);
    };

    const handleToggle = () => {
        setMenuShowed(menuShowed => !menuShowed);
    };

    const handleClose = event => {
        if (anchorEl.current.contains(event.target)) {
            return;
        }

        setMenuShowed(false);
    };

    const handleLogout = () => {
        localStorage.setItem(TOKEN_LOCAL_STORAGE_NAME, '');
        dispatch(setAuthenticated(false));
    };

    return <AppBar position='static'>
        <Toolbar>
            <IconButton
                color='inherit'
                aria-label='Menu'
                onClick={handleToggle}
                ref={anchorEl}
            >
                <Menu />
            </IconButton>
            <Popper open={menuShowed} anchorEl={anchorEl.current} className={classes.popper} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    {routes.map((route, i) => {
                                        if (route.notMenu) {
                                            return null;
                                        }
                                        return <Link to={route.path} key={i}>
                                            <MenuItem onClick={handleClose}>{route.title}</MenuItem>
                                        </Link>;
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <Typography variant='h6' color='inherit' className={classes.title}>
                {getHeaderTitle()}
            </Typography>
            <Link to={`${ADMIN_PANEL_URL}/credentials`}>
                <Button color='inherit' className={classes.button}>Change Credentials</Button>
            </Link>
            <Button color='inherit' onClick={handleLogout}>Go Out</Button>
        </Toolbar>
    </AppBar>;
};

export default Header;
