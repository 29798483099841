import React, { ReactComponentElement } from "react";
import styles from "./index.module.scss";
import { Tooltip } from "@mui/material";

export interface ITooltip {
  children: ReactComponentElement<any>;
  title: string
}

export const TooltipComponent = ({ children, title }: ITooltip) => {
  return (
    <Tooltip
      placement='top'
      arrow
      classes={{ tooltip: styles.tooltipGrey, arrow: styles.arrowGrey, popper: styles.popper }}
      title={<React.Fragment>{title}</React.Fragment>}
    >
      {children}
    </Tooltip>
  );
};
