import { get } from "../../../config/axios";

type DATA = {
  page: number,
  size: number,
  sort: string,
  categoryId: string,
  subcategoryId: string,
  search: string,
  dateStart: number | string,
  dateEnd: number | string,
  showHidden: boolean
}

export default ({ page, size, sort = 'desk', categoryId, subcategoryId, search, dateStart, dateEnd, showHidden = true }: DATA) =>
  get(`/api/admin/nft/paginated?page=${page}&size=${size}&sort=${sort}&categoryId=${categoryId || ''}&subcategoryId=${subcategoryId || ''}&search=${search || ''}&dateStart=${dateStart || ''}&dateEnd=${dateEnd || ''}&showHidden=${showHidden}`, {});

