import { useLocation, Link, useMatch } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./_navbar.scss";

import { ROUTES } from "../../config/Routes";
import { ReactComponent as Logo } from "../../icons/assets/logo-big.svg";
import { ReactComponent as WhitePaperIco } from "./assets/menu-icons/whitepaper.svg";
import { ReactComponent as WhitePaperIco2 } from "./assets/menu-icons/whitepaper2.svg";
import { ReactComponent as RoadMapIco } from "./assets/menu-icons/roadmap.svg";
import { ReactComponent as FirstMintIco } from "./assets/menu-icons/first-mint.svg";
import { ReactComponent as TokenomicsIco } from "./assets/menu-icons/tokenomics.svg";
import { ReactComponent as AIIco } from "./assets/menu-icons/ai.svg";
import { ReactComponent as CartIco } from "./assets/cart.svg";
import { ReactComponent as WalletIco } from "./assets/wallet.svg";
import { ReactComponent as UserIco } from "./assets/user.svg";
import { Button} from 'antd';

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import HeaderSearchInput from "../HeaderSearchInput/HeaderSearchInput";
import { useDispatch, useSelector } from "react-redux";
import {
  setCartOpen,
  setConnectWalletOpen,
} from "../../store/reducers/application";
import { useAccount, useBalance, useDisconnect } from "wagmi";
import { Popover } from "@headlessui/react";

enum NavItemTypes {
  dropdown,
  link,
  btnlink,
  cart,
}

interface INavDropdownContainer {
  title: string;
  links: INavDropdownItem[];
  handleClose?: any;
}

interface INavDropdownItem {
  // eslint-disable-next-line
  Icon?: any;
  title: string;
  description: string;
  route?: string | "";
  source?: string | "";
  handleClose?: any;
}

interface INavLink {
  className?: string;
  title: string;
  route: string | "";
  handleClose?: any;
}

interface INavItem {
  type: NavItemTypes;
  // eslint-disable-next-line
  item: INavLink | INavDropdownContainer | any | { isHidden: boolean };
  excludeRoutes?: string[];
  isHidden?: boolean;
  handleClose?: any;
}

const CollapseStructure: INavItem[] = [
  {
    type: NavItemTypes.dropdown,
    item: {
      title: "Docs",
      links: [
        {
          Icon: WhitePaperIco,
          title: "Whitepaper",
          description: "",
          route: "",
          source:
            process.env.PUBLIC_URL +
            "/media/docs/AI creativity White paper.pdf",
        },
        {
          Icon: WhitePaperIco2,
          title: "Library",
          description: "",
          route: ROUTES.DOCS,
        },
      ],
    },
  },
  {
    type: NavItemTypes.dropdown,
    item: {
      title: "Tokenomics",
      links: [
        {
          Icon: TokenomicsIco,
          title: "Tokenomics",
          description: "",
          route: ROUTES.TOKENOMICS,
        },
        {
          Icon: FirstMintIco,
          title: "NFT Revenue Protocol",
          description: "",
          route: "",
        },
      ],
    },
  },
  {
    type: NavItemTypes.dropdown,
    item: {
      title: "Model NFT marketplace",
      links: [
        {
          Icon: AIIco,
          title: "Food and Beverage",
          description: "",
          route: "/#catalog-section",
        },
      ],
    },
  },
  {
    type: NavItemTypes.link,
    item: {
      title: "About TIEx",
      route: "/#about",
    },
  },
  {
    type: NavItemTypes.cart,
    item: {},
  },
  {
    type: NavItemTypes.btnlink,
    item: {
      title: "Join our community",
      route: "/join",
    },
  },
];

const MenuButtons: INavItem[] = [
  {
    type: NavItemTypes.cart,
    item: {},
  },
  {
    type: NavItemTypes.btnlink,
    item: {
      title: "Join the community",
      route: "/join",
    },
    excludeRoutes: ["/join", "/buy-intl"],
  },
];
export const GlobalNavbar = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isProductPage = useMatch("/products/:alias");
  const isMarketplacePage = useMatch("/marketplace");
  const cartItems = useSelector(({ application }) => application.cartItems);
  const [expanded, setExpanded] = useState<boolean>(false);
  const ref = useRef<any>();

  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const { data, isLoading } = useBalance({ address });

  const [open, setOpen] = useState(false);

  const handleClick = (e: any) => {
    if (
      (ref.current && ref.current.contains(e.target)) ||
      e.target.classList.contains("navbar-toggler-icon")
    ) {
      return;
    } else {
      setExpanded(false);
    }
  };

  const handleClose = () => {
    setExpanded(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, false);

    return () => {
      document.removeEventListener("click", handleClick, false);
    };
  }, []);

  const handleOpenCart = () => {
    dispatch(setCartOpen(true));
  };

  const handleOpenWallet = () => {
    dispatch(setConnectWalletOpen(true));
  };

  const handlePresentDisconnectModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDisconnect = () => {
    disconnect();
    setOpen(false);
  };

  return (
    <Navbar
      bg="light"
      expand="lg"
      className="global-nav"
      expanded={expanded}
      collapseOnSelect={true}
    >
      <Navbar.Brand>
        <Link to="/">
          <Logo />
        </Link>
      </Navbar.Brand>
      <Navbar.Collapse
        id="basic-navbar-nav"
        className={classNames({
          ["productPage"]: isProductPage,
          ["marketplacePage"]: isMarketplacePage,
        })}
      >
        <Nav className="me-auto" ref={ref}>
          <Navbar.Toggle
            className="close"
            content="+"
            onClick={() => setExpanded(!expanded)}
          />
          {CollapseStructure.map((value, index) => (
            <RenderNavItem
              key={index}
              type={value.type}
              item={value.item}
              handleClose={handleClose}
            />
          ))}
        </Nav>
      </Navbar.Collapse>
      {isProductPage && (
        <React.Fragment>
          <div className="tools-wrapper">
            <HeaderSearchInput />
            <div className="cart-button" onClick={handleOpenCart}>
              <CartIco />
              <div className="cart-button-counter">{cartItems.length}</div>
            </div>
            {isConnected ? (
              <Popover className="relative">
                <Popover.Button className="user-button">
                  <UserIco />
                </Popover.Button>
                <Popover.Panel className="absolute z-10">
                  <div className="bg-white rounded-md shadow-md p-2 mt-3 w-52">
                    {/* User details */}
                    <button className="flex hover:bg-gray-200 transition-all ease-in flex-row items-center justify-start w-full p-2 rounded-md">
                      <img
                        className="w-8 h-8 flex rounded-2xl mr-2.5"
                        src={`https://effigy.im/a/${address}.png`}
                      />
                      <p className="flex text-[17px] font-bold">
                        {address?.slice(0, 6) + "..." + address?.slice(-4)}
                      </p>
                    </button>
                    <div className="mt-2">
                      <p className="text-black text-sm font-normal">Balance</p>
                      {isLoading ? (
                        <p>0 ETH</p>
                      ) : (
                        <p className="text-black text-2xl font-bold tracking-tight mt-0">
                          {data?.formatted} {data?.symbol}
                        </p>
                      )}
                    </div>
                    <button
                      onClick={handlePresentDisconnectModal}
                      className="mt-2 flex hover:bg-red-300 justify-center items-center bg-red-500 transition-all ease-in flex-row w-full p-2 rounded-md"
                    >
                      <p className="flex text-base text-center font-semibold text-white">
                        Log out
                      </p>
                    </button>
                    <Dialog
                      open={open}
                      onClose={handleCloseModal}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle
                        id="alert-dialog-title"
                        className="text-lg font-bold"
                      >
                        {"Are you sure you want to log out?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          You will need to connect your wallet again to
                          continue.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button  type="primary"  onClick={handleCloseModal}>Cancel</Button>
                        <Button
                          type="primary" 
                          color="error"
                          onClick={handleDisconnect}
                          autoFocus
                        >
                          Log out
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </Popover.Panel>
              </Popover>
            ) : (
              <div className="wallet-button" onClick={handleOpenWallet}>
                <WalletIco />
              </div>
            )}
          </div>
        </React.Fragment>
      )}
      {isMarketplacePage && (
        <div
          className="cart-button cart-button-marketplace"
          onClick={handleOpenCart}
        >
          <CartIco />
          <div className="cart-button-counter">{cartItems.length}</div>
        </div>
      )}
      <div
        className={classNames("md-hidden", {
          ["isProductPageJoin"]: isProductPage,
        })}
      >
        {MenuButtons.map((value, index) => {
          const isHidden = value?.excludeRoutes?.includes(pathname);

          return (
            <RenderMenuItem
              key={index}
              type={value.type}
              item={value.item}
              isHidden={isHidden}
              handleClose={handleClose}
            />
          );
        })}
      </div>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded(!expanded)}
      />
    </Navbar>
  );
};

const RenderNavItem = ({ type, item, handleClose }: INavItem) => {
  switch (type) {
    case NavItemTypes.dropdown:
      return (
        <NavDropdownContainer
          title={item.title}
          links={"links" in item ? item?.links : []}
          handleClose={handleClose}
        />
      );
    case NavItemTypes.link:
      return (
        <NavLink
          title={item.title}
          route={"route" in item ? item?.route : ""}
          handleClose={handleClose}
        />
      );
    case NavItemTypes.btnlink:
      return (
        <BtnNavLink
          className="sm-show"
          title={item.title}
          route={"route" in item ? item?.route : ""}
          handleClose={handleClose}
        />
      );
    case NavItemTypes.cart:
      return <></>;
    default:
      return <></>;
  }
};
const RenderMenuItem = ({ type, item, isHidden, handleClose }: INavItem) => {
  switch (type) {
    case NavItemTypes.btnlink:
      return (
        <BtnNavLink
          className={classNames("md-hidden", {
            hidden: isHidden,
          })}
          title={item.title}
          route={"route" in item ? item?.route : ""}
          handleClose={handleClose}
        />
      );
    case NavItemTypes.cart:
      return <></>;
    default:
      return <></>;
  }
};

const NavDropdownContainer = ({
  title,
  links,
  handleClose,
}: INavDropdownContainer) => {
  return (
    <NavDropdown title={title}>
      <div className="navbar-subtitle">{title}</div>
      {links.map((value, index) => (
        <NavDropdownItem
          key={index}
          route={value.route}
          source={value.source}
          title={value.title}
          description={value.description}
          Icon={value.Icon}
          handleClose={handleClose}
        />
      ))}
    </NavDropdown>
  );
};
const NavDropdownItem = ({
  Icon,
  title,
  description,
  route = "",
  source = "",
  handleClose,
}: INavDropdownItem) => {
  const content = (
    <React.Fragment>
      <div className="menu-link-logo">
        <Icon />
      </div>
      <div className="menu-text">
        <p className="menu-link-title">{title}</p>
        {description && <p className="menu-link-description">{description}</p>}
      </div>
      <div className="pointer"></div>
    </React.Fragment>
  );

  if (route) {
    return (
      <NavDropdown.Item
        as={Link}
        to={route}
        className="menu-link"
        onClick={handleClose}
      >
        {content}
      </NavDropdown.Item>
    );
  } else {
    return (
      <NavDropdown.Item
        href={source}
        className="menu-link"
        target="_blank"
        onClick={handleClose}
      >
        {content}
      </NavDropdown.Item>
    );
  }
};

const NavLink = ({ title, route = "", handleClose }: INavLink) => {
  return (
    <div className="nav-item info-link">
      <Link to={route} className="dropdown-item" onClick={handleClose}>
        {title}
      </Link>
    </div>
  );
};

const BtnNavLink = ({
  className = "",
  title,
  route = "",
  handleClose,
}: INavLink) => {
  return (
    <div className={`buttons-container ${className}`}>
      <Link
        to={route}
        className="button-primary disable-after"
        onClick={handleClose}
      >
        {title}
      </Link>
    </div>
  );
};
