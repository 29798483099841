import React from "react";
import "./_doc-tile.scss";

interface IDocTile {
  type: "whitepaper" | "video" | "article";
  date: string;
  title: string;
  subtitle: string;
  source: string;
}

export const DocTile = ({
  type,
  date = "",
  title = "",
  subtitle = "",
  source,
}: IDocTile) => {
  const getLinkText = (type: string, source: string): JSX.Element | string => {
    switch (type) {
      case "video":
        return "Watch";
      default:
        return (
          <a href={source} target="_blank" rel="noreferrer">
            Read
          </a>
        );
    }
  };

  return (
    <div className="doc-tile">
      <div className="doc-head">
        <div className={`doc-type ${type}`}>{type}</div>
        <div className="doc-date">{date || "5/22/2023"}</div>
      </div>
      <div className="doc-title">{title || "Title of the document"}</div>
      <div className="doc-subtitle">{subtitle || "Author doc"}</div>
      <div className="doc-action">{getLinkText(type, source)}</div>
    </div>
  );
};
