import React from "react";

import { Section1 } from "./sections/Section1";
import { Section2 } from "./sections/Section2";
import { Section3 } from "./sections/Section3";
import { Section7 } from "./sections/Section7";
import { Section4 } from "./sections/Section4";
import { Section5 } from "./sections/Section5";
import { Section8 } from "./sections/Section8";
import { Section6 } from "./sections/Section6";
import { Footer } from "./Footer";
import ScrollToHashElement from "../components/ScrollToHashElement/ScrollToHashElement";

export const LandingPage = () => {
  return (
    <>
      <ScrollToHashElement />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section7 />
      <Section5 />
      <Section8 />
      <Section6 />
      <Footer />
    </>
  );
};
