import axios, { AxiosResponse } from "axios";
import { LOCAL_STORAGE_TOKEN } from "../constants/token";

const BASE_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: BASE_URL,
});

export const request = instance;

export const post = async (
  url: string,
  data: any,
  formData?: boolean,
  token?: string,
): Promise<AxiosResponse<any, any>> => {
  const headers = {
    Authorization: `Bearer ${token || localStorage.getItem(LOCAL_STORAGE_TOKEN)}`,
    ...(formData && { "Content-Type": "multipart/form-data" })
  };

  return request({
    method: "POST",
    headers,
    url,
    data: data
  });
};

export const put = async (
  url: string,
  data: any,
  formData?: boolean,
  token?: string,
): Promise<AxiosResponse<any, any>> => {
  const headers = {
    Authorization: `Bearer ${token || localStorage.getItem(LOCAL_STORAGE_TOKEN)}`,
    ...(formData && { "Content-Type": "multipart/form-data" })
  };

  return request({
    method: "PUT",
    headers,
    url,
    data: data,
  });
};

export const get = async (
  url: string,
  data: any,
  token?: string,
): Promise<AxiosResponse<any, any>> => {
  const headers = {
    Authorization: `Bearer ${token || localStorage.getItem(LOCAL_STORAGE_TOKEN)}`,
  };
  return request({
    method: "GET",
    headers,
    url,
    data: {
      ...data,
    },
  });
};

export const deletes = async (
  url: string,
  data: any,
  token?: string,
): Promise<AxiosResponse<any, any>> => {
  const headers = {
    Authorization: `Bearer ${token || localStorage.getItem(LOCAL_STORAGE_TOKEN)}`,
  };
  return request({
    method: "DELETE",
    headers,
    url,
    data: {
      ...data,
    },
  });
};
