import React, { useState } from 'react';

import classNames from 'classnames';

import { TextField, Button, Typography, SnackbarContent } from '@mui/material';
import { green } from '@mui/material/colors/index.js';
import { makeStyles } from '@mui/styles';

import { recover } from "../../services/recover.ts";

import { ADMIN_PANEL_URL } from "../../constants/index.js";
import { Alert } from '@mui/lab';

const useStyles = makeStyles(() => ({
    buttons: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    successBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '110px'
    },
    success: {
        backgroundColor: green[600]
    },
    error: {
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        padding: '0'
    },
    margin: {
        padding: '0',
        minWidth: 'unset',
        background: 'transparent'
    }
}));

const RecoveryRequestForm = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [result, setResult] = useState(false);

    const handleChange = event => {
        setEmail(event.target.value);
        setError(false);
    };

    const handleSubmit = event => {
        event.preventDefault();

        if (!event) {
            return setError(true);
        }

        recover(email)
            .then(() => setResult(true));
    };

    if (result) {
        return <div className={classes.successBlock}>
            <SnackbarContent
                className={classNames(classes.success, classes.margin)}
                classes={{ message: classes.message }}
                message={
                    <span id='client-snackbar' className={classes.message}>
                        <Alert severity="success">
                            Check your email
                        </Alert>
                    </span>
                }
            />
            <Button variant='contained' color='primary' href={ADMIN_PANEL_URL}>
                Go to Main Page
            </Button>
        </div>;
    }

    return <form onSubmit={handleSubmit}>
        <Typography variant='h5'>Account Recovery</Typography>
        <TextField
            label='Email'
            value={email}
            onChange={handleChange}
            margin='normal'
            variant='outlined'
            fullWidth
            error={error}
        />
        <div className={classes.buttons}>
            <Button variant='contained' color='primary' href={ADMIN_PANEL_URL}>
                Go to Main Page
            </Button>
            <Button variant='contained' color='primary' type='submit'>
                Reestablish
            </Button>
        </div>
    </form>;
};

export default RecoveryRequestForm;
