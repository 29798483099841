import React from "react";
import "../css/_section-3.scss";

export const Section3 = () => {
  return (
    <div className="row section section-3">
      <div className="d-flex flex-nowrap sub-section">
        <div className="order-xl-1 order-md-3 order-sm-3 order-3 content">
          <div className="text-container">
            <h4 className="text-h4">Own <span className="text-orange">Shares of NFT’s </span>of </h4>
            <h4 className="text-h4">
              business-ready AI Models and LLMs
            </h4>
            <p className="text-md">
              Our AI Model ecosystem facilitates the development and training of AI Models and LLMs to enable any sized company to access and implement the model’s intelligence output – quickly and easily.
            </p>
            <p className="text-md">
              <span>For the first time anywhere, we have created NFTs to enable investors to own shares of those AI Models and LLMs.</span> INTELL Token holders can use the $INTL to buy shares of the NFTs. When companies subscribe and use the associated model, registered NFT Owners can earn their share of the revenue generated.
            </p>
            <p className="text-md">
              All transactions are tracked and managed on Blockchain and royalties are periodically available for distribution directly to the NFT Owner’s digital wallet.
            </p>
          </div>
          <div className="buttons-container">
            <a href="#" className="button-primary">
              Model NFTs
            </a>
          </div>
        </div>
        <div className="order-xl-2 order-md-2 order-sm-2 order-2 spacer"></div>
        <div className="order-xl-3 order-md-1 order-sm-1 order-1 media">
          <div className="media-container">
            <div className="section-3-img-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
