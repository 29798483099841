import React from "react";
import "../css/_section-4.scss";
import StarIco from "../assets/images/icons/cards-star-ok.png";
import FoodIco from "../assets/images/icons/cards-food.png";
import ChefIco from "../assets/images/icons/cards-chef.png";
import MarkerIco from "../assets/images/icons/cards-marker.png";
import MenuIco from "../assets/images/icons/cards-menu.png";
import TgIco from "../assets/images/icons/cards-tg.png";
import { ILandingCard, LandingCard } from "../../components/LandingCard";
import { Button } from "../../components/Button";
import { Link } from "react-router-dom";

const catalogItems: ILandingCard[] = [
  {
    icon: StarIco,
    text: "Best Restaurants to Target To Increase Product Sales",
    route: "/marketplace?category=category-1",
  },
  {
    icon: MenuIco,
    text: "Effectiveness of My Marketing Campaigns",
    route: "/marketplace?category=category-2",
  },
  {
    icon: ChefIco,
    text: "Opportunities for Growth",
    route: "/marketplace?category=category-4",
  },
  {
    icon: TgIco,
    text: "Creating Dynamic Sales Strategies - using LLMs",
    route: "/marketplace?category=category-5",
  },
  {
    icon: MarkerIco,
    text: "Hottest Menu Item by Location or Market",
    route: "/marketplace?category=category-3",
  },
  {
    icon: FoodIco,
    text: "Gain Efficiencies in Inventory & Allocation Management",
    route: "/marketplace?category=category-6",
  },
];

export const Section4 = () => {
  return (
    <div id="catalog-section" className="row section section-4">
      <div className="text-container">
        <h2 className="text-h2">The “IntellToken.AI” Model NFT Collections</h2>
        <div className="text-plain-container flex-column">
          <p className="text-md">
            We organize our Model NFTs into Collections for key target industries –
            and verticals within those industries  – that can benefit most from the
            integration of advanced Artificial Intelligence. Future Collections will
            include AI Model NFTs for Hospitality, Adult Beverage and Auto ”After Care”.
          </p>
          <p className="text-md">
            Our first is the <span className="bold">“Food and Beverage Collection”</span>, developed to support
            businesses that manufacturer, sell, market, prepare and/or serve products or menu items.
            <br/>
            Leveraging The Intelligence Exchange’s expertise creating intelligence for
            the Food Service Industry, the AI Model NFTs have been categorized by business need
            and challenges companies face every day.
          </p>
        </div>
      </div>
      <div className="catalog">
        <p className="text-lg bold catalog-header-title">
          Food and Beverage Collection
        </p>
        <p className="text-lg bold catalog-header">
          Current Categories:
        </p>
        <div className="market-card-container">
          {catalogItems.map((value, index) => (
            <LandingCard key={index} {...value} />
          ))}
        </div>
      </div>
      <div className="buttons-container">
        <Link to="/marketplace">
          <Button type="primary" text="NFT Marketplace" />
        </Link>
      </div>
    </div>
  );
};
