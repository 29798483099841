import { Route, Routes } from "react-router-dom";
import routes, { routerType } from "./routes";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import { NotFound } from "../components/NotFound";

const Router = () => {
  // get user data from db
  const pageRoutes = routes.map(
    ({ path, title, element: Element, isPrivate }: routerType) => {
      return (
        <Route
          key={title}
          path={`/${path}`}
          element={
            isPrivate ? (
              <ProtectedRoute user={false}>
                <Element />
              </ProtectedRoute>
            ) : (
              <Element />
            )
          }
        />
      );
    },
  );

  return <Routes>
    {pageRoutes}
    <Route path={'*'} element={<NotFound/>}/>
  </Routes>;
};

export default Router;
