import { post } from "../../config/axios";

type UserData = {
  firstName: string;
  lastName: string;
  email: string;
  isBasedInUS: string;
  haveCryptoWallet: string;
};

export const sendJoinRequest = (data: UserData) =>
  post("/api/client/joinRequest", data);
