import { fieldName as button } from '../Form/fields/FormFieldButton/index.js';
import { fieldName as input } from '../Form/fields/FormFieldInput/index.js';
import { fieldName as langs } from '../Form/fields/FormFieldLangs/index.js';
import { fieldName as title } from '../Form/fields/FormFieldTitle/index.js';
import { fieldName as divider } from '../Form/fields/FormFieldDivider/index.js';
import { fieldName as checkbox } from "../Form/fields/FormFieldCheckbox/index.js";

export default function ({ formTitle, disabled, hiddenCollection }) {
    return [
        {
            field: title,
            name: 'titleField',
            settings: {
                label: formTitle
            }
        },
        {
            field: langs,
            name: 'lang'
        },
        {
            field: divider,
            name: 'divider'
        },
        {
            field: input,
            name: 'name',
            settings: {
                label: 'Category name',
                maxLength: 50
            },
            validators: [
                { name: 'required', options: { text: 'Name is required' } }
            ]
        },
        {
            field: title,
            name: 'titleField',
            settings: {
                label: 'Category settings',
                variant: 'h6'
            }
        },
        ...(hiddenCollection ? [{
            field: title,
            name: 'titleField',
            settings: {
                label: 'Visibility of selected collection  is set to hidden',
                variant: 'body1'
            }
        },] : []),
        {
            field: checkbox,
            name: 'hidden',
            settings: {
                label: 'Hidden',
                disabled: hiddenCollection
            },
            langInsensitive: true
        },
        {
            field: divider,
            name: 'divider'
        },
        {
            field: button,
            name: 'button',
            settings: {
                label: 'Save',
                disabled
            }
        }
    ];
}
