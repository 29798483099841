import React, { useState, useEffect, useMemo } from "react";
import styles from "./index.module.scss";
import { useParams, useLocation } from "react-router-dom";
// IMAGES
import { ReactComponent as VerifiedIcon } from "./images/verified.svg";
import { ReactComponent as ArrowIcon } from "./images/arrow.svg";
import { ReactComponent as LiveIcon } from "./images/live.svg";
import { ReactComponent as UpdateIcon } from "./images/update.svg";
import { ReactComponent as ArrowsIcon } from "./images/arrows.svg";
import { ReactComponent as PlusIcon } from "./images/plus.svg";
import { ReactComponent as ArrowToggleIcon } from "./images/arrowToggle.svg";
import { ReactComponent as DescriptionIcon } from "./images/description.svg";
import { ReactComponent as BusinessIcon } from "./images/business.svg";
import { ReactComponent as TargetIcon } from "./images/target.svg";
import Input from "../../components/Input/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { Share } from "../../components/Share";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import getCollectionsItemById from "../../services/client/getCollectionsItemById";
import { DEFAULT_LOCALE } from "../../adminApp/constants";
import { useDispatch, useSelector } from "react-redux";
import { MarketPlaceCardProps } from "../MarketPlacePage/types";
import { setCartItems, setCartOpen } from "../../store/reducers/application";
import { NotFound } from "../../components/NotFound";

type PRODUCT = {
  _id: string,
  data: {
    en: {
      name: string;
      description: string;
      availableShare: number;
      pricePerShare: number;
      businessNeed: string;
      companyTargets: string;
      image: [{ path: string, pathWebp: string }] | [];
      comingSoon: boolean;
      verified: boolean;
      collection: string;
      category: string;
      alias: string
    }
  }
};

const validationSchema = Yup.object({
  number: Yup.number()
    .required("Value is required")
    .moreThan(0, "Please enter a valid number of shares"),
  price: Yup.number()
    .required("Value is required")
    .moreThan(0, "Please enter a valid number of price"),
});

const ProductPage: React.FC = () => {
  const [productItem, setProductItem] = useState<PRODUCT | null | undefined>(null);
  const [tabs, setTabs] = useState<{[key: string]: boolean}>({
    description: true,
    business: false,
    target: false
  });
  const alias = useParams().alias;
  const location = useLocation();
  const dispatch = useDispatch();
  const cartItems = useSelector(({ application }) => application.cartItems);
  const product = useMemo(() => productItem ? productItem.data[DEFAULT_LOCALE] : null, [productItem])

  useEffect(() => {
    if (alias) {
      getCollectionsItemById(alias).then(({ data }) => {
        if (data) {
          setProductItem(data)
        } else {
          setProductItem(undefined)
        }
      })
    }
    document.body.scrollIntoView();
  }, [alias]);

  const formik = useFormik({
    initialValues: {
      number: 0,
      price: 0
    },
    onSubmit: (values, { resetForm }) => {
      try {
        resetForm();
      } catch (error) {
        console.log(error);
      }
    },
    validateOnChange: false,
    validationSchema,
  });

  const handleInputChange = (name: string, value: string | number) => {
    if (product && name === 'number'){
      const integerValue = Math.round(+value);
      if (+integerValue > +product.availableShare) {
        formik.setFieldValue(name, +product.availableShare);
        formik.setFieldValue('price', (+product.availableShare * +product.pricePerShare));
      } else {
        formik.setFieldValue(name, +integerValue);
        formik.setFieldValue('price', (+integerValue * +product.pricePerShare));
      }
    } else {
      formik.setFieldValue(name, value);
    }

    // eslint-disable-next-line
    // @ts-ignore
    if (formik.errors[name]) {
      formik.setErrors({ ...formik.errors, [name]: false });
    }
  };

  const handleAddToCart = () => {
    if (product && productItem?._id) {
      const isItem = cartItems.find((item: { product: { _id: string } }) => item.product._id === productItem._id);
      const newProducts = isItem
        ? [...cartItems.filter((item: { product: { _id: string } }) => item.product._id !== productItem._id), { product: productItem, amount: +isItem.amount + +formik.values.number, price: +product.pricePerShare }]
        : [...cartItems, { product: productItem, amount: +formik.values.number, price: +product.pricePerShare }]
      dispatch(setCartItems(newProducts));
      dispatch(setCartOpen(true));
    }
  }

  const handleAddNumber = () => {
    if (product && +formik.values.number < +product.availableShare){
      const newValue = +formik.values.number + 1;
      formik.setFieldValue('number', newValue);
      formik.setFieldValue('price', (newValue * +product.pricePerShare));
    }
  };

  const handleTabSwitch = (key: string) => () => {
    setTabs({
      ...tabs,
      [key]: !tabs[key]
    })
  };

  const BREADCRUMBS_ROUTES = [
    {
      title: 'Home',
      url: '/'
    },
    {
      title: 'MarketPlace',
      url: '/marketplace'
    },
    {
      title: product?.name || '',
      url: location.pathname
    }
  ];

  if (productItem === undefined) {
    return <NotFound/>
  }

  return (product ?
    <div className={styles.root}>
      <div className={styles.breadcrumbs}>
        <Breadcrumbs routes={BREADCRUMBS_ROUTES}/>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            {!!product.image.length &&
              <picture>
                <source srcSet={product.image[0].pathWebp} type='image/webp' />
                <img src={product.image[0].path} className={styles.image} alt={product.name}/>
              </picture>
            }
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.buttons}>
            {
              product.verified &&
              <div className={styles.modelVerified}>
                Model verified
                <span><VerifiedIcon/></span>
              </div>
            }
            <div className={styles.modelShare}>
             <Share/>
            </div>
          </div>
          <h1 className={styles.title}>{product.name}</h1>
          <div className={styles.copyright}>
            Copyright owner <span>04TJS85</span>
          </div>
          <div className={styles.imageWrapperMobile}>
            <div className={styles.imageContainer}>
              {!!product.image.length &&
                <picture>
                  <source srcSet={product.image[0].pathWebp} type='image/webp' />
                  <img src={product.image[0].path} className={styles.image} alt={product.name}/>
                </picture>
              }
            </div>
          </div>
          <div className={styles.availableShares}>
            <span className={styles.sharesIcon}><ArrowIcon/></span>
            Available Shares
            <span className={styles.available}>
              <span>{product.availableShare}/</span>1000
            </span>
          </div>
          <div className={styles.pricePerShare}>
            <div>
              <div className={styles.priceTitle}>Price Per Share</div>
              <div className={styles.priceValue}>{product.pricePerShare} INT</div>
            </div>
            <div>
              <span><UpdateIcon/></span>
              <span><LiveIcon/></span>
            </div>
          </div>
          <form className={styles.inputsContainer} onSubmit={formik.handleSubmit}>
            <div className={styles.inputs}>
              <div className={styles.inputWrapper}>
                <Input
                  onChange={handleInputChange}
                  value={formik.values.number}
                  leftIcon={<div className={styles.inputSharesIcon}>
                    <ArrowIcon/>
                  </div>}
                  placeholder={'Number of shares'}
                  name={'number'}
                  title={'Number of shares'}
                  hint={'Enter the number of shares you need to buy'}
                  type={'number'}
                  error={!!formik.errors.number}
                  errorText={formik.errors.number}
                  max={product.availableShare}
                  min={0}
                  step={1}
                  />
              </div>
              <div className={styles.separator}>
                <ArrowsIcon/>
                <ArrowsIcon/>
                <ArrowsIcon/>
                <ArrowsIcon/>
                <ArrowsIcon/>
              </div>
              <div className={styles.inputWrapper}>
                <Input
                  onChange={handleInputChange}
                  value={formik.values.price}
                  rightIcon={<div className={styles.inputPriceIcon}>
                    INT
                  </div>}
                  placeholder={'Price of shares'}
                  name={'price'}
                  title={'Price of all shares'}
                  type={'number'}
                  backgroundColor={'rgba(217, 217, 217, .3)'}
                  readonly={true}
                />
              </div>
            </div>
            <div className={styles.inputsButton}>
              <button type={'submit'} className={styles.buyNow} onClick={handleAddToCart} disabled={!(+formik.values.number > 0)}>Buy Now</button>
              <div className={classNames(styles.addIcon, {
                [styles.active]: +formik.values.number < +product.availableShare
              })} onClick={handleAddNumber}>
                <PlusIcon/>
              </div>
            </div>
          </form>
          <div className={classNames(styles.tab, {
            [styles.open]: tabs.description
          })}>
            <div className={styles.tabHeader} onClick={handleTabSwitch('description')}>
              <span><DescriptionIcon/></span>
              Description
              <span><ArrowToggleIcon/></span>
            </div>
            <div className={styles.tabContent}>
              {product.description}
            </div>
          </div>
          <div className={classNames(styles.tab, {
            [styles.open]: tabs.business
          })}>
            <div className={styles.tabHeader} onClick={handleTabSwitch('business')}>
              <span><BusinessIcon/></span>
              Business Need
              <span><ArrowToggleIcon/></span>
            </div>
            <div className={styles.tabContent}>
              {product.businessNeed}
            </div>
          </div>
          <div className={classNames(styles.tab, {
            [styles.open]: tabs.target
          })}>
            <div className={styles.tabHeader} onClick={handleTabSwitch('target')}>
              <span><TargetIcon/></span>
              Company Targets
              <span><ArrowToggleIcon/></span>
            </div>
            <div className={styles.tabContent}>
              {product.companyTargets}
            </div>
          </div>
        </div>
      </div>
    </div>
  : <div/>);
};

export default ProductPage;
