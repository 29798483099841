import React, { useEffect, useRef } from "react";
import styles from "./index.module.scss";
import { CrossSVG } from "../../icons";
import classNames from "classnames";
import { clearBodyLocks, lock } from 'tua-body-scroll-lock';

const Popup: React.FC<{
  children: React.ReactNode;
  onClose: () => void;
  theme?: string;
  open?: boolean;
  refOuter?: any;
  refInner?: any;
}> = ({ children, onClose, theme, open, refOuter, refInner }) => {

  useEffect(() => {
    if (open && refOuter?.current) {
      lock([refOuter.current, ...(refInner?.current ? [refInner.current] : [])]);
    }
  }, [open, refOuter, refInner]);

  const handleClose = () => {
    clearBodyLocks();
    onClose();
  }

  return (
    <div className={classNames(styles.root, {
      [styles[`${theme}`]]: theme
    })}>
      <div className={styles.bg} onClick={handleClose} ></div>
      <button onClick={handleClose} className={styles.close}>
        <CrossSVG />
      </button>
      {children}
    </div>
  );
};

export default Popup;
