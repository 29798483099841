import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import Popup from "../Popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import { setConnectWalletOpen } from "../../store/reducers/application";
import { CrossSVG, ArrowSVG } from "../../icons";
import classNames from "classnames";
import { POPULAR_WALLETS, ADDITIONAL_WALLETS } from "./wallets";
import { clearBodyLocks } from "tua-body-scroll-lock";
import { useAccount, useConnect } from "wagmi";

const ConnectWallet = () => {
  const dispatch = useDispatch();
  const connectWalletOpen = useSelector(
    ({ application }) => application.connectWalletOpen,
  );
  const [showMore, setShowMore] = useState(false);
  const ref = useRef<any>();

  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const { isConnected } = useAccount();

  useEffect(() => {
    if (isConnected) {
      handleClosePopup();
    }
  }, [isConnected]);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleClosePopup = () => {
    dispatch(setConnectWalletOpen(false));
    clearBodyLocks();
    setShowMore(false);
  };

  return connectWalletOpen ? (
    <div className={styles.root} ref={ref}>
      <Popup
        onClose={handleClosePopup}
        theme={"cartPopup"}
        open={connectWalletOpen}
        refOuter={ref}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>Connect your wallet</div>
            <button onClick={handleClosePopup} className={styles.close}>
              <CrossSVG />
            </button>
            <div className={styles.headerInfo}>
              If you don&apos;t have a wallet you can select a provider and
              create one now. <span>Learn more</span>
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.items}>
              <div className={styles.popular}>
                <div className={styles.sectionTitle}>Popular</div>
                <div className={styles.walletsPopular}>
                  {POPULAR_WALLETS.map((wallet, i) => {
                    const connector = connectors?.find(
                      (c) => c.id === wallet.id,
                    );
                    const isWalletEnabled = connector && connector.ready;
                    const isWalletLoading =
                      isLoading && connector?.id === pendingConnector?.id;
                    return (
                      <div
                        onClick={() => connect({ connector })}
                        className={classNames(styles.popularItem, {
                          [styles.disabled]: !isWalletEnabled,
                          [styles.loading]: isWalletLoading,
                        })}
                        key={i}
                      >
                        <div className={styles.popularImage}>
                          <img src={wallet.image} alt={wallet.name} />
                        </div>
                        <div className={styles.popularName}>{wallet.name}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {showMore && (
                <div className={styles.more}>
                  <div className={styles.sectionTitle}>More</div>
                  <div className={styles.walletsMore}>
                    {ADDITIONAL_WALLETS.map((wallet, i) => {
                      const connector = connectors?.find(
                        (c) => c.id === wallet.id,
                      );
                      const isWalletEnabled = connector && connector.ready;
                      const isWalletLoading =
                        isLoading && connector?.id === pendingConnector?.id;
                      return (
                        <div
                          onClick={() => connect({ connector })}
                          className={classNames(styles.additionalItem, {
                            [styles.disabled]: !isWalletEnabled,
                            [styles.loading]: isWalletLoading,
                          })}
                          key={i}
                        >
                          <div className={styles.additionalImage}>
                            <img src={wallet.image} alt={wallet.name} />
                          </div>
                          <div className={styles.additionalName}>
                            {wallet.name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            <div
              className={classNames(styles.showMore, {
                [styles.active]: showMore,
              })}
              onClick={handleShowMore}
            >
              {showMore ? "Show Less" : "Show More"}
              <span>
                <ArrowSVG />
              </span>
            </div>
            {error && error.message && (
              <div className={styles.error}>
                <p>{`${error.message}`}</p>
              </div>
            )}
          </div>
        </div>
      </Popup>
    </div>
  ) : null;
};

export default ConnectWallet;
