import "../../landing/css/_section-1.scss";
import "./_cards.scss";
import { Link } from "react-router-dom";

export interface ILandingCard {
  icon: string;
  text: string;
  route: string;
}

export const LandingCard = ({ icon, text, route }: ILandingCard) => {
  return (
    <Link to={route} className="text-md bold market-card">
      <div className="icon">
        <img src={icon} alt="" />
        <div>
          <span className="market-card-text">{text}</span>
        </div>
      </div>
    </Link>
  );
};
