import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import uploadFile from '../../../../services/uploadFile.ts';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { Typography, IconButton, Button } from '@mui/material';
import { Delete, CloudUpload } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import arrayMove from '../../utils/arrayMove.js';
import uniqid from 'uniqid';

import remove from 'ramda/src/remove';
import map from 'ramda/src/map';
import includes from 'ramda/src/includes';
import last from 'ramda/src/last';

import checkSettings from '../../utils/checkSettings.js';

const useStyles = makeStyles(() => ({
    uploadInput: {
        display: 'none'
    },
    upload: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '400px',
        justifyContent: 'space-between',
    },
    title: {
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        color: '#868686'
    },
    button: {
        borderRadius: '0px',
        padding: '8px 26px',
        border: '1px solid #B9A659',
        backgroundColor: 'transparent',

        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    uploadIcon: {
        width: '30px',
        height: '19px',
        color: 'white'
    },
    filesList: {
        overflow: 'auto'
    },
    fileItem: {
        position: 'relative',
        userSelect: 'none',
        padding: '16px',
        width: '200px',
        float: 'left',
        zIndex: '100',
        cursor: 'grab',
        '&:hover $fileItemDeleteContainer': {
            visibility: 'visible'
        }
    },
    fileItemSorting: {
        '&:hover $fileItemDeleteContainer': {
            visibility: 'hidden'
        }
    },
    fileImage: {
        width: '100%'
    },
    fileImageError: {
        outline: 'solid 4px #f44336'
    },
    fileItemDeleteContainer: {
        position: 'absolute',
        right: '0',
        top: '0',
        visibility: 'hidden',
        background: 'white',
        borderRadius: '100%',
        zIndex: '1'
    },
    warning: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '20px'
    },
    warningIcon: {
        color: '#ffae42',
        marginRight: '10px'
    },
    errorIcon: {
        color: '#f44336',
        marginRight: '10px'
    },
    warningText: {
        fontSize: '16px'
    },
    divider: {
    },
    sizeError: {
        color: 'red',
        marginTop: '10px'
    }
}));

const imagesFileExt = ['png', 'jpg', 'jpeg', 'gif', 'svg'];

const Image = SortableHandle(({ imageClassName, src }) => (
    includes(last(src.split('.')), imagesFileExt) ? <img className={imageClassName} src={src} /> : <Typography>{last(src.split('/'))}</Typography>
));

const FilePreview = SortableElement(({ file, i, classes, onFileDelete, isSorting }) =>
    <div className={classNames(classes.fileItem, {
        [classes.fileItemSorting]: isSorting
    })}>
        <div className={classes.fileItemDeleteContainer}>
            <IconButton
                aria-label='Delete'
                onClick={onFileDelete(i)}
            >
                <Delete />
            </IconButton>
        </div>
        <Image src={file.path} imageClassName={classes.fileImage} />
    </div>);

const FilesPreviews = SortableContainer(({ files, classes, ...rest }) => {
    return (
        <div className={classes.filesList}>
            {files.map((file, i) => <FilePreview
                key={i}
                index={i}
                i={i}
                file={file}
                classes={classes}
                {...rest}
            />)}
        </div>
    );
});

const SETTINGS_MODEL = {
    webp: ['required', 'boolean'], // should the backend prepare a webp version of the file
    max: ['number'], // maximum number of files
    accept: ['string'], // "accept" attribute of the file input
    type: ['string'],
    filename: ['string'],
    maxSize: ['number']
};

const FormFieldFiles = ({ value, settings, onChange, name }) => {
    const [sizeError, setSizeError] = useState(false);

    useEffect(() => {
        checkSettings('Files', settings, SETTINGS_MODEL);
    }, []);

    const [isSorting, setSorting] = useState(false);

    const classes = useStyles();

    const handleFilesUpload = event => {
        const newFiles = map(file => (file), event.target.files);
        let files = [...value, ...newFiles];

        event.target.value = '';

        if (settings.max && files.length > settings.max) {
            files = files.slice(files.length - settings.max);
        }

        if (settings.maxSize) {
            const size = files.reduce((acc, item) => acc + item.size, 0);

            if (size > settings.maxSize){
                setSizeError(true)
                return;
            }
        }

        if (sizeError) {
            setSizeError(false);
        }

        Promise.all(
            map(file => {
                return new Promise((resolve) => {
                    if (file.path) {
                        return resolve(file);
                    }
                    const formData = new FormData();

                    formData.append(file.name, file);

                    return uploadFile(formData, settings.webp)
                        .then(({ data }) => {
                            resolve({
                                path: data.path,
                                ...(data.pathWebp ? { pathWebp: data.pathWebp } : {}),
                                id: uniqid()
                            });
                        });
                });
            }, files)
        )
            .then(filesArr => {
                onChange(filesArr);
            });
    };

    const onDragStart = () => {
        setSorting(true);
    };

    const onDragEnd = ({ oldIndex, newIndex }) => {
        onChange(arrayMove(value, oldIndex, newIndex));

        setSorting(false);
    };

    const handleFileDelete = i => () => {
        onChange(remove(i, 1, value));
    };

    const inputId = useMemo(() => `${name}-${+Date.now()}`, [name]);

    return <div>
        <div className={classes.upload}>
            <input
                className={classes.uploadInput}
                id={inputId}
                type='file'
                accept={settings.accept || 'image/*'}
                onChange={handleFilesUpload}
                multiple
            />
            <label htmlFor={inputId}>
                <Button variant='contained' component='span' color='primary'>
                    Upload
                    <CloudUpload className={classes.uploadIcon} />
                </Button>
            </label>
        </div>
        <FilesPreviews
            axis='xy'
            classes={classes}
            files={value}
            onFileDelete={handleFileDelete}
            onSortStart={onDragStart}
            onSortEnd={onDragEnd}
            isSorting={isSorting}
            useDragHandle
        />
        {
            sizeError &&
          <div className={classes.sizeError}>The uploaded file(s) exceeds the maximum size of {(settings.maxSize/1024/1024).toFixed(0)}MB</div>
        }
    </div>;
};

FormFieldFiles.propTypes = {
    value: PropTypes.array,
    settings: PropTypes.object,
    onChange: PropTypes.func,
    name: PropTypes.string
};

FormFieldFiles.defaultProps = {
    value: [],
    settings: {},
    name: ''
};

export const fieldName = 'files';
export const getInitialValues = (data, field, langs) => langs.reduce((result, lang) => {
    if (field.langInsensitive) {
        result[field.name] = (data[lang][field.name] || []).map(file => {
            return {
                path: file.path,
                pathWebp: file.pathWebp,
                id: file.id
            };
        });
    } else {
        result[`${lang}_${field.name}`] = (data[lang][field.name] || []).map(file => {
            return {
                path: file.path,
                pathWebp: file.pathWebp,
                id: file.id
            };
        });
    }
    return result;
}, {});
export const getPayload = (values, field, lang) => field.langInsensitive
    ? values[field.name]
    : values[`${lang}_${field.name}`]
        .map(file => {
            return {
                path: file.path,
                pathWebp: file.pathWebp,
                id: file.id
            };
        });
export const Component = FormFieldFiles;

export default {
    Component,
    fieldName,
    getInitialValues,
    getPayload
};
