import React from "react";
import "./css/_tokenomics_roadmap.scss";

export const Roadmap = () => {
  return (
    <div className="row">
      <div className=" col-xs-12 col-sm-10 col-md-10 col-lg-7 container">
        <div className="info-alocation">
          <h3 className="text-h3">Roadmap of Our Key Milestones</h3>
          <h6 className="text-h6 text-primary-blue roadmap-title">
            INTELL Token
          </h6>

          <div className="roadmap-container">
            <RoadmapItem title="Select Blockchain" status="completed" />

            <RoadmapItem
              title="Token Generation Event"
              status="October/November 2023"
            />

            <RoadmapItem
              title="Select DeFi and DEX’s"
              status="October/November 2023"
            />

            <RoadmapItem title="Publish Tokenomics" status="completed" />

            <RoadmapItem
              title="Announce INTELL Token Sale Start Date"
              status="October 2023"
            />

            <RoadmapItem
              title="INTELL Community registration opens"
              status="completed"
            />

            <RoadmapItem
              title="Select Cryptocurrency Exchange(s)"
              status="October 2023"
            />

            <RoadmapItem
              title="INTELL Token First Issuance - Public Sale"
              status="tba"
            />

            <RoadmapItem
              title="Weekly Email Updates to INTELL Token Holder and INTELL
              Community Members Begins"
              status="October 2023"
            />
          </div>

          <h6 className="text-h6 text-primary-blue roadmap-title">
            NFT Marketplace (AI Models and LLMs)
          </h6>
          <div className="roadmap-container">
            <RoadmapItem
              title="First Set of AI Model NFTs Minted (Food and Beverage Collection) previewed in Marketplace"
              status="October 2023"
            />
            <RoadmapItem
              title="First Set of AI Model NFTs available for purchase to INTELL Token holders"
              status="November 2023"
            />
            <RoadmapItem title="Additional Food and Beverage Model NFTs available" status="Ongoing (Periodic)" />
            <RoadmapItem
              title="AI Models for Adult Beverage Industry listed in Marketplace"
              status="tba"
            />
            <RoadmapItem
              title="AI Models for Hospitality Industry listed in Marketplace"
              status="tba"
            />
            <RoadmapItem
              title="Share of allocated revenue available for NFT Share owners"
              status="tba"
            />
          </div>

          <h6 className="text-h6 text-primary-blue roadmap-title">
            Model Creator Expansion
          </h6>
          <div className="roadmap-container">
            <RoadmapItem
              title="External Data Scientists and Creators can submit Models"
              status="tba"
            />
            <RoadmapItem
              title="Request for new Models (RFM) functionality goes live"
              status="tba"
            />
          </div>

          <h6 className="text-h6 text-primary-blue roadmap-title">
            Decentralized Autonomous Organization (DAO) Milestones
          </h6>
          <div className="roadmap-container">
            <RoadmapItem
              title="Development of DAO infrastructure completed and tested - phase 1"
              status="tba"
            />
            <RoadmapItem
              title="Development of DAO Member Tools compelted"
              status="tba"
            />
            <RoadmapItem title="Food Serivce DAO Launches" status="tba" />
            <RoadmapItem
              title="Food Servcie DAO Member Platform Launches"
              status="tba"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface IRoadmapItem {
  title: string;
  status: "completed" | "tba" | string;
}

const RoadmapItem = ({ title, status }: IRoadmapItem) => {
  const renderStatus = (status: string) => {
    switch (status) {
      case "completed":
        return <div className="status completed">Completed</div>;
      case "tba":
        return <div className="status tba">TBA</div>;
      default:
        return <div className="status in-progress">{status}</div>;
    }
  };

  return (
    <div className="roadmap-item">
      <p>{title}</p>
      {renderStatus(status)}
    </div>
  );
};
