import React, { useState } from 'react';

import classNames from 'classnames';

import { TextField, Button, Snackbar, SnackbarContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useDispatch } from 'react-redux';
import { signIn } from "../../services/signIn.ts";

import { ADMIN_PANEL_URL, TOKEN_LOCAL_STORAGE_NAME } from "../../constants/index.js";

import { setAdmin, setToken, setAuthenticated } from '../../store/reducers/application.js';

import styles from './styles.module.css';
import { Alert } from '@mui/lab';

const useStyles = makeStyles(() => ({
    error: {
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        padding: '0'
    },
    margin: {
        padding: '0',
        minWidth: 'unset',
        background: 'transparent'
    }
}));

const Authentication = () => {
    const dispatch = useDispatch();
    const [values, setValues] = useState({ login: '', password: '' });
    const [errors, setErrors] = useState({});
    const [authFailed, setAuthFailed] = useState(false);
    const classes = useStyles();

    const handleChange = credential => event => {
        setValues(values => ({ ...values, [credential]: event.target.value }));
        setErrors(errors => ({ ...errors, [credential]: false }));
    };

    const handleHideFailMessage = () => {
        setAuthFailed(false);
    };

    const handleSubmit = event => {
        event.preventDefault();

        const { login, password } = values;
        const credentials = {
            login: login.trim(),
            password: password.trim()
        };

        if (!credentials.login || !credentials.password) {
            return setErrors({
                login: !login,
                password: !password
            });
        }

        signIn(credentials)
            .then(({ data }) => {
                localStorage.setItem(TOKEN_LOCAL_STORAGE_NAME, data.token);
                dispatch(setToken(data.token));
                dispatch(setAdmin(data.admin));
                dispatch(setAuthenticated(true));
            })
            .catch(() => {
                setValues(values => ({ ...values, password: '' }));
                setErrors({});
                setAuthFailed(true);
            });
    };

    return <div className={styles.container}>
        <h1 className={styles.title}>Enter</h1>
        <form className={styles.form} noValidate autoComplete='off' onSubmit={handleSubmit}>
            <TextField
                label={'Login'}
                value={values.login}
                onChange={handleChange('login')}
                margin='normal'
                variant='outlined'
                error={errors.login}
            />
            <TextField
                label={'Password'}
                value={values.password}
                onChange={handleChange('password')}
                margin='normal'
                variant='outlined'
                error={errors.password}
                type='password'
            />
            <div className={styles.button}>
                <Button variant='contained' color='primary' type='submit' fullWidth>
                    Enter
                </Button>
            </div>
            <div className={styles.button}>
                <Button variant='contained' color='primary' fullWidth href={`${ADMIN_PANEL_URL}/recovery`}>
                    Forgot password
                </Button>
            </div>
        </form>
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            onClose={handleHideFailMessage}
            open={authFailed}
            autoHideDuration={null}
        >
            <SnackbarContent
                className={classNames(classes.error, classes.margin)}
                classes={{ message: classes.message }}
                message={
                    <span id='client-snackbar' className={classes.message}>
                        <Alert severity="error">
                            Wrong username or password
                        </Alert>
                    </span>
                }
            />
        </Snackbar>
    </div>;
};

export default Authentication;
