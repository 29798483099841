export enum ROUTES {
  HOME = "/",
  ROADMAP = "/roadmap",
  TOKENOMICS = "/tokenomics",
  DOCS = "/docs",
  MARKETPLACE = "/marketplace",
  PRODUCT = "/products/:alias",
  JOIN = "/join",
  BUY = "/buy-intl",
  ADMIN = "/admin/*",
  DETAIL_FEDERATED = "/detail/federated/:id",
  DETAIL_SINGLE = "/detail/single/:id"
}
