import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { SearchSVG, CrossSVG } from "../../icons";
import { useDebounce } from "../../hooks/useDebounce";
import getSearchedItems from "../../services/client/getSearchedItems";
import { Link, useLocation } from "react-router-dom";
import { MarketPlaceCardProps } from "../../pages/MarketPlacePage/types";
import { DEFAULT_LOCALE } from "../../adminApp/constants";
import classNames from "classnames";

const HeaderSearchInput = () => {
  const [value, setValue] = useState('');
  const [data, setData] = useState([]);
  const location = useLocation();
  const [expanded, setExpanded] = useState<boolean>(true);
  const ref = useRef<any>();

  const handleClick = (e: any) => {
    if (ref.current && ref.current.contains(e.target) || e.target.classList.contains('navbar-toggler-icon')) {
      return;
    } else {
      setExpanded(false);
    }
  }

  const onFocus = () => {
    if (!expanded){
      setExpanded(true);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick, false);

    return () => {
      document.removeEventListener('click', handleClick, false);
    }
  }, []);

  useEffect(() => {
    if (location.pathname !== '/products/:alias' && value) {
      setValue('');
    }
  }, [location.pathname]);

  const onChange = (e: { target: { value: string } }) => {
    setValue(e.target.value);
  }

  const handleChange = (value: string) => {
    if (value) {
      getSearchedItems({
        search: value || ''
      })
        .then(({ data }) => {
          setData(data);
        })
    }
    else {
      setData([]);
    }
  }

  const updateSearchValue = useDebounce(handleChange, 500);

  const handleClear = () => {
    setValue("");
  };

  useEffect(() => {
    updateSearchValue(value)
  }, [value])

  return (
    <div className={styles.root} ref={ref}>
      <div className={styles.iconWrapper}>
        <div className={styles.icon}>
          <SearchSVG />
        </div>
      </div>
      <input
        className={styles.input}
        value={value}
        onChange={onChange}
        placeholder="Search"
        onFocus={onFocus}
      />
      {value && (
        <button onClick={handleClear} className={styles.clear}>
          <CrossSVG />
        </button>
      )}
      {
        !!data.length && <div className={classNames(styles.results, {
          [styles.hidden]: !expanded
        })}>
          {data.map((item: MarketPlaceCardProps) => {
            const { image, name, pricePerShare, comingSoon, alias } = item.data[DEFAULT_LOCALE];
            const resultItem = <div className={styles.resultItemContent} onClick={handleClear}>
              <div className={styles.image}>
                {
                  image?.length ?
                    <div className={styles.imageWrapper}>
                      <picture>
                        <source srcSet={image[0].pathWebp} type='image/webp' />
                        <img src={image[0].path} alt={name} loading="lazy" className={styles.image}/>
                      </picture>
                    </div>
                    : <div className={styles.comingSoonWrapper}>
                      <div className={styles.comingSoonText}>Coming Soon</div>
                    </div>
                }
              </div>
              <div className={styles.name}>{name}</div>
              <div className={styles.price}>{`${pricePerShare} INT`}</div>
            </div>

            return !comingSoon
              ? <Link to={`/products/${alias}`} className={classNames(styles.resultItem, styles.resultItemLink)} key={item._id}>{resultItem}</Link>
              : <div className={styles.resultItem} key={item._id}>{resultItem}</div>;
          })}
        </div>
      }
    </div>
  );
};

export default HeaderSearchInput;
