import React, { useEffect, useState } from "react";
import "./_toggle.scss";

export interface IToggle {
  options: string[];
  onSelect?: (selectedValue: string) => void;
  defaultActive?: string;
}

export const Toggle = ({ options, onSelect, defaultActive }: IToggle) => {
  const [toggleOptions] = useState(options || []);
  const [active, setActive] = useState<string>("");

  useEffect(() => {
    if (defaultActive) {
      setActive(defaultActive || "");
      return;
    }
    if (options.length) {
      setActive(options[0]);
    }
  }, []);

  const onClick = (selectedValue: string) => {
    if (onSelect) {
      onSelect(selectedValue);
      setActive(selectedValue);
    }
  };

  return (
    <div className="toggler">
      {toggleOptions.map((value, index) => (
        <div
          className={`option ${value === active ? "active" : ""}`}
          key={index}
          onClick={() => {
            onClick(value);
          }}
        >
          {value}
        </div>
      ))}
    </div>
  );
};
