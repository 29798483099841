import { post } from "../../config/axios";

type UserData = {
    recovery: {[key: string]: string };
    newCredentials: {[key: string]: string };
};

export const changeRecoveryCredentials = (data: UserData) =>
  post("/api/admin/admin/recover-change", data);

