import React from "react";
import "../css/_section-5.scss";
import { ReactComponent as NFT } from "../assets/images/section-5-nft.svg";

export const Section5 = () => {
  return (
    <div className="row justify-content-between section section-5">
      <div className="col-xl-8 col-lg-8 col-sm-12 col-xs-12">
        <h2 className="text-h4">About the <span className="text-h4-colored">Model NFTs</span></h2>
        <p className="text-md lh-31">
          The AI-MODEL NFTs are the center of the AI Ecosystem. The NFT works like this:
        </p>

        <ul>
          <li className="text-md lh-31">
            The data scientists create an AI Model and/or LLM to produce a unique output, tied to a business problem
          </li>
          <li className="text-md lh-31">
            Model efficacy is validated in a business environment to ensure it addresses the problem
          </li>
          <li className="text-md lh-31">
            The model Intellectual Property (IP) is fingerprinted and assigned to the creator
          </li>
          <li className="text-md lh-31">
            NFT is minted; share allocation is assigned, and placed in the Marketplace
          </li>
          <li className="text-md lh-31">
            INTELL Token holders use their $INTL to purchase shares of an NFT
          </li>
          <li className="text-md lh-31">
            Share ownership is managed via an NFT Smart Contract on block chain
          </li>
          <li className="text-md lh-31">
            When a model is ‘decrypted’ for use by a business, the creator and NFT
            shareholders can earn a portion of the revenue generated
          </li>
        </ul>

        <p className="text-md lh-31">
          NFT shareholders can sell their shares to other investors at any point following the initial grace period.
        </p>
      </div>
      <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12 d-flex justify-content-center">
        <NFT className="big-svg nft-img" />
      </div>
    </div>
  );
};
