import React, { useState, useEffect, useRef } from 'react';

import omit from 'ramda/src/omit';

import { makeStyles } from '@mui/styles';

import AdminTableWithSubcategory from '../../../adminApp/components/AdminTableWithSubcategoryPaginated/index.js';
import SubcategoryItemForm from '../../../adminApp/components/ProductItemForm/index.js';
import CategoryForm from '../../../adminApp/components/CollectionForm/index.js';
import SubcategoryForm from '../../../adminApp/components/CategoryForm/index.js';

import getItems from '../../../adminApp/services/nft/getCollectionsItemsPaginated.ts';
import getCollections from '../../../adminApp/services/nft/getCollections.ts';
import deleteCollectionsItems from '../../../adminApp/services/nft/deleteCollectionsItems.ts';
import deleteCollections from '../../../adminApp/services/nft/deleteCollections.ts';
import sortCollections from '../../../adminApp/services/nft/sortCollections.ts';
import getCategories from '../../../adminApp/services/nft/getCategories.ts';
import deleteCategories from '../../../adminApp/services/nft/deleteCategories.ts';
import sortCategories from '../../../adminApp/services/nft/sortCategories.ts';

import { DEFAULT_LOCALE, LOCALES } from '../../constants/index.js';
import debounce from 'lodash.debounce';
import { Close, Check } from '@mui/icons-material';
import { IconButton, DialogTitle, DialogActions, Button, Dialog, CircularProgress, Modal, Paper } from '@mui/material';

const headerRows = [
    { id: 'image', label: 'Image' },
    { id: 'name', label: 'Name' },
    { id: 'alias', label: 'Alias' },
    { id: 'available', label: 'Available' },
    { id: 'price', label: 'Price' },
    { id: 'verified', label: 'Verified' },
    { id: 'hidden', label: 'Visible' }
];

const useStyles = makeStyles(() => ({
    loader: {
        height: 'calc(100vh - 64px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    closeButton: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        zIndex: '10'
    },
    modalContent: {
        padding: '30px',
        position: 'absolute',
        width: '1200px',
        outline: 'none',
        overflowY: 'auto',
        maxHeight: '100vh',
        '@media (max-width:1300px)': {
            width: '90%'
        }
    },
    warningContent: {
        paddingBottom: '0'
    },
    columnTitle: {
        fontSize: '1.1125rem',
        maxWidth: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '@media (max-width:1366px)': {
            maxWidth: '100px'
        },
        '@media (max-width:1020px)': {
            maxWidth: '100px'
        },
        '@media (max-width:750px)': {
            maxWidth: '100px'
        },
        '@media (max-width:340px)': {
            maxWidth: '100px'
        }
    },
    columnImage: {
        maxWidth: '100px',
        display: 'block',
        '@media (max-width:1020px)': {
            maxWidth: '100px'
        },
        '@media (max-width:750px)': {
            maxWidth: '100px'
        },
        '@media (max-width:340px)': {
            maxWidth: '80px'
        }
    }
}));

const ProductsTable = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [warning, setWarning] = useState(false);
    const [activeCategory, setActiveCategory] = useState(null);
    const [activeSubcategory, setActiveSubcategory] = useState(null);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState('');
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [formOpened, setFormOpened] = useState(false);
    const [categoryFormOpened, setCategoryFormOpened] = useState(false);
    const [subcategoryFormOpened, setSubcategoryFormOpened] = useState(false);
    const [editableItem, setEditableItem] = useState(null);
    const [requestOptions, setRequestOptions] = useState({
        page: 1,
        size: 10,
        sort: 'desc',
        categoryId: null,
        subcategoryId: null,
        search: search,
        dateStart: null,
        dateEnd: null,
        showHidden: true
    });
    const [isFieldsChanged, setFieldsChanged] = useState(false);
    const DAY_MILISECONDS = 24 * 60 * 60 * 1000;

    useEffect(() => {
        handleFetchOptionsDebounced.current(search);
    }, [search]);

    const handleSetSearchQuery = (search) => {
        setRequestOptions({
            ...requestOptions,
            search: search,
            page: 1
        });
    };

    const handleFetchOptionsDebounced = useRef(debounce(handleSetSearchQuery, 200));

    const handleInputChange = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        setRequestOptions({
            ...requestOptions,
            ...(dateStart
                ? { dateStart: new Date(dateStart).getTime() }
                : { dateStart: '' }),
            ...(dateEnd
                // eslint-disable-next-line max-len
                ? { dateEnd: new Date(dateEnd).getTime() + DAY_MILISECONDS } // to include s created during the 24 hours of selected day (timestamp time is 00:00)
                : { dateEnd: '' }),
            ...(status ? { status: status } : { status: '' }),
            page: 1
        });
    }, [dateStart, dateEnd, status]);

    const handleDateStartChange = (event) => {
        setDateStart(event.target.value);
    };

    const handleDateEndChange = (event) => {
        setDateEnd(event.target.value);
    };

    useEffect(() => {
        getItems({ ...requestOptions }).then((items) => {
            setItems(items.data.paginatedResults);
            setCount(items.data.totalCount);
        });
    }, [requestOptions]);

    useEffect(() => {
        Promise.all([
            getCollections()
        ])
            .then(([categories]) => {
                setCategories(categories.data);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (activeCategory) {
            setSubcategories(activeCategory?.categories.map((item) => ({ ...item, collectionHidden: activeCategory.data[DEFAULT_LOCALE].hidden })) || []);
            setRequestOptions({ ...requestOptions, categoryId: activeCategory._id, page: 1 });
        } else {
            setSubcategories([]);
            setActiveSubcategory(null);
            setRequestOptions({ ...requestOptions, categoryId: null, subcategoryId: null, page: 1 });
        }
    }, [activeCategory]);

    useEffect(() => {
        if (activeSubcategory) {
            setRequestOptions({ ...requestOptions, subcategoryId: activeSubcategory._id, page: 1 });
        } else {
            setRequestOptions({ ...requestOptions, categoryId: null, subcategoryId: null, page: 1 });
        }
    }, [activeSubcategory]);

    const handleFormDone = () => {
        Promise.all([
            getCollections(),
            (activeCategory ? getCategories(activeCategory?._id) : () => []),
            getItems({ ...requestOptions }),
        ])
            .then(([categories, subcategories, items]) => {
                setCategories(categories.data);
                setSubcategories(subcategories.data);
                setItems(items.data.paginatedResults);
                setCount(items.data.totalCount);
            })
            .then(() => {
                handleCloseForm();
            });
    };

    const handleItemsDelete = ids => {
        deleteCollectionsItems(ids)
            .then(() => {
                Promise.all([
                    getCollections(),
                    getItems({ ...requestOptions })
                ])
                    .then(([categories, items]) => {
                        setCategories(categories.data);
                        setItems(items.data.paginatedResults);
                        setCount(items.data.totalCount);
                    });
            });
    };

    const handleCategoriesDelete = ids => {
        deleteCollections(ids)
            .then(() => {
                Promise.all([
                    getCollections(),
                    getItems({ ...requestOptions })
                ])
                    .then(([categories, items]) => {
                        setCategories(categories.data);
                        setItems(items.data.paginatedResults);
                        setCount(items.data.totalCount);
                    });
            });
    };

    const handleSubcategoriesDelete = (ids, categoryId) => {
        deleteCategories(ids, categoryId)
            .then(() => {
                Promise.all([
                    getCollections(),
                    getCategories(categoryId),
                    getItems({ ...requestOptions })
                ])
                    .then(([categories, subcategories, items]) => {
                        setCategories(categories.data);
                        setSubcategories(subcategories.data);
                        setItems(items.data.paginatedResults);
                        setCount(items.data.totalCount);
                    });
            });
    };

    const handleCategoriesSort = ids => {
        setCategories(ids.map(id => categories.find(category => category._id === id)));
        sortCollections(ids)
            .then(() => {
                Promise.all([
                    getCollections(),
                    getItems({ ...requestOptions })
                ])
                    .then(([categories, items]) => {
                        setCategories(categories.data);
                        setItems(items.data.paginatedResults);
                        setCount(items.data.totalCount);
                    });
            });
    };

    const handleSubcategoriesSort = (ids, categoryId) => {
        setSubcategories(ids.map(id => subcategories?.find(subcategory => subcategory._id === id)));
        sortCategories(ids, categoryId)
            .then(() => {
                Promise.all([
                    getCollections(),
                    getCategories(categoryId),
                    getItems({ ...requestOptions })
                ])
                    .then(([categories, subcategories, items]) => {
                        setCategories(categories.data);
                        setSubcategories(subcategories.data);
                        setItems(items.data.paginatedResults);
                        setCount(items.data.totalCount);
                    });
            });
    };

    const handleFormOpen = item => () => {
        setFormOpened(true);
        setEditableItem(item);
    };

    const handleCategoryFormOpen = item => (event) => {
        event.stopPropagation();
        setCategoryFormOpened(true);
        setEditableItem(item);
    };

    const handleSubcategoryFormOpen = item => (event) => {
        event.stopPropagation();
        setSubcategoryFormOpened(true);
        setEditableItem(item);
    };

    const handleItemClone = item => () => {
        setFormOpened(true);

        const data = {};
        LOCALES.forEach((locale) => {
            data[locale] = {
                ...item.data[locale],
                alias: '',
                title: `${item.data[locale].title} (copy)`
            };
        });

        setEditableItem(omit(['_id'], {
            ...item,
            data: data
        }));
    };

    const handleCloseForm = () => {
        setFormOpened(false);
        setCategoryFormOpened(false);
        setSubcategoryFormOpened(false);
        setEditableItem(null);
        setFieldsChanged(false);
    };

    const handleWarningAgree = () => {
        setWarning(false);
        handleCloseForm();
    };

    const handleWarningDisagree = () => {
        setWarning(false);
    };

    const handleOpenWarning = () => {
        setWarning(true);
    };

    const handleWarning = () => {
        if (isFieldsChanged) {
            setWarning(true);
        } else {
            handleCloseForm();
        }
    };

    const tableCells = [
        {
            prop: item => <div className={classes.columnTitle}>
                {
                  item.data[DEFAULT_LOCALE]?.image[0]?.path &&
                  <img className={classes.columnImage} src={item.data[DEFAULT_LOCALE]?.image[0].path} alt={item.data[DEFAULT_LOCALE]?.name}/>
                }
            </div>
        },
        { prop: item => <div className={classes.columnTitle}>{item.data[DEFAULT_LOCALE]?.name}</div> },
        {
            prop: item => <a className={classes.columnTitle} target="_blank" href={`/products/${item.data[DEFAULT_LOCALE]?.alias}`} rel="noreferrer">
                {`/products/${item.data[DEFAULT_LOCALE]?.alias}`}
            </a>
        },
        { prop: item => <div className={classes.columnTitle}>{item.data[DEFAULT_LOCALE]?.availableShare}</div> },
        { prop: item => <div className={classes.columnTitle}>{item.data[DEFAULT_LOCALE]?.pricePerShare}</div> },
        { prop: item => <div className={classes.columnTitle}>{
            item.data[DEFAULT_LOCALE]?.verified ? <Check/> : <Close/>}
        </div> },
        { prop: item => <div className={classes.columnTitle}>{
            !(item.data[DEFAULT_LOCALE]?.hidden || item.collectionInfo?.data[DEFAULT_LOCALE].hidden || item.categoryInfo?.data[DEFAULT_LOCALE].hidden) ? <Check/> : <Close/>
        }</div> },
    ];

    if (loading) {
        return <div className={classes.loader}>
            <CircularProgress/>
        </div>;
    }

    return <div>
        <AdminTableWithSubcategory
            headerRows={headerRows}
            tableCells={tableCells}
            values={items}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
            activeSubcategory={activeSubcategory}
            setActiveSubcategory={setActiveSubcategory}
            categories={categories}
            subcategories={subcategories}
            onDelete={handleItemsDelete}
            onClone={handleItemClone}
            onFormOpen={handleFormOpen}
            onCategoryFormOpen={handleCategoryFormOpen}
            onCategoriesDelete={handleCategoriesDelete}
            onCategoriesSort={handleCategoriesSort}
            onSubcategoryFormOpen={handleSubcategoryFormOpen}
            onSubcategoriesDelete={handleSubcategoriesDelete}
            onSubcategoriesSort={handleSubcategoriesSort}
            setRequestOptions={setRequestOptions}
            requestOptions={requestOptions}
            count={count}
            search={search}
            handleSearchChange={handleInputChange}
            dateStart={dateStart}
            handleDateStartChange={handleDateStartChange}
            dateEnd={dateEnd}
            handleDateEndChange={handleDateEndChange}
        />
        <Modal open={formOpened} onClose={handleWarning} className={classes.modal} disableEnforceFocus disableAutoFocus disablePortal>
            <Paper className={classes.modalContent}>
                <SubcategoryItemForm
                    item={editableItem}
                    categories={categories}
                    activeSubcategory={activeSubcategory}
                    activeCategory={activeCategory}
                    onDone={handleFormDone}
                    setFieldsChanged={setFieldsChanged}
                    onClose={handleWarning}
                />
                <IconButton onClick={handleWarning} className={classes.closeButton}>
                    <Close />
                </IconButton>
            </Paper>
        </Modal>
        <Modal open={categoryFormOpened} onClose={handleOpenWarning} className={classes.modal} disableEnforceFocus disableAutoFocus disablePortal>
            <Paper className={classes.modalContent}>
                <CategoryForm item={editableItem} onDone={handleFormDone} setFieldsChanged={setFieldsChanged}/>
                <IconButton onClick={handleOpenWarning} className={classes.closeButton}>
                    <Close />
                </IconButton>
            </Paper>
        </Modal>
        <Modal open={subcategoryFormOpened} onClose={handleOpenWarning} className={classes.modal} disableEnforceFocus disableAutoFocus disablePortal>
            <Paper className={classes.modalContent}>
                <SubcategoryForm activeCategory={activeCategory} item={editableItem} onDone={handleFormDone} setFieldsChanged={setFieldsChanged}/>
                <IconButton onClick={handleOpenWarning} className={classes.closeButton}>
                    <Close />
                </IconButton>
            </Paper>
        </Modal>
        <Dialog
            open={warning}
            onClose={handleWarningDisagree}
        >
            <DialogTitle>Are you sure you want to close the form?</DialogTitle>
            <DialogActions>
                <Button onClick={handleWarningDisagree} color='primary'>
                    No
                </Button>
                <Button onClick={handleWarningAgree} color='primary' autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    </div>;
};

export default ProductsTable;
