import { ADMIN_PANEL_URL } from './index.js';

export default [
    { id: 'buyIntell', path: ADMIN_PANEL_URL, title: 'Buy INTELL Token' },
    { id: 'joinIntell', path: `${ADMIN_PANEL_URL}/joinIntell`, title: 'Join INTELL Community' },
    { id: 'products', path: `${ADMIN_PANEL_URL}/products`, title: 'Products' },
    { id: 'credentials', path: `${ADMIN_PANEL_URL}/credentials`, title: 'Changing Credentials', notMenu: true },
    { id: 'db', path: `${ADMIN_PANEL_URL}/db`, title: 'Database', notMenu: true }

];
