import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { IconExit, IconQuestion } from "./assets/Icons/Icons";
import imgMore1 from './assets/img/representation.png'
import imgMore2 from './assets/img/representation2.png'
import Filters from "./filters/Filters";
import NavCards from "./Cards/NavCards";
import CardSingle from "./Cards/CardSingle/CardSingle";
import CardFederated from "./Cards/CardsFederated/CardFederated";
import { Pagination } from 'antd';
import Loading from "../../components/Loading/Loading";

interface DataPagination {
  resultsAmountPerPage: number;
  resultsAmount: number;
  page: number;
}


const MarketplacePage: React.FC = () => {

  const [openPopup , setOpenPopup] = useState(false)
  const [loading , setLoading] = useState(false)
  const [typeSection , setTypeSection] = useState('single')
  const [typeViewButton , setTypeViewButton] = useState('box')


  const handleOpenPopup = () => {
      setOpenPopup(!openPopup)
  }

  const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    useEffect(() => {
      scrollToTop();
    }, []);


    const [dataSingle, setDataSingle] = useState([])
    const [dataFederated, setDataFederated] = useState([])
    const [dataPagination, setDataPagination] = useState<DataPagination>({ 
      resultsAmountPerPage: 10,
      resultsAmount: 100,
      page: 1,
    });
    const [maxPagination, setMaxPagination] = useState(dataPagination.resultsAmount)
    const [currentPage, setCurrentPage] = useState<number>(dataPagination.page);
    
    const handlePageChange = (page: number, pageSize?: number | undefined) => {
      setCurrentPage(page);
    };

  
    useEffect(() => {
        fetch('https://run.mocky.io/v3/4f709cdd-e776-4b9e-b2bd-dc7fcde391fd')
        .then(response =>  response.json()) 
        .then(data => {
          setLoading(true)
          setDataSingle(data.single) 
          setDataFederated(data.federated)
          setDataPagination(data.pagination)
          setMaxPagination(data.pagination.resultsAmount  / 10)
        })
        .catch(error => {
          console.error('error', error);
        });
    },[])


    const postData = {
      pagination: {
        resultsPerPage: 10,
        page: currentPage
      }
    };


    useEffect(() => {
      fetch('https://run.mocky.io/v3/3ce378ac-7961-4805-a778-8052d4604e73', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      })
        .then(response => response.json())
        .then(data => {
          console.log(data)
          setLoading(true)
        })
        .catch(error => {
          console.error('error', error);
        });
   },[])

   console.log(maxPagination)


  const PopupImg = () => {

    return(
      <div className={styles.layoutPopupMore} onClick={handleOpenPopup}>

        <div className={styles.popupImgMore}>
          <h2>Learn how to read the cards</h2>
          <div className={styles.box_data_img}>
            <div className={styles.iconExit}>
              <IconExit/>
            </div>
            <img src={imgMore1} alt="Learn More Deployment" />
            <img src={imgMore2} alt="Learn More Federated" />
          </div>

        </div>
      </div>
    )
  }

  return (
    <>
    <div className={styles.root} >
      <header className={styles.header}>
        <div className={styles.box_title_header}>
          <div className={styles.box_title_header_question}>
            <h2>Shop Activations</h2>
            <IconQuestion/>
          </div>
          <p>Discover a new era of investment opportunities by buying Activations with cryptocurrencies in your wallets. </p>
        </div>

        <div className={styles.box_learMore_header}>
          <span>Don’t know how to identify the elements in the cards?</span>
          <strong onClick={handleOpenPopup}>Learn more here</strong>
        </div>
      </header>

      <Filters typeSection = {typeSection}/>

      <NavCards 
        typeSection = {typeSection} 
        setTypeSection={setTypeSection} 
        setTypeViewButton={setTypeViewButton}
        typeViewButton={typeViewButton}
      />

      {
        !loading ? 

        <Loading />
        :

          typeSection === 'single' ?
            <CardSingle
              typeViewButton={typeViewButton}
              dataSingle={dataSingle}
            />
          :
            <CardFederated
              typeViewButton={typeViewButton}
              dataFederated={dataFederated}
            />
      }

      <div className={styles.pagination}>
        {
          loading && 
            <Pagination 
              defaultCurrent={currentPage} 
              total={maxPagination} 
              defaultPageSize={1}
              onChange={handlePageChange}
            />
        }
      
      </div>


    </div>
    

    {openPopup && <PopupImg/>}
    </>
  );
};

export default MarketplacePage;
