import React, { CSSProperties } from "react";
import "./_buttons.scss";

type TButtonTypes = "primary" | "secondary" | "text" | "link";

interface IButton {
  type: TButtonTypes;
  text: string;
  onClick?: () => void;
  style?: CSSProperties;
}

export const Button = ({ type, text, onClick, style }: IButton) => {
  const getClassNames = (type: TButtonTypes): string => {
    switch (type) {
      case "primary":
        return "button-primary";
      case "secondary":
        return "";
      case "text":
        return "";
      case "link":
        return "link";
      default:
        return "";
    }
  };

  return (
    <span onClick={onClick} className={getClassNames(type)} style={style}>
      {text}
    </span>
  );
};
