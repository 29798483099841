import { useFormik } from "formik";
import styles from "./index.module.scss";
import { DiscordSVG, TelegramSVG, TwitterSVG } from "../../icons";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import classNames from "classnames";
import { useEffect, useState } from "react";
import SuccessForm from "../../components/SuccessForm/SuccessForm";
import Popup from "../../components/Popup/Popup";
import { sendJoinRequest } from "../../services/client/sendJoinRequest";
import { ReactComponent as LogoSVG } from "../../landing/assets/images/Intell-token-inscript.svg";
import { ReactComponent as LogoIconSVG } from "../../landing/assets/images/Intell-token-icon.svg";

const validationSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address"),
  isBasedInUS: Yup.string().required("Pick one"),
});

const JoinCommunityForm: React.FC = () => {
  const [formSent, setFormSent] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      isBasedInUS: "",
      haveCryptoWallet: "",
    },
    onSubmit: async (values) => {
      try {
        await sendJoinRequest(values);
        setFormSent(true);
        handleClearForm();
      } catch (error) {
        console.log(error);
      }
    },
    validateOnChange: false,
    validationSchema,
  });

  const handleClearForm = () => {
    formik.resetForm();
  };

  const handleClosePopup = () => {
    setFormSent(false);
  };

  useEffect(() => {
    document.body.scrollIntoView();
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <LogoIconSVG/>
      </div>
      {formSent ? (
        <Popup onClose={handleClosePopup}>
          <SuccessForm
            onButtonClick={handleClosePopup}
            title="Thank you for joining the INTELL community"
          />
        </Popup>
      ) : (
        <>
          <h1 className={styles.title}>Be the First to Know</h1>
          <p className={styles.text}>
            We are changing the way businesses access and utilize AI-enhanced
            intelligence. And AI enthusiasts and investors - like you - can
            participate in the process of creating an ecosystem where AI creators,
            users, and contributors can collaborate and innovate.
          </p>
          <p className={styles.text}>
            Join the INTELL Token community by providing your contact details
            below and we’ll keep you informed of opportunities for you to
            support – and benefit from - the utilization of AI throughout
            traditional business environments.
          </p>

          <div className={styles.formContainer}>
            <p className={styles.provideText}>
              Please provide the following information
            </p>
            <form className={styles.form} onSubmit={formik.handleSubmit}>
              <div className={styles.formItem}>
                <label className={styles.inputLabel} htmlFor="firstName">
                  First Name:
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  className={styles.input}
                />
              </div>
              <div className={styles.formItem}>
                <label className={styles.inputLabel} htmlFor="lastName">
                  Last Name:
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  className={styles.input}
                />
              </div>
              <div className={styles.formItem}>
                <label className={styles.inputLabel} htmlFor="email">
                  Email Address: <span>*</span>
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className={classNames(styles.input, {
                    [styles.inputError]: formik.errors.email,
                  })}
                />
              </div>
              <p className={styles.radioTitle}>
                Please check one: <span>*</span>
              </p>
              <div className={styles.formItem}>
                <input
                  id="basedInUS"
                  type="radio"
                  name="isBasedInUS"
                  value="yes"
                  checked={formik.values.isBasedInUS === "yes"}
                  onChange={formik.handleChange}
                />
                <label className={styles.radioLabel} htmlFor="basedInUS">
                  I am a US-based Investor
                </label>
              </div>
              <div className={styles.formItem}>
                <input
                  id="nonBasedInUS"
                  type="radio"
                  name="isBasedInUS"
                  value="no"
                  checked={formik.values.isBasedInUS === "no"}
                  onChange={formik.handleChange}
                />
                <label className={styles.radioLabel} htmlFor="nonBasedInUS">
                  I am a Non US-based investor
                </label>
              </div>
              <p className={styles.radioTitle}>
                Do you currently have a Digital “Crypto” Wallet?
              </p>
              <div className={styles.formItem}>
                <input
                  id="haveWallet"
                  type="radio"
                  name="haveCryptoWallet"
                  value="yes"
                  checked={formik.values.haveCryptoWallet === "yes"}
                  onChange={formik.handleChange}
                />
                <label className={styles.radioLabel} htmlFor="haveWallet">
                  Yes, of course
                </label>
              </div>
              <div className={styles.formItem}>
                <input
                  id="dontHaveWallet"
                  type="radio"
                  name="haveCryptoWallet"
                  value="no"
                  checked={formik.values.haveCryptoWallet === "no"}
                  onChange={formik.handleChange}
                />
                <label className={styles.radioLabel} htmlFor="dontHaveWallet">
                  No, not yet
                </label>
              </div>
              <div className={styles.formItem}>
                <input
                  id="other"
                  type="radio"
                  name="haveCryptoWallet"
                  value="other"
                  checked={formik.values.haveCryptoWallet === "other"}
                  onChange={formik.handleChange}
                />
                <label className={styles.radioLabel} htmlFor="other">
                  I’ll tell you later
                </label>
              </div>
              <span className={styles.hint}>* - required field</span>
              <button className={styles.submitButton} type="submit">
                Join
              </button>
            </form>
            <p className={styles.privacyText}>
              Your privacy is important to us. Rest assured, the information you
              provide will only be used for communication purposes related to
              INTELL Token and AI participation opportunities. We will never
              share your personal information with anyone.
            </p>
            <div className={styles.bottomWrapper}>
              <div className={styles.socialsWrapper}>
                <p className={styles.socialText}>
                  Also, join on us our socials:
                </p>
                <div className={styles.socialList}>
                  <div className={styles.socialItem}>
                    <DiscordSVG className={styles.socialIcon} />
                    <Link to="#" className={styles.socialLink}>
                      The INTELL Discord
                    </Link>
                  </div>
                  <div className={styles.socialItem}>
                    <TelegramSVG className={styles.socialIcon} />
                    <Link to="#" className={styles.socialLink}>
                      INTELL Telegram
                    </Link>
                  </div>
                  <div className={styles.socialItem}>
                    <TwitterSVG className={styles.socialIcon} />
                    <Link to="#" className={styles.socialLink}>
                      Follow on X (Twitter)
                    </Link>
                  </div>
                </div>
              </div>
              <div className={styles.secondaryLogo}>
                <LogoSVG/>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default JoinCommunityForm;
