import React from "react";
import "./_donut-chart.scss";

export const DonutChart = () => {
  return (
    <div className="chart-container">
      <div className="donut-chart"></div>
      <div className="legend">
        <div className="part4">
          <span className="title">Advisors</span>
          <span className="value">3.0%</span>
        </div>
        <div className="part6">
          <span className="title">Community</span>
          <span className="value">25.5%</span>
        </div>
        <div className="part2">
          <span className="title">Investors </span>
          <span className="value">10.0%</span>
        </div>
        <div className="part3">
          <span className="title">Public Launch</span>
          <span className="value">7.0%</span>
        </div>
        <div className="part1">
          <span className="title">Team</span>
          <span className="value">17.0%</span>
        </div>
        <div className="part5">
          <span className="title">Treasury</span>
          <span className="value">37.5%</span>
        </div>
      </div>
    </div>
  );
};
